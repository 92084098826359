export const CLIENTS_FILTERS = {
  FULL_MEMBERS: 'full-member',
  CLASS_PASS_MEMBERS: 'class-pass-member',
  ALL_MEMBERS: 'all-member'
};
export const MEMBERSHIP_STATUS_FILTERS = {
  ACTIVE: 'member-active',
  EXPIRED: 'member-expired',
  ALL: 'member-all'
};

export const CLIENTS_ACTIVE_FILTERS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ALL: 'all'
};

export const CLIENTS_STATUS_FILTERS = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PERMISSION_DENIED: 'permissionDenied',
  WAITING_FOR_APPROVAL: 'waitingForApproval'
};

export const CLIENTS_TYPES = {
  FULL_MEMBER: 'full-member',
  CLASS_PASS_MEMBER: 'class-pass-member'
};

export const PRODUCT_TYPES = {
  CLASS_PASS: 'classPass',
  PERSONAL_TRAINING_PASS: 'personalTrainingPass',
  INDUCTION: 'inductionPass',
  GYM_CLASS: 'gymClassPass',
  SWIMMING_CLASS: 'swimmingClassPass',
  TENNIS_CLASS: 'tennisClassPass',
  MASSAGE_CLASS: 'massageClassPass',
  VIDEO: 'videoPass'
};

export const CLASS = {
  CLASS: 'class',
  PERSONAL_TRAINING: 'personalTraining',
  INDUCTION: 'induction',
  GYM_CLASS: 'gymClass',
  SWIMMING_CLASS: 'swimmingClass',
  TENNIS_CLASS: 'tennisClass',
  MASSAGE_CLASS: 'massageClass',
  VIDEO: 'video'
};

export const CLASS_TYPES = [
  CLASS.CLASS,
  CLASS.PERSONAL_TRAINING,
  CLASS.INDUCTION,
  CLASS.GYM_CLASS,
  CLASS.SWIMMING_CLASS,
  CLASS.TENNIS_CLASS,
  CLASS.MASSAGE_CLASS,
  CLASS.VIDEO
];

export const ALL_CLASS_TYPES_WITH_DETAILS = () => [
  CLASS_DETAILS,
  PERSONAL_TRAINING_SESSION_DETAILS,
  INDUCTION_CLASS_DETAILS,
  GYM_CLASS_DETAILS,
  SWIMMING_CLASS_DETAILS,
  TENNIS_CLASS_DETAILS,
  MASSAGE_CLASS_DETAILS
];

export const CLASS_LEFT_NAMES = [
  'classesLeft',
  'PTclassesLeft',
  'gymClassesLeft',
  'swimmingClassesLeft',
  'tennisClassesLeft',
  'massageClassesLeft'
];

export const CLASS_DETAILS = {
  type: CLASS.CLASS,
  name: 'class',
  title: 'Class',
  passesUrl: 'class-passes',
  addOneOffUrl: 'class-timetable/add-one-off',
  usersClassesLeft: 'classesLeft',
  productType: PRODUCT_TYPES.CLASS_PASS
};

export const PERSONAL_TRAINING_SESSION_DETAILS = {
  type: CLASS.PERSONAL_TRAINING,
  name: 'personal training',
  title: 'PT',
  passesUrl: 'personal-training-passes',
  addOneOffUrl: 'personal-training-timetable/add-one-off',
  usersClassesLeft: 'PTclassesLeft',
  productType: PRODUCT_TYPES.PERSONAL_TRAINING_PASS
};

export const INDUCTION_CLASS_DETAILS = {
  type: CLASS.INDUCTION,
  name: 'induction',
  title: 'Induction',
  passesUrl: 'induction-passes',
  addOneOffUrl: 'induction-timetable/add-one-off',
  usersClassesLeft: null,
  productType: PRODUCT_TYPES.INDUCTION
};

export const GYM_CLASS_DETAILS = {
  type: CLASS.GYM_CLASS,
  name: 'gym',
  title: 'Gym',
  passesUrl: 'gym-passes',
  addOneOffUrl: 'gym-timetable/add-one-off',
  usersClassesLeft: 'gymClassesLeft',
  productType: PRODUCT_TYPES.GYM_CLASS
};

export const SWIMMING_CLASS_DETAILS = {
  type: CLASS.SWIMMING_CLASS,
  name: 'swim',
  title: 'Swim',
  passesUrl: 'swim-passes',
  addOneOffUrl: 'swim-timetable/add-one-off',
  usersClassesLeft: 'swimmingClassesLeft',
  productType: PRODUCT_TYPES.SWIMMING_CLASS
};

export const TENNIS_CLASS_DETAILS = {
  type: CLASS.TENNIS_CLASS,
  name: 'tennis',
  title: 'Tennis',
  passesUrl: 'tennis-passes',
  addOneOffUrl: 'tennis-timetable/add-one-off',
  usersClassesLeft: 'tennisClassesLeft',
  productType: PRODUCT_TYPES.TENNIS_CLASS
};

export const MASSAGE_CLASS_DETAILS = {
  type: CLASS.MASSAGE_CLASS,
  name: 'massage',
  title: 'Massage',
  passesUrl: 'massage-passes',
  addOneOffUrl: 'massage-timetable/add-one-off',
  usersClassesLeft: 'massageClassesLeft',
  productType: PRODUCT_TYPES.MASSAGE_CLASS
};

export const VIDEO_CLASS_DETAILS = {
  type: CLASS.VIDEO,
  name: 'video',
  title: 'Video',
  passesUrl: 'video-passes',
  addOneOffUrl: null,
  usersClassesLeft: null,
  productType: PRODUCT_TYPES.VIDEO
};

export const CLASS_LABELS = {
  [CLASS.CLASS]: CLASS_DETAILS.title,
  [CLASS.PERSONAL_TRAINING]: PERSONAL_TRAINING_SESSION_DETAILS.title,
  [CLASS.INDUCTION]: INDUCTION_CLASS_DETAILS.title,
  [CLASS.GYM_CLASS]: GYM_CLASS_DETAILS.title,
  [CLASS.SWIMMING_CLASS]: SWIMMING_CLASS_DETAILS.title,
  [CLASS.TENNIS_CLASS]: TENNIS_CLASS_DETAILS.title,
  [CLASS.MASSAGE_CLASS]: MASSAGE_CLASS_DETAILS.title,
  [CLASS.VIDEO]: VIDEO_CLASS_DETAILS.title
};

export const ALL_CURRENCIES = [
  {
    label: 'Pound sterling',
    value: 'gbp',
    symbol: '£'
  },
  {
    label: 'Euro',
    value: 'eur',
    symbol: '€'
  },
  {
    label: 'US Dollar',
    value: 'usd',
    symbol: '$'
  },
  {
    label: 'Australian Dollar',
    value: 'aud',
    symbol: 'A$'
  }
];

export const DEFAULT_WAIT_LIST_CONFIG = {
  active: false,
  minimalTimeBeforeClassStartsToNotifyUsers: 2,
  timeDelayForSendingNotifications: 2
};

export const CUSTOMER_TYPE = [
  {
    label: 'Membership',
    value: 'full-member'
  },
  {
    label: 'Class Pass',
    value: 'class-pass-member'
  }
];

const VIDEOS_KEY = 'vod';
export const getVideoKey = (userId) => `${VIDEOS_KEY}-${userId}`;
