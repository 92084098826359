import React, { useState, useEffect } from 'react';
import Tab from 'grommet/components/Tab';
import Tabs from 'grommet/components/Tabs';
import moment from 'moment';
import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import { PERSONAL_TRAINING_SESSION_DETAILS, CLASS_DETAILS } from '../../../constants';
import { returnProductTypeName } from '../../../shared/classFunctions';
import './ClientProfileProductsDetail.scss';
import { returnAvailableClassTypesInGym } from '../../../shared/gymFunctions';

const ClientProfileProductsDetail = ({ gymSettings, selectedClient, bookings, history, gymId }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [products, setProducts] = useState([]);
  const [futureBookings, setFutureBookings] = useState([]);
  const [pastBookings, setPastBookings] = useState([]);

  const allTabs = returnAvailableClassTypesInGym(gymSettings);

  useEffect(
    () => {
      setProducts(
        selectedClient.products.filter((product) => product.productType === allTabs[selectedTab].productType)
      );
      setFutureBookings(
        bookings.filter(
          (booking) =>
            booking.class &&
            booking.class.__t === allTabs[selectedTab].type &&
            moment() <
              moment(booking.class.classDate, 'YYYY-MM-DD').set({
                hour: moment(booking.class.classTime, ['hh:mm A']).get('hour'),
                minute: moment(booking.class.classTime, ['hh:mm A']).get('minute')
              })
        )
      );
      setPastBookings(
        bookings.filter(
          (booking) =>
            booking.class &&
            booking.class.__t === allTabs[selectedTab].type &&
            moment() >
              moment(booking.class.classDate, 'YYYY-MM-DD').set({
                hour: moment(booking.class.classTime, ['hh:mm A']).get('hour'),
                minute: moment(booking.class.classTime, ['hh:mm A']).get('minute')
              })
        )
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab]
  );

  const onActiveTab = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  const getBookingList = (bookings) =>
    bookings.map((booking) => (
      <div
        className="booking-item"
        onClick={() => {
          history.push(`/gym/${gymId}/class/${booking.class._id}`);
        }}
        key={booking._id}>
        <ProfileTextWithLabel
          key={booking._id}
          label={`Status -  ${booking.status}`}
          text={`${moment(booking.classDate).format('Do MMM YYYY')}`}
        />
      </div>
    ));
  return (
    <div className="product-detail-container">
      <h3 style={{ fontSize: '18px' }}>PURCHASES AND BOOKINGS</h3>
      <Tabs responsive={false} justify="start" activeIndex={selectedTab} onActive={onActiveTab}>
        {allTabs.map(({ title, type, usersClassesLeft }) => (
          <Tab title={title} key={title}>
            {type === PERSONAL_TRAINING_SESSION_DETAILS.type ? (
              <ProfileTextWithLabel
                label="Remaining:"
                text={
                  selectedClient[usersClassesLeft] === 'error'
                    ? 'error'
                    : selectedClient[usersClassesLeft] === 0
                    ? 0
                    : selectedClient[usersClassesLeft].map((elem, i) => (
                        <span key={i}>
                          {elem.classesLeft} x {elem.duration} mins {elem.places > 1 && `(${elem.places} places)`}
                        </span>
                      ))
                }
              />
            ) : type === CLASS_DETAILS.type ? (
              <>
                <ProfileTextWithLabel
                  label="Remaining this month:"
                  text={selectedClient.classesLeft === true ? 'Unlimited' : selectedClient.classesLeft}
                />
                <ProfileTextWithLabel
                  label="Remaining next month:"
                  text={
                    selectedClient.classesLeftNextMonth === true ? 'Unlimited' : selectedClient.classesLeftNextMonth
                  }
                />
              </>
            ) : (
              <ProfileTextWithLabel label="Remaining:" text={selectedClient[usersClassesLeft]} />
            )}
            <Tabs responsive={false} justify="start">
              <Tab title="Pass purchases">
                {products.length === 0 && 'No detail available'}
                {products.map((elem) => (
                  <ProfileTextWithLabel
                    key={elem._id}
                    label={returnProductTypeName(elem.productType)}
                    text={`${elem.classCount}x, bought on ${moment(elem.orderDate).format('Do MMM YY h:mm A')}`}
                  />
                ))}
              </Tab>
              <Tab title="Past Bookings">
                {pastBookings.length === 0 && 'No past bookings available'}
                {getBookingList(pastBookings)}
              </Tab>
              <Tab title="Future Bookings">
                {futureBookings.length === 0 && 'No future bookings'}
                {getBookingList(futureBookings)}
              </Tab>
            </Tabs>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default ClientProfileProductsDetail;
