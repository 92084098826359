import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from '../../../axios-global';
import Modal from 'react-modal';

import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';
import Button from 'grommet/components/Button';
import Toast from 'grommet/components/Toast';

import ClientProfileMainDetails from '../../../components/ClientProfile/ClientProfileMainDetails/ClientProfileMainDetails';
import ClientProfileMoreDetails from '../../../components/ClientProfile/ClientProfileMoreDetails';
import ClientProfileGeneralNotes from '../../../components/ClientProfile/ClientProfileGeneralNotes';
import ClientProfilePaymentNotes from '../../../components/ClientProfile/ClientProfilePaymentNotes/ClientProfilePaymentNotes';
import ClientProfilePlanStatus from '../../../components/ClientProfile/ClientProfilePlanStatus/ClientProfilePlanStatus';
import ClientProfilePayments from '../../../components/ClientProfile/ClientProfilePayments';
import ClientProfileAddProducts from '../../../components/ClientProfile/ClientProfileAddProducts/index';
import ClientProfileSignUpClient from '../../../components/ClientProfile/ClientProfileSignUpClient';
import ClientProfileOperationalButtons from '../../../components/ClientProfile/ClientProfileOperationalButtons';
import ClientProfileAddQr from '../../../components/ClientProfile/ClientProfileAddQr';
import AdminEditClient from '../../../components/AdminEditClient';
import ClientProfileProductsDetail from '../../../components/ClientProfile/ClientProfileProductsDetail/ClientProfileProductsDetail';

import { returnErrorFromResponse, returnSuccessFromResponse } from '../../../shared/utility';

import * as actions from '../../../store/actions/index';

import { CLASS_TYPES } from '../../../constants';

import classes from './ClientProfile.module.scss';

const shouldDisplaySignUpButton = (client) => client.customerType === 'full-member';

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    selectedClientLoading: state.admin.selectedClientLoading,
    selectedClientError: state.admin.selectedClientError,
    selectedClient: state.admin.selectedClient,
    selectedGym: state.admin.selectedGym,
    plansList: state.admin.plansList,
    gymList: state.location.locations
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGymUsersFullData: (gymId) => dispatch(actions.fetchGymUsersFullData(gymId)),
    onFetchGymUsersList: (gymId) => dispatch(actions.fetchGymUsersList(gymId)),
    onFetchSingleClient: (userId) => dispatch(actions.fetchSingleClient(userId)),
    onSingleClientDataReset: (userId) => dispatch(actions.singleClientDataReset(userId)),
    onGlobalQrReaderStateChange: (state) => dispatch(actions.changeGlobalQrReader(state))
  };
};

const ClientProfile = (props) => {
  const [showQrModal, setShowQrModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [toastStatus, setToastStatus] = useState(null);
  const [classPasses, setClassPasses] = useState([]);
  const [personalTrainingPasses, setPersonalTrainingPasses] = useState([]);
  const [gymClassPasses, setGymClassPasses] = useState([]);
  const [swimmingClassPasses, setSwimmingClassPasses] = useState([]);
  const [tennisClassPasses, setTennisClassPasses] = useState([]);
  const [massageClassPasses, setMassageClassPasses] = useState([]);

  useEffect(
    () => {
      props.onFetchSingleClient(props.match.params.clientId);
      return () => {
        props.onSingleClientDataReset(props.match.params.clientId);
        props.onGlobalQrReaderStateChange(true);
      };
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (props.userData.role !== 'user') {
      CLASS_TYPES.forEach((elem) => {
        if (
          props.selectedClient &&
          props.selectedClient.gymSettings[elem] &&
          props.selectedClient.gymSettings.classConfig.filter((config) => config.classType === elem)[0] &&
          props.selectedClient.gymSettings.classConfig.filter((config) => config.classType === elem)[0]
            .allowedToBuyPasses
        ) {
          if (!(props.selectedClient.customerType === 'full-member' && elem === 'class')) {
            axios
              .get(`/products/passes/${elem}Pass/${props.selectedClient.gymId}/all?userId=${props.selectedClient._id}`)
              .then((response) => {
                switch (elem) {
                  case 'class':
                    setClassPasses(response.data);
                    break;
                  case 'personalTraining':
                    setPersonalTrainingPasses(response.data);
                    break;

                  case 'tennisClass':
                    setTennisClassPasses(response.data);
                    break;

                  case 'gymClass':
                    setGymClassPasses(response.data);
                    break;

                  case 'swimmingClass':
                    setSwimmingClassPasses(response.data);
                    break;

                  case 'massageClass':
                    setMassageClassPasses(response.data);
                    break;
                }
              })
              .catch((err) =>
                // eslint-disable-next-line
                console.log(err)
              );
          }
        }
      });
    }

    if (props.selectedClient && props.selectedClient._id) {
      if (
        props.match.params.clientId !== props.selectedClient._id &&
        (!props.selectedClient.qrCode ||
          (props.selectedClient.qrCode && props.selectedClient.qrCode !== props.match.params.clientId))
      ) {
        props.onFetchSingleClient(props.match.params.clientId);
      }
    }
    // eslint-disable-next-line
  }, [props.selectedClient]);

  const fetchSingleClientDetails = () => props.onFetchSingleClient(props.match.params.clientId);

  const fetchUsersFullDataForSelectedGym = () => props.onFetchGymUsersFullData(props.selectedGym._id);

  const showSuccessfulToast = (response) => {
    setToast(response ? returnSuccessFromResponse(response) : 'Changes saved');
    setToastStatus('ok');
  };

  const showFailedToast = (error) => {
    setToast(returnErrorFromResponse(error));
    setToastStatus('critical');
  };

  const closeToast = () => {
    setToast(false);
    setToastStatus(null);
  };

  const renderToast = () => {
    if (toast && toastStatus) {
      return (
        <Toast status={toastStatus} onClose={closeToast}>
          {toast}
        </Toast>
      );
    }
  };

  const handleClientDeletion = () => {
    setDeleteLoading(true);

    axios
      .delete(`/users/${props.selectedClient._id}`)
      .then((resp) => {
        props.onFetchGymUsersList(props.selectedClient.gymId);
        props.onFetchSingleClient(props.match.params.clientId);
        setToast(resp.data.message);
        setToastStatus('ok');
        setDeleteLoading(false);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        setToast(err.response.data.message);
        setToastStatus('critical');
        setDeleteLoading(false);
        setShowDeleteModal(false);
      });
  };

  const onNewQrScan = (scan) => {
    if (scan) {
      axios
        .patch(`/users/${props.selectedClient._id}`, {
          qrCode: scan
        })
        .then(() => {
          setShowQrModal(false);
          setToast('Additional QR code saved');
          setToastStatus('ok');
          setTimeout(() => {
            props.onGlobalQrReaderStateChange(true);
          }, 1000);
        })
        .catch((err) => {
          if (err.response.data.errors && err.response.data.errors[0].message) {
            setShowQrModal(false);
            setToast(err.response.data.errors[0].message);
            setToastStatus('critical');
            props.onGlobalQrReaderStateChange(true);
          }
        });
    }
  };

  const onQrCodeError = (error) => {
    setToast(returnErrorFromResponse(error));
    setToastStatus('critical');
  };

  const handleQrModalClose = () => {
    setShowQrModal(false);
    props.onGlobalQrReaderStateChange(true);
  };

  const handleQrModalOpen = () => {
    setShowQrModal(true);
    props.onGlobalQrReaderStateChange(false);
  };

  const renderQrModal = () => {
    return (
      <Modal isOpen={showQrModal} onRequestClose={handleQrModalClose} className="ss-modal ss-modal--no-min-width">
        <ClientProfileAddQr handleQrCodeScan={onNewQrScan} handleQrCodeError={onQrCodeError} />
      </Modal>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        isOpen={showDeleteModal}
        onRequestClose={handleDeleteModalClose}
        className="ss-modal ss-modal--no-min-width">
        Do you want to deactivate chosen user?
        <br />
        <div className="ss-modal__buttons-container">
          {deleteLoading ? (
            <Spinning />
          ) : (
            <>
              <Button box label="Yes" secondary={true} onClick={handleClientDeletion} />

              <Button box label="Cancel" primary onClick={handleDeleteModalClose} />
            </>
          )}
        </div>
      </Modal>
    );
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteModalOpen = () => {
    setShowDeleteModal(true);
  };

  const renderEditModal = () => {
    return (
      <Modal isOpen={showEditModal} onRequestClose={handleEditModalClose} className="ss-modal">
        <div className="grommet">
          <AdminEditClient
            clientData={props.selectedClient}
            onFetchClientDetails={fetchSingleClientDetails}
            gymList={props.gymList}
            onFetchUsersFullDataForGym={fetchUsersFullDataForSelectedGym}
          />
        </div>
      </Modal>
    );
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleEditModalOpen = () => {
    setShowEditModal(true);
  };

  const renderProfile = () => {
    if (!props.selectedClientLoading && props.selectedClientError && !props.selectedClient) {
      return <Notification message={props.selectedClientError} status="critical" />;
    }

    if (
      !props.selectedClientLoading &&
      !props.selectedClientError &&
      props.selectedClient &&
      !props.selectedClient._id
    ) {
      return <Notification message="No customer found for this ID or this QR code" status="warning" />;
    }

    if (!props.selectedClientLoading && !props.selectedClientError && props.selectedClient) {
      const isUserActive = props.selectedClient.status === 'active';
      return (
        <>
          {!isUserActive && renderInactiveUser()}

          <ClientProfileMainDetails
            editable={props.userData.role !== 'trainer'}
            selectedClient={props.selectedClient}
            fetchSingleClient={fetchSingleClientDetails}
            onSuccessfulSave={showSuccessfulToast}
            onFailedSave={showFailedToast}
            isAdmin={props.userData.role === 'admin'}
          />

          <ClientProfileMoreDetails
            editable={props.userData.role !== 'trainer'}
            selectedClient={props.selectedClient}
            fetchSingleClient={fetchSingleClientDetails}
            onSuccessfulSave={showSuccessfulToast}
            onFailedSave={showFailedToast}
          />

          <h3 style={{ fontSize: '18px' }}>NOTES</h3>
          <ClientProfilePaymentNotes
            isAdmin={props.userData.role === 'admin'}
            selectedClient={props.selectedClient}
            onSuccessfulSave={showSuccessfulToast}
            onFailedSave={showFailedToast}
          />

          <ClientProfileGeneralNotes
            selectedClient={props.selectedClient}
            onSuccessfulSave={showSuccessfulToast}
            onFailedSave={showFailedToast}
          />

          {props.userData.role === 'admin' &&
            isUserActive &&
            classPasses &&
            personalTrainingPasses &&
            gymClassPasses &&
            swimmingClassPasses &&
            tennisClassPasses &&
            massageClassPasses && (
              <ClientProfileAddProducts
                gymPasses={gymClassPasses}
                swimPasses={swimmingClassPasses}
                tennisPasses={tennisClassPasses}
                massagePasses={massageClassPasses}
                gymSettings={props.selectedGym.settings}
                selectedClient={props.selectedClient}
                classPasses={classPasses}
                personalTrainings={personalTrainingPasses}
                fetchSingleClient={fetchSingleClientDetails}
                onSuccessfulSave={showSuccessfulToast}
                onFailedSave={showFailedToast}
              />
            )}

          <ClientProfileProductsDetail
            gymSettings={props.selectedGym.settings}
            selectedClient={props.selectedClient}
            bookings={props.selectedClient.fitnessClassHistory}
            history={props.history}
            gymId={props.selectedClient.gymId}
          />

          <ClientProfilePlanStatus selectedClient={props.selectedClient} />

          {shouldDisplaySignUpButton(props.selectedClient) && (
            <ClientProfileSignUpClient
              isAdmin={props.userData.role === 'admin'}
              selectedClient={props.selectedClient}
              plansList={props.plansList}
              fetchSingleClient={fetchSingleClientDetails}
              onSuccessfulSave={showSuccessfulToast}
              onFailedSave={showFailedToast}
            />
          )}

          <ClientProfilePayments
            isAdmin={props.userData.role === 'admin'}
            selectedClient={props.selectedClient}
            onSuccessfulSave={showSuccessfulToast}
            onFailedSave={showFailedToast}
          />

          {props.userData.role === 'admin' && isUserActive && (
            <ClientProfileOperationalButtons
              showQRButton={props.selectedGym.settings.qrCode}
              handleQrModalOpen={handleQrModalOpen}
              handleEditModalOpen={handleEditModalOpen}
              handleDeleteModalOpen={handleDeleteModalOpen}
            />
          )}
        </>
      );
    }

    return <Spinning />;
  };

  const renderInactiveUser = () => {
    const activateUser = () => {
      axios
        .patch(`/users/${props.selectedClient._id}`, { status: 'active' })
        .then(() => {
          setToast('Client successfully activated!');
          setToastStatus('ok');

          props.onFetchSingleClient(props.selectedClient._id);
          props.onFetchGymUsersList(props.selectedClient.gymId);
        })
        .catch((error) => {
          setToast(returnErrorFromResponse(error));
          setToastStatus('critical');
        });
    };
    const userStatus =
      props.selectedClient.status === 'waitingForApproval'
        ? 'waiting for admin approval'
        : props.selectedClient.status === 'permissionDenied'
        ? 'permission denied'
        : 'inactive';

    return (
      <>
        <Notification message={`This customer status is: ${userStatus}`} status="warning" />

        {props.userData.role === 'admin' && (
          <div className={classes.userActivationBox}>
            Do you want to activate this user?
            <Button label="YES" primary onClick={() => activateUser()} />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className={classes.detailsContainer}>
        {renderToast()}
        {renderQrModal()}
        {renderDeleteModal()}
        {renderEditModal()}
        {renderProfile()}
      </div>
    </>
  );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientProfile));
