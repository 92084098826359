import React from 'react';
import { Tab, Tabs } from 'grommet';
import SingleClassTypeHistory from '../../../HistoryComponent/SingleClassTypeHistory';
import DefaultSingleDayTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultSingleDayTimetable';
import DefaultTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import { returnAllUpcomingClasses } from '../../../../shared/classFunctions';
import withUser from '../../../../store/hoc/withUser';
import ManagePasses from '../../../ManagePasses/ManagePasses';
import AddEditSingleClass from '../../../AddEditSingleClass/AddEditSingleClass';
import ManageSchedules from '../../../ManageSchedules/ManageSchedules';
import ManageTemplates from '../../../ManageTemplates/ManageTemplates';
import ManageVideos from '../../../ManageVideos/ManageVideos';
import { CLASS } from '../../../../constants';

const AdminView = ({
  classesList,
  classesListLoading,
  classesListError,
  classType,
  classesDetails,
  classConfig,
  onFetchClasses,
  showAllGymsData
}) => {
  const upcomingClassesComponent = classConfig.showUpcoming && (
    <Tab key="Upcoming" title="Upcoming">
      {classConfig.singleDayTimetable ? (
        <DefaultSingleDayTimetable
          classesList={classesList}
          classesListLoading={classesListLoading}
          classesListError={classesListError}
          onFetchClasses={onFetchClasses}
          classesDetails={classesDetails}
          classConfig={classConfig}
          showAllGymsData={showAllGymsData}
        />
      ) : (
        <DefaultTimetable
          classesList={returnAllUpcomingClasses(classesList)}
          classesListLoading={classesListLoading}
          classesListError={classesListError}
          classesDetails={classesDetails}
          classType={classesDetails.type}
          onFetchClasses={onFetchClasses}
          classConfig={classConfig}
          showAllGymsData={showAllGymsData}
        />
      )}
    </Tab>
  );

  const historyComponent = classConfig.showHistory && (
    <Tab key="History" title="History">
      <SingleClassTypeHistory classType={classType} classConfig={classConfig} />
    </Tab>
  );

  const addNewComponent = classConfig.showAddNew && (
    <Tab key="Add New" title="Add New">
      <AddEditSingleClass classType={classType} classConfig={classConfig} />
    </Tab>
  );

  const videosComponent = classType === CLASS.VIDEO && (
    <Tab key="Videos" title="Videos">
      <ManageVideos />
    </Tab>
  );

  const passesComponent = classConfig.allowedToBuyPasses && (
    <Tab key="Passes" title="Passes">
      <ManagePasses classType={classType} />
    </Tab>
  );

  const scheduleComponent = classConfig.schedule && (
    <Tab key="Schedule" title="Schedule">
      <ManageSchedules classType={classType} />
    </Tab>
  );

  const templateComponent = classConfig.schedule && (
    <Tab key="Templates" title="Templates">
      <ManageTemplates classType={classType} />
    </Tab>
  );

  return (
    <>
      <Tabs responsive={false} style={tabStyles} justify="start" data-cy="tabList">
        {[
          upcomingClassesComponent,
          historyComponent,
          addNewComponent,
          videosComponent,
          passesComponent,
          scheduleComponent,
          templateComponent
        ].filter((component) => !!component)}
      </Tabs>
    </>
  );
};

export default withUser(AdminView);

const tabStyles = {
  borderBottom: 'none',
  paddingBottom: '20px',
  fontWeight: 'bold'
};
