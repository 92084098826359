import React from 'react';

import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';

import classes from './ClientPayDescription.module.scss';

import { getCurrencySymbol, proRatedCost } from '../../../../shared/utility';
const ClientPayDescription = (props) => {
  let contractMonthsText = 'month';
  if (props.membershipPlan.minContractLength && props.membershipPlan.minContractLength > 1) {
    contractMonthsText = 'months';
  }
  return (
    <div className={classes.container}>
      <Heading strong={false} tag="h2" style={{ fontSize: '30px' }}>
        {props.membershipPlan.name}
      </Heading>
      <Paragraph>{props.membershipPlan.description}</Paragraph>
      <Heading tag="h2" strong={false} style={{ fontSize: '20px' }}>
        Contract length: {props.membershipPlan.minContractLength} {contractMonthsText}
      </Heading>
      {!props.joiningFeePaid && props.membershipPlan.joiningFee ? (
        <Heading tag="h2" strong={false} style={{ fontSize: '20px' }}>
          Joining fee: {getCurrencySymbol(props.membershipPlan.currency)}
          &nbsp;{props.membershipPlan.joiningFee}
        </Heading>
      ) : null}
      <Heading tag="h2" strong={false} style={{ fontSize: '20px' }}>
        Membership price (monthly): {getCurrencySymbol(props.membershipPlan.currency)}
        {props.membershipPlan.price}
      </Heading>
      <Paragraph>
        Membership will be pro rated for the rest of this month, therefore you will be charged at most{' '}
        <b className={classes.cost}>{proRatedCost(props.membershipPlan)}</b> (exact amount may differ by a few pence)
      </Paragraph>
    </div>
  );
};

export default ClientPayDescription;
