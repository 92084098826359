/* eslint-disable no-unused-vars */
import React from 'react';
import DefaultSingleDayTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultSingleDayTimetable';
import DefaultTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import FullDataTimetable from '../../DefaultTimetable/FullDataTimetable/FullDataTimetable';
import { returnAllUpcomingClasses } from '../../../../shared/classFunctions';
import VideoOnDemandPasses from '../../../VideoOnDemandPasses';
import classes from './UserView.module.scss';

const explainer = (classType) =>
  classType === 'induction' ? (
    <div className={classes.explainer}>YOU CAN CHOOSE TO COMPLETE YOUR INDUCTION BY VIDEO OR IN PERSON</div>
  ) : null;

const UserView = ({
  classesList,
  classesListLoading,
  classesListError,
  classesDetails,
  classConfig,
  classesListReFetch,
  videoProducts = [],
  userVideoProducts = []
}) => {
  if (classesList && classConfig.classType === 'class') {
    return (
      <>
        {(!!userVideoProducts.length || !!videoProducts.length) && (
          <>
            <VideoOnDemandPasses userVideoProducts={userVideoProducts} videoProducts={videoProducts} />
            {explainer(classConfig.classType)}
          </>
        )}
        <FullDataTimetable classesList={classesList} classesDetails={classesDetails} />
      </>
    );
  }

  if (classConfig.singleDayTimetable) {
    return (
      <>
        {(!!userVideoProducts.length || !!videoProducts.length) && (
          <>
            <VideoOnDemandPasses userVideoProducts={userVideoProducts} videoProducts={videoProducts} />
            {explainer(classConfig.classType)}
          </>
        )}
        <DefaultSingleDayTimetable
          classesList={classesList}
          classesListLoading={classesListLoading}
          classesListError={classesListError}
          onFetchClasses={classesListReFetch}
          classesDetails={classesDetails}
          classConfig={classConfig}
        />
      </>
    );
  }

  return (
    <>
      {(!!userVideoProducts.length || !!videoProducts.length) && (
        <>
          <VideoOnDemandPasses userVideoProducts={userVideoProducts} videoProducts={videoProducts} />
          {explainer(classConfig.classType)}
        </>
      )}
      <DefaultTimetable
        classesList={returnAllUpcomingClasses(classesList)}
        classesListLoading={classesListLoading}
        classesListError={classesListError}
        classesDetails={classesDetails}
        classType={classesDetails.type}
        onFetchClasses={classesListReFetch}
        classConfig={classConfig}
      />
    </>
  );
};

export default UserView;
