import React from 'react';
import {
  isActiveUser,
  isFullAccessAdmin,
  isFullAccessAdminOrTrainer,
  returnGymsByAccess
} from '../../../shared/userFunctions';
import { returnClassConfigFieldValue } from '../../../shared/gymFunctions';
import UserIcon from '../../../brandConfig/assets/svg/user.svg';
import QRIcon from '../../../brandConfig/assets/svg/qr.svg';
import ScheduledClassesIcon from '../../../brandConfig/assets/svg/scheduled-cl.svg';
import ClassPassesIcon from '../../../brandConfig/assets/svg/class-passes.svg';
import CalendarIcon from '../../../brandConfig/assets/svg/calendar.svg';
import PTSessionIcon from '../../../brandConfig/assets/svg/pt-session-add.svg';
import PTPackagesIcon from '../../../brandConfig/assets/svg/pt-packages.svg';
import PlansIcon from '../../../brandConfig/assets/svg/plans.svg';
import ClassesIcon from '../../../brandConfig/assets/svg/classes.svg';
import ClientsIcon from '../../../brandConfig/assets/svg/clients.svg';
import TrainersIcon from '../../../brandConfig/assets/svg/trainers.svg';

export const menuItemsArray = (userData, settings, url, gyms, type = 'showInMenu') => {
  const gymsFullAccess = isFullAccessAdminOrTrainer(userData);
  const gymsFullAccessAdmin = isFullAccessAdmin(userData);
  let tabs = [
    {
      name: 'Settings',
      icon: <UserIcon />,
      link: `/user/settings`,
      showInMenu: true,
      showAsTile: false,
      cyId: 'userSettings'
    }
  ];

  switch (userData.role) {
    case 'user':
      if (isActiveUser(userData)) {
        if (settings.qrCode) {
          tabs.push({
            link: `${url}/qr`,
            name: 'QR Code',
            icon: <QRIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'qrCode'
          });
        }
      }

      if (settings.induction) {
        tabs.push({
          link: `${url}/induction-timetable`,
          name: 'Induction Bookings',
          icon: <ScheduledClassesIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'induction'
        });

        if (returnClassConfigFieldValue(settings.classConfig, 'induction', 'allowedToBuyPasses')) {
          tabs.push({
            link: `${url}/induction-passes`,
            name: 'Induction Passes',
            icon: <ClassPassesIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'inductionPasses'
          });
        }
      }

      if (settings.gymClass) {
        tabs.push({
          link: `${url}/gym-timetable`,
          name: 'Gym Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'gymBookings'
        });
        if (returnClassConfigFieldValue(settings.classConfig, 'gymClass', 'allowedToBuyPasses')) {
          tabs.push({
            link: `${url}/gym-passes`,
            name: 'Gym Passes',
            icon: <ClassPassesIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'gymPasses'
          });
        }
      }

      if (settings.swimmingClass) {
        tabs.push({
          link: `${url}/swim-timetable`,
          name: 'Swim Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'swimBookings'
        });

        if (returnClassConfigFieldValue(settings.classConfig, 'swimmingClass', 'allowedToBuyPasses')) {
          tabs.push({
            link: `${url}/swim-passes`,
            name: 'Swim Passes',
            icon: <ClassPassesIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'swimPasses'
          });
        }
      }

      if (settings.tennisClass) {
        tabs.push({
          link: `${url}/tennis-timetable`,
          name: 'Tennis Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'tennisBookings'
        });

        if (returnClassConfigFieldValue(settings.classConfig, 'tennisClass', 'allowedToBuyPasses')) {
          tabs.push({
            link: `${url}/tennis-passes`,
            name: 'Tennis Passes',
            icon: <ClassPassesIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'tennisPasses'
          });
        }
      }

      if (settings.massageClass) {
        tabs.push({
          link: `${url}/massage-timetable`,
          name: 'Massage Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'massageBookings'
        });

        if (returnClassConfigFieldValue(settings.classConfig, 'massageClass', 'allowedToBuyPasses')) {
          tabs.push({
            link: `${url}/massage-passes`,
            name: 'Massage Passes',
            icon: <ClassPassesIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'massagePasses'
          });
        }
      }

      if (settings.personalTraining) {
        tabs.push({
          link: `${url}/personal-training-timetable`,
          name: 'PT Bookings',
          icon: <PTPackagesIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'personalTrainingBookings'
        });

        if (returnClassConfigFieldValue(settings.classConfig, 'personalTraining', 'allowedToBuyPasses')) {
          tabs.push({
            link: `${url}/personal-training-passes`,
            name: 'PT Passes',
            icon: <PTSessionIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'personalTrainingPasses'
          });
        }
      }

      if (isActiveUser(userData)) {
        if (settings.class) {
          tabs.push({
            name: 'Class Bookings',
            exact: true,
            link: `${url}/class-timetable`,
            icon: <CalendarIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'classBookings'
          });
        }
        if (
          userData.customerType === 'class-pass-member' &&
          returnClassConfigFieldValue(settings.classConfig, 'class', 'allowedToBuyPasses')
        ) {
          tabs.push({
            name: 'Class Passes',
            link: `${url}/class-passes`,
            icon: <ClassPassesIcon />,
            showInMenu: true,
            showAsTile: true,
            cyId: 'classPasses'
          });
        }
      }

      if (settings.videoOnDemand) {
        tabs.push({
          link: `${url}/video-passes`,
          name: 'Video Passes',
          icon: <CalendarIcon />,
          showInMenu: false,
          showAsTile: false,
          cyId: 'videoPass'
        });
      }

      if (settings.liveStreaming) {
        tabs.push({
          link: `${url}/videos/live-stream-viewer`,
          name: 'Live Streams',
          icon: <CalendarIcon />,
          showInMenu: false,
          showAsTile: false,
          cyId: 'liveStream'
        });
      }

      if (settings.faq) {
        tabs.push({
          link: `${url}/faq`,
          name: 'FAQ',
          icon: <PlansIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'faq'
        });
      }

      if (settings.kitList) {
        tabs.push({
          link: `${url}/kit-list`,
          name: 'Kit List',
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'kitList'
        });
      }

      break;

    case 'trainer':
      tabs.push(
        {
          name: 'Clients',
          link: `${url}/clients`,
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'clients'
        },
        {
          name: 'My Timetable',
          link: `${url}/my-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'trainerTimetable'
        },
        {
          link: `${url}/staff-calendar`,
          name: 'Staff Calendar',
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'staffCalendar'
        }
      );

      if (settings.class) {
        tabs.push({
          name: 'Class Bookings',
          link: `${url}/class-timetable`,
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'classBookings'
        });
      }

      if (settings.personalTraining) {
        tabs.push({
          name: 'PT Bookings',
          link: `${url}/personal-training-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'personalTrainingBookings'
        });
      }

      if (settings.induction) {
        tabs.push({
          name: 'Induction Bookings',
          link: `${url}/induction-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'induction'
        });
      }

      if (settings.gymClass) {
        tabs.push({
          name: 'Gym Bookings',
          link: `${url}/gym-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'gymBookings'
        });
      }

      if (settings.swimmingClass) {
        tabs.push({
          name: 'Swim Bookings',
          link: `${url}/swim-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'swimBookings'
        });
      }

      if (settings.tennisClass) {
        tabs.push({
          name: 'Tennis Bookings',
          link: `${url}/tennis-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'tennisBookings'
        });
      }

      if (settings.massageClass) {
        tabs.push({
          name: 'Massage Bookings',
          link: `${url}/massage-timetable`,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'massageBookings'
        });
      }

      if (settings.faq) {
        tabs.push({
          name: 'FAQ',
          link: `${url}/faq`,
          icon: <PlansIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'faq'
        });
      }

      if (settings.kitList) {
        tabs.push({
          name: 'Kit List',
          link: `${url}/kit-list`,
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'kitList'
        });
      }

      tabs.push({
        name: 'History',
        link: `${url}/class-history`,
        icon: <CalendarIcon />,
        showInMenu: true,
        showAsTile: true,
        cyId: 'history'
      });

      tabs.push(
        {
          link: `${url}/videos`,
          name: 'Videos',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'video'
        },
        {
          link: `${url}/videos/live-stream`,
          name: 'Live Stream',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'liveStream'
        }
      );

      break;

    case 'admin':
      tabs.shift();
      tabs.push(
        {
          link: `${url}/settings`,
          name: 'Gym Settings',
          icon: <TrainersIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'gymSettings'
        },
        {
          link: `${url}/admins`,
          name: 'Admins',
          icon: <TrainersIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'admins'
        },
        {
          link: `${url}/trainers`,
          name: 'Trainers',
          icon: <TrainersIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'trainers'
        },
        {
          link: `${url}/clients`,
          name: 'Clients',
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'clients'
        },
        {
          link: `${url}/staff-calendar`,
          name: 'Staff Calendar',
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'staffCalendar'
        },
        {
          link: `${url}/class-history`,
          name: 'History',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'history'
        },
        {
          link: `${url}/search`,
          name: 'Search',
          icon: <TrainersIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'search'
        },
        {
          link: `${url}/reports`,
          name: 'Reports',
          icon: <PlansIcon data-cy="reportsTile" />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'reports'
        }
      );

      if (settings.newClientsHaveToBeApprovedByAdmin) {
        tabs.push({
          link: `${url}/needs-approval`,
          name: 'Needs Approval',
          icon: <ClientsIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'needsApproval'
        });
      }
      if (gymsFullAccess) {
        tabs.push({
          link: `${url}/bulk-management`,
          name: 'Bulk management',
          icon: <PlansIcon data-cy="bulkManagementTile" />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'bulkManagementTile'
        });
      }

      if (settings.membershipProducts) {
        tabs.push({
          link: `${url}/membership-plans`,
          name: 'Membership Plans',
          icon: <PlansIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'membershipPlans'
        });
      }

      tabs.push({
        link: `${url}/timetable/all`,
        name: 'All Bookings',
        icon: <CalendarIcon />,
        showInMenu: true,
        showAsTile: true,
        cyId: 'allBookings'
      });

      if (settings.class) {
        tabs.push({
          link: `${url}/class-timetable`,
          name: 'Class Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'classBookings'
        });
      }

      if (settings.personalTraining) {
        tabs.push({
          link: `${url}/personal-training-timetable`,
          name: 'PT Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'personalTrainingBookings'
        });
      }

      if (settings.induction) {
        tabs.push({
          link: `${url}/induction-timetable`,
          name: 'Induction',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'induction'
        });
      }

      if (settings.videoOnDemand && gymsFullAccessAdmin) {
        tabs.push({
          link: `${url}/video-passes`,
          name: 'Video Passes',
          icon: <CalendarIcon />,
          showInMenu: false,
          showAsTile: false,
          cyId: 'videoPass'
        });
      }

      if (settings.gymClass) {
        tabs.push({
          link: `${url}/gym-timetable`,
          name: 'Gym Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'gymBookings'
        });
      }

      if (settings.swimmingClass) {
        tabs.push({
          link: `${url}/swim-timetable`,
          name: 'Swim Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'swimBookings'
        });
      }

      if (settings.tennisClass) {
        tabs.push({
          link: `${url}/tennis-timetable`,
          name: 'Tennis Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'tennisBookings'
        });
      }

      if (settings.massageClass) {
        tabs.push({
          link: `${url}/massage-timetable`,
          name: 'Massage Bookings',
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: true,
          cyId: 'massageBookings'
        });
      }

      if (returnGymsByAccess(gyms, userData).length > 1) {
        tabs.unshift({
          link: '/gym',
          name: 'Select Gym',
          exact: true,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'selectGym'
        });
      } else if (gymsFullAccess) {
        tabs.unshift({
          link: '/gym/add-gym',
          name: 'Add gym',
          exact: true,
          icon: <CalendarIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'addGym'
        });
      }

      if (settings.faq) {
        tabs.push({
          link: `${url}/faq`,
          name: 'FAQ',
          icon: <PlansIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'faq'
        });
      }

      if (settings.kitList) {
        tabs.push({
          link: `${url}/kit-list`,
          name: 'Kit List',
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'kitList'
        });
      }

      if (settings.videoOnDemand) {
        tabs.push({
          link: `${url}/videos`,
          name: 'Videos',
          icon: <ClassesIcon />,
          showInMenu: false,
          showAsTile: false,
          cyId: 'video'
        });
      }

      if (settings.liveStreaming) {
        tabs.push({
          link: `${url}/videos/live-stream`,
          name: 'Live Stream',
          icon: <ClassesIcon />,
          showInMenu: true,
          showAsTile: false,
          cyId: 'liveStream'
        });
      }

      break;
    default:
      return tabs;
  }

  return tabs.filter((elem) => elem[type] === true);
};
