import React from 'react';
import { returnUserClassesLeftAmount } from '../../../../shared/userFunctions';
import { returnClassPrice, hasClassStarted } from '../../../../shared/classFunctions';
import { getCurrencySymbol } from '../../../../shared/utility';
import withUser from '../../../../store/hoc/withUser';
import { returnClassConfig } from '../../../../shared/gymFunctions';

const ReturnClassPrice = ({ classType, selectedClass, userData, hasUserSignUp = false, classesConfig }) => {
  const classesLeft = returnUserClassesLeftAmount(classType, userData, selectedClass);
  const classPrice = returnClassPrice(selectedClass, userData);
  let price = 'Free';
  const membershipClassPrice = selectedClass.membershipClassPrice;
  const currency = getCurrencySymbol(selectedClass.currency);
  const classConfig = returnClassConfig(classesConfig, classType);

  if (userData.role === 'user') {
    if (hasUserSignUp) {
      price = null;
    }
    if (classesLeft > 0) {
      price = userData.customerType === 'full-member' ? 'Within plan' : 'Within pass';
    } else {
      price = classConfig.showClassCost ? <>£{classPrice}</> : null;
    }

    const userEventAttendance = {
      _id: selectedClass.userAttendanceId,
      status: selectedClass.userAttendanceStatus,
      stripeOrderId: selectedClass.userAttendanceStripeOrderId
    };
    const hasUserBookedClass = userEventAttendance && userEventAttendance.status === 'active';
    const hasClassAlreadyStarted = hasClassStarted(selectedClass, 15);

    if (hasUserBookedClass || hasClassAlreadyStarted) {
      price = null;
    }
  } else {
    price = (
      <>
        {currency}
        {selectedClass.cost} {membershipClassPrice && `(${currency} ${membershipClassPrice})`}
      </>
    );
  }

  return <h5 style={{ margin: '0px' }}>{price}</h5>;
};

export default withUser(ReturnClassPrice);
