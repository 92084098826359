import React from 'react';
import FormField from 'grommet/components/FormField';
import TextInput from 'grommet/components/TextInput';
import PasswordInput from 'grommet/components/PasswordInput';
import NumberInput from 'grommet/components/NumberInput';
import DateTime from 'grommet/components/DateTime';
import Label from 'grommet/components/Label';
import Select from 'grommet/components/Select';
import VideoPicker from '../../../containers/ManageVideos/VideoPicker/VideoPicker';
import OptionalFormElement from './OptionalFormElement/OptionalFormElement';

import classes from './FormCreator.module.scss';
import CheckBox from 'grommet/components/CheckBox';

const FormCreator = (props) => {
  const handleSubmit = (ev) => ev.preventDefault();

  let inputs = [];
  for (let form in props.formData) {
    props.formData[form].identifier = form;
    inputs.push(props.formData[form]);
  }

  return (
    <form data-cy="form" onSubmit={handleSubmit}>
      {inputs.map((element) => {
        let warning = null;
        if (element.touched && !element.valid && element.errorText) {
          warning = <div className={classes.warning}>{element.errorText}</div>;
        }
        const selectClassList = [classes.select];
        if (element.identifier !== 'title' && element.type) {
          selectClassList.push(classes.selectLong);
        }
        switch (element.type) {
          case 'select':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <Select
                  aria-labelledby={element.identifier}
                  className={selectClassList.join(' ')}
                  options={element.options}
                  value={element.value}
                  onChange={(event) => {
                    props.valueChanged(event.value ? event.value : event.target.value, element.identifier);
                  }}
                />
              </div>
            );
          case 'password':
            if (
              element.identifier === 'confirmPassword' &&
              element.touched &&
              element.value !== element.basePasswordValue
            ) {
              warning = <div className={classes.warning}>Passwords are not equal!</div>;
            }
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <FormField className={classes.input}>
                  <PasswordInput
                    aria-labelledby={element.identifier}
                    value={element.value}
                    placeholder={element.placeholder}
                    data-cy={element.cy_id || ''}
                    onChange={(event) => props.valueChanged(event.target.value, element.identifier)}
                    onKeyPress={element.keyPress ? (event) => props.keyPress(event, element.identifier) : null}
                  />
                </FormField>
                {warning}
              </div>
            );
          case 'number':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <FormField className={classes.input}>
                  <NumberInput
                    aria-labelledby={element.identifier}
                    value={element.value}
                    placeholder={element.placeholder}
                    data-cy={element.cy_id || ''}
                    onChange={(event) => props.valueChanged(event.target.value, element.identifier)}
                  />
                </FormField>
                {warning}
              </div>
            );
          case 'smallNumber':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <FormField style={{ maxWidth: '300px' }}>
                  <NumberInput
                    aria-labelledby={element.identifier}
                    value={element.value}
                    placeholder={element.placeholder}
                    data-cy={element.cy_id || ''}
                    step={1}
                    style={{ visibility: 'visible !important' }}
                    onChange={(event) => props.valueChanged(event.target.value, element.identifier)}></NumberInput>
                </FormField>
                {warning}
              </div>
            );
          case 'datetime':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <FormField className={classes.input}>
                  <DateTime
                    aria-labelledby={element.identifier}
                    placeholder={element.placeholder}
                    data-cy={element.cy_id || ''}
                    format="DD/MM/YYYY h:mm a"
                    step={5}
                    value={element.value}
                    onChange={(event) => props.valueChanged(event ? event : null, element.identifier)}
                  />
                </FormField>
                {warning}
              </div>
            );
          case 'time':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <FormField className={classes.input}>
                  <DateTime
                    aria-labelledby={element.identifier}
                    placeholder={element.placeholder}
                    format="h:mm a"
                    data-cy={element.cy_id || ''}
                    step={5}
                    value={element.value}
                    onChange={(event) => props.valueChanged(event ? event : null, element.identifier)}
                  />
                </FormField>
                {warning}
              </div>
            );
          case 'date':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <FormField className={classes.input}>
                  <DateTime
                    aria-labelledby={element.identifier}
                    placeholder={element.placeholder}
                    data-cy={element.cy_id || ''}
                    format="DD/MM/YYYY"
                    value={element.value}
                    onChange={(event) => props.valueChanged(event ? event : null, element.identifier)}
                  />
                </FormField>
                {warning}
              </div>
            );
          case 'longText':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput} style={{ minWidth: '45vw' }}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <FormField className={classes.input}>
                  <textarea
                    aria-labelledby={element.identifier}
                    value={element.value.toString()}
                    rows={3}
                    onChange={(event) =>
                      props.valueChanged(event.value ? event.value : event.target.value, element.identifier)
                    }></textarea>
                </FormField>
                {warning}
              </div>
            );
          case 'optional':
            return (
              <div key={element.identifier}>
                <OptionalFormElement
                  elementData={element}
                  optionalElementChanged={props.optionalElementChanged}
                  valueChanged={(event) =>
                    props.valueChanged(event.value ? event.value : event.target.value, element.identifier)
                  }
                  warning={warning}
                />
              </div>
            );

          case 'checkbox': {
            return (
              <CheckBox
                key={element.identifier}
                checked={element.value}
                label={element.label}
                onChange={(event) => props.valueChanged(event.target.checked, element.identifier)}
              />
            );
          }
          case 'disabled':
            return null;
          case 'videoPicker': {
            return (
              <div key={element.identifier} className={classes.videoPicker}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                {element.value.length ? `${element.value.length} video(s) selected` : 'No Videos Selected'}
                <VideoPicker
                  aria-labelledby={element.identifier}
                  selection={element.value}
                  onPick={(videoSelection) => {
                    props.valueChanged(videoSelection, element.identifier);
                  }}
                />
              </div>
            );
          }
          default:
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label id={element.identifier} className={classes.label}>
                  {element.label}
                </Label>
                <FormField className={classes.input}>
                  <TextInput
                    aria-labelledby={element.identifier}
                    value={element.value}
                    placeHolder={element.placeholder}
                    data-cy={element.cy_id || ''}
                    onDOMChange={(event) => props.valueChanged(event.target.value, element.identifier)}
                  />
                </FormField>
                {warning}
              </div>
            );
        }
      })}
    </form>
  );
};
export default FormCreator;
