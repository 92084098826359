import React, { useState, useEffect } from 'react';
import List from 'grommet/components/List';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';
import Button from 'grommet/components/Button';
import SingleTrainer from './SingleTrainer/SingleTrainer';
import TextInput from 'grommet/components/TextInput';
import classes from './ManageTrainers.module.scss';
import { isFullAccessAdminOrTrainer } from '../../../shared/userFunctions';

export default (props) => {
  const [gymsList, setGymsList] = useState({});
  const [trainerFullAccess, setTrainerFullAccess] = useState({});
  const [filteredTrainers, setFilteredTrainers] = useState([]);

  useEffect(() => {
    if (props.locations) {
      let gymsList = {};
      props.locations.forEach((elem) => {
        gymsList[elem._id] = false;
      });
      setGymsList(gymsList);
    }
    setTrainerFullAccess(isFullAccessAdminOrTrainer(props.userData));
  }, [props.locations, props.userData]);

  useEffect(() => {
    setFilteredTrainers(props.trainersList);
  }, [props.trainersList]);

  const renderTrainersList = () => {
    if (props.loading) {
      return <Spinning />;
    }
    if (!props.loading && props.error) {
      return <Notification message={props.error} status="critical" />;
    }

    if (filteredTrainers.length > 0) {
      return filteredTrainers.map((trainer) => (
        <SingleTrainer
          key={trainer._id}
          trainer={trainer}
          history={props.history}
          match={props.match}
          gymsList={gymsList}
          trainerFullAccess={trainerFullAccess}
        />
      ));
    }

    if (filteredTrainers.length === 0) {
      return 'No trainers';
    }

    return <Spinning />;
  };

  const renderButton = () => {
    if (!props.loading && props.error) {
      return (
        <Button
          style={{ marginTop: '50px', display: 'inline-block' }}
          label="Refresh"
          secondary={true}
          onClick={props.refreshTrainersList}
        />
      );
    }

    return (
      <Button
        style={{ marginTop: '50px', display: 'inline-block' }}
        label="Add"
        secondary={true}
        path={`${props.match.url}/trainers/add`}
      />
    );
  };

  const renderSearch = () => {
    if (!props.loading && !props.error && props.trainersList && props.trainersList.length > 0) {
      const onSearchChanged = (e) => {
        const searchText = e.target.value.toLowerCase();
        const filteredArray = props.trainersList.filter((elem) => {
          return elem.name.toLowerCase().includes(searchText) || elem.last_name.toLowerCase().includes(searchText);
        });
        setFilteredTrainers(filteredArray);
      };

      return (
        <TextInput placeHolder="Search: Trainer name" className={classes.searchInput} onDOMChange={onSearchChanged} />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <h4 className={classes.listHeader}>Trainer list</h4>
      {renderSearch()}
      <List>{renderTrainersList()}</List>
      {renderButton()}
    </>
  );
};
