import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header as GrommetHeader, Button, Menu, Anchor } from 'grommet';
import UserIcon from '../../../brandConfig/assets/svg/user.svg';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import Logo from '../../UI/Logo/Logo';
import classes from './header.module.scss';
import MenuNavigation from '../MenuNavigation/MenuNavigation';

const Header = ({ location, isAuthenticated, userData, gym, dashboardLink, drawerToggleClicked, menuItems }) => {
  let authSection = (
    <Button
      className={[classes.dashboardIcon, classes.hiddenMobile].join(' ')}
      label="LOGIN"
      path="/login"
      primary={true}
    />
  );
  if (isAuthenticated) {
    authSection = (
      <div className={classes.headerIcons}>
        <MenuNavigation menuItems={menuItems} />

        <Menu
          className={classes.userMenu}
          aria-label="User Menu"
          data-cy="userMenu"
          dropAlign={{ left: 'left', top: 'bottom' }}
          icon={<img className={classes.userMenuIcon} src={UserIcon} alt="user menu" />}>
          <Anchor path="/user/settings">Settings</Anchor>
          {userData &&
            userData.role === 'user' &&
            userData.customerType === 'full-member' &&
            userData.status === 'active' &&
            gym &&
            gym.settings.membershipProducts && <Anchor path="/gym/membership">Membership</Anchor>}
          {gym && <Anchor path="/gym/terms-and-conditions">Terms</Anchor>}
          <Anchor path="/logout">Logout</Anchor>
        </Menu>

        <Button
          label="Dashboard"
          className={classes.dashboardIcon}
          data-cy="dashboardBtn"
          path={dashboardLink}
          primary={true}
        />
      </div>
    );
  }

  if (location.pathname !== '/' && !isAuthenticated && gym) {
    authSection = (
      <>
        <div className={classes.hiddenMobile}>
          <Button style={{ margin: '0 10px' }} label="LOGIN" path="/login" primary={true} />
          <Button
            style={{ margin: '0 10px' }}
            label="SIGN UP"
            data-cy="btn_go_to_signup"
            path={`/gym/${gym._id}/signup`}
            primary={true}
          />
        </div>
      </>
    );
  }

  return (
    <GrommetHeader float={true}>
      <div className="container container--flexbox">
        <div className={classes.headerMenu}>
          <Logo dark={location.pathname === '/'} isAuthenticated={isAuthenticated} />
          <div className={classes.navbarContainer}>
            <div className={classes.authSection}>{authSection}</div>
            <div className={classes.mobileMenuContainer}>
              <DrawerToggle clicked={drawerToggleClicked} dark={location.pathname !== '/'} />
            </div>
          </div>
        </div>
      </div>
    </GrommetHeader>
  );
};

export default withRouter(Header);
