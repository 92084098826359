import React, { useState, useEffect } from 'react';
import classes from './DayView.module.scss';
import LinkNextIcon from 'grommet/components/icons/base/LinkNext';
import LinkPreviousIcon from 'grommet/components/icons/base/LinkPrevious';
import UserIcon from 'grommet/components/icons/base/User';
import moment from 'moment';
import { returnProductName } from '../../../../shared/classFunctions';

const DayView = ({ timetable, showClassDetail }) => {
  const [timeslots, setTimeslots] = useState([]);
  const [visibleTimeslots, setVisibleTimeslots] = useState([]);
  const timeStarts = '5:00 AM';
  const totalVisibleTimeslots = 7;

  useEffect(
    () => {
      const startTime = moment('00:00', 'h:mm A');
      const endTime = moment('23:59', 'h:mm A');
      let arrTimeslots = [];
      while (startTime <= endTime) {
        arrTimeslots.push(new moment(startTime).format('h:mm A'));
        startTime.add(60, 'minutes');
      }
      setTimeslots(arrTimeslots);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (timeslots.length > 0) {
        paginate();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeslots]
  );

  const paginate = (type) => {
    if (window.innerWidth < 991) {
      setVisibleTimeslots(timeslots);
    } else {
      let begin = timeslots.indexOf(visibleTimeslots.length === 0 ? timeStarts : visibleTimeslots[0]);
      if (type === 'next' && begin < 24 - totalVisibleTimeslots) {
        begin++;
      }
      if (type === 'previous' && begin !== 0) {
        begin--;
      }
      setVisibleTimeslots(timeslots.slice().splice(begin, totalVisibleTimeslots));
    }
  };

  const renderEventCell = (rowIndex, cellIndex, time, events) => {
    let currentEvent = events.filter((evt) => {
      return moment(evt.time, 'h:mm A').isBetween(
        moment(time, 'h:mm A'),
        moment(time, 'h:mm A').add(1, 'hours'),
        null,
        '[)'
      );
    });
    let startedEvent = [];
    if (currentEvent.length === 0 && visibleTimeslots.indexOf(time) === 0) {
      startedEvent = events.filter((evt) => {
        return moment(time, 'h:mm A').isBetween(
          moment(evt.time, 'h:mm A'),
          moment(evt.time, 'h:mm A').add(evt.duration, 'minutes'),
          null,
          '()'
        );
      });
    }

    const eventContainer = () => {
      const calendarEvent = startedEvent.length > 0 ? startedEvent[0] : currentEvent[0];
      calendarEvent.timeTo = moment(calendarEvent.time, 'h:mm A')
        .add(calendarEvent.duration, 'minutes')
        .format('h:mm A');
      const cellTime = moment(time, 'h:mm A');
      const eventStartFrom = moment(calendarEvent.time, 'h:mm A');
      const eventStartTo = moment(calendarEvent.timeTo, 'h:mm A');
      const minutes = eventStartTo.diff(eventStartFrom, 'minutes');
      let marginTime = eventStartFrom.diff(cellTime, 'minute');

      let activeAttendanceListTotal = 0;
      let totalUpdatedAttendee = 0;

      if (calendarEvent.numberAttendancesByStatus) {
        activeAttendanceListTotal =
          (calendarEvent.numberAttendancesByStatus.active ? calendarEvent.numberAttendancesByStatus.active : 0) +
          (calendarEvent.numberAttendancesByStatus.absent ? calendarEvent.numberAttendancesByStatus.absent : 0) +
          (calendarEvent.numberAttendancesByStatus.attended ? calendarEvent.numberAttendancesByStatus.attended : 0);

        totalUpdatedAttendee =
          (calendarEvent.numberAttendancesByStatus.absent ? calendarEvent.numberAttendancesByStatus.absent : 0) +
          (calendarEvent.numberAttendancesByStatus.attended ? calendarEvent.numberAttendancesByStatus.attended : 0);
      }

      const divClasses = [classes.event, classes[calendarEvent.__t]];

      if (activeAttendanceListTotal !== 0 && activeAttendanceListTotal === totalUpdatedAttendee) {
        divClasses.push(classes.allAttendeesUpdated);
      }

      return (
        <div
          style={{
            width: startedEvent.length > 0 ? `calc(${minutes * 1.666}% - 2px)` : `calc(${minutes * 1.666}% + 1px)`,
            position: 'relative',
            padding: '3.5px',
            marginLeft: `calc(1.6% * ${marginTime})`
          }}
          onClick={() => showClassDetail(calendarEvent)}>
          <div className={divClasses.join(' ')}>
            <p>{returnProductName(calendarEvent.__t)}</p>
          </div>
        </div>
      );
    };

    return (
      <td className={classes.eventCell} key={`${rowIndex}_${cellIndex}`}>
        {(currentEvent.length > 0 || startedEvent.length > 0) && eventContainer()}
      </td>
    );
  };

  const renderRow = (rowIndex, userEvents) => (
    <tr key={rowIndex}>
      <td className={classes.titleColumn}>
        <UserIcon className={classes.xsHidden} />{' '}
        <span className={classes.xsOnly}>
          {userEvents.leader.name.charAt(0)} {userEvents.leader.last_name}
        </span>
        <span className={classes.xsHidden}>
          {userEvents.leader.name} {userEvents.leader.last_name}
        </span>
      </td>
      {visibleTimeslots.map((time, index) => {
        return renderEventCell(rowIndex, index, time, userEvents.classes);
      })}
      <td></td>
    </tr>
  );

  return (
    <>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={`${classes.day} ${classes.titleColumn}`}>
                <div className={classes.xsHidden}>
                  Names
                  <LinkPreviousIcon className={classes.previousBtn} onClick={() => paginate('previous')} />
                </div>
              </th>
              {visibleTimeslots.map((time) => (
                <th key={time}>{time}</th>
              ))}
              <th className={`${classes.nextBtnColumn} ${classes.xsHidden}`}>
                <LinkNextIcon onClick={() => paginate('next')} />
              </th>
            </tr>
          </thead>
          <tbody>
            {timetable.length > 0 ? (
              timetable.map((userEvents, index) => renderRow(index, userEvents))
            ) : (
              <tr>
                <td colSpan="9">No record found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default DayView;
