import React, { useState, useEffect } from 'react';
import classes from './ManageAdmins.module.scss';
import withManageAdmins from '../../../store/hoc/withManageAdmins';
import { isFullAccessAdminOrTrainer } from '../../../shared/userFunctions';
import List from 'grommet/components/List';
import Notification from 'grommet/components/Notification';
import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import TextInput from 'grommet/components/TextInput';
import { SingleAdmin } from './SingleTrainer/SingleAdmin';

function ManageAdmins({
  locations,
  userData,
  adminsListLoading,
  adminsList,
  adminsListError,
  history,
  selectedGym,
  onDeleteAdmin,
  onGetAdmins,
  match
}) {
  const [gymsList, setGymsList] = useState({});
  const [hasFullAccess, setHasFullAccess] = useState({});
  const [filteredAdmins, setFilteredAdmins] = useState([]);

  useEffect(() => {
    if (locations) {
      let gymsList = {};
      locations.forEach((elem) => {
        gymsList[elem._id] = false;
      });
      setGymsList(gymsList);
    }
    setHasFullAccess(isFullAccessAdminOrTrainer(userData));
  }, [locations, userData]);

  useEffect(() => {
    setFilteredAdmins(adminsList);
  }, [adminsList]);

  const renderAdminList = () => {
    if (adminsListLoading) {
      return <Spinning size="xlarge" />;
    }
    const deleteAdminFunction = (adminId) => {
      onDeleteAdmin(adminId);
      setTimeout(() => onGetAdmins(selectedGym._id), 1000);
    };
    if (adminsListError) {
      return <Notification message={'Something went wrong'} status="critical" />;
    }

    if (filteredAdmins.length > 0) {
      return filteredAdmins.map((admin) => (
        <SingleAdmin
          adminId={userData._id}
          hasFullAccess={hasFullAccess}
          singleAdmin={admin}
          key={admin._id}
          gymsList={gymsList}
          history={history}
          match={match}
          deleteAdmin={() => deleteAdminFunction(admin._id)}
          getAllAdminAfterEdit={true}
        />
      ));
    }

    if (filteredAdmins.length === 0) {
      return 'No admins';
    }
  };

  const renderSearch = () => {
    if (!adminsListLoading && !adminsListError && adminsList && adminsList.length > 0) {
      const onSearchChanged = (e) => {
        const searchText = e.target.value.toLowerCase();
        const filteredArray = adminsList.filter((elem) => {
          return elem.name.toLowerCase().includes(searchText) || elem.last_name.toLowerCase().includes(searchText);
        });
        setFilteredAdmins(filteredArray);
      };

      return (
        <TextInput placeHolder="Search: Admin name" className={classes.searchInput} onDOMChange={onSearchChanged} />
      );
    } else {
      return null;
    }
  };

  const addAdminButton = (
    <div className={classes.addAdminButton}>
      <Button
        box
        label="Add new Admin"
        secondary={true}
        onClick={() => history.push(`/gym/${selectedGym._id}/admins/add`)}
      />
    </div>
  );

  return (
    <div>
      {addAdminButton}
      <br />
      <h4 className={classes.listHeader}>Admin list</h4>
      {renderSearch()}
      <List>{renderAdminList()}</List>
    </div>
  );
}

export default withManageAdmins(ManageAdmins);
