import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Spinning from 'grommet/components/icons/Spinning';

export default (Component) => {
  const mapStateToProps = (state) => {
    return {
      userData: state.auth.userData,
      userDataLoading: state.auth.loading,
      isAuthenticated: state.auth.token !== null,

      gymDataLoading: state.admin.gymDataLoading,
      gymDataError: state.admin.gymDataError,
      gym: state.admin.selectedGym,

      plansListLoading: state.client.plansListLoading,
      plansListError: state.client.plansListError,
      plansList: state.client.plansList,

      currentStatus: state.client.currentStatus,
      currentStatusLoading: state.client.currentStatusLoading,
      currentStatusError: state.client.currentStatusError,

      upcomingPts: state.client.upcomingPts,
      upcomingPtsLoading: state.client.upcomingPtsLoading,
      upcomingPtsError: state.client.upcomingPtsError
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId)),
      onFetchPlansList: (gymId) => dispatch(actions.fetchPlansListForClient(gymId)),
      onFetchSubscriptionStatus: () => dispatch(actions.fetchSubscriptionStatus()),
      onFetchSources: () => dispatch(actions.fetchSources())
    };
  };

  class Wrapper extends React.Component {
    componentDidMount() {
      this.props.onFetchGymDetails(this.props.userData.gymId);
      this.props.onFetchPlansList(this.props.userData.gymId);
      this.props.onFetchSubscriptionStatus();
      this.props.onFetchSources();
    }

    componentDidUpdate() {
      if (!this.props.currentStatusLoading && !this.props.plansListLoading && sessionStorage.getItem('redirect')) {
        const redirect = JSON.parse(sessionStorage.getItem('redirect'));
        sessionStorage.removeItem('redirect');

        if (redirect.goToPayment) {
          this.props.history.push(`${this.props.match.url}/membership/pay/${redirect.planId}`);
        } else {
          this.props.history.push(`${this.props.match.url}/membership`);
        }
      }
    }

    render() {
      if (!this.props.userData || this.props.loading) {
        return <Spinning />;
      }
      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
