import React, { useState, useEffect } from 'react';
import classes from '../../../containers/AddEditSingleClass/AddEditSingleClass.module.scss';
import Label from 'grommet/components/Label';
import Select from 'grommet/components/Select';
import Modal from 'react-modal';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import Button from 'grommet/components/Button';
import { returnClientsListWithSamePTPasses } from '../../../shared/gymFunctions';
import { useQuery } from 'react-query';
import { fetchUsersList } from '../../../apiFunctions/apiFunctions';

const PTClientSelector = ({ users, setSelectedData, isValid, setStatus, showLabel = true, gymId = null }) => {
  const [usersList, setUsersList] = useState(users);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showModal, setShowModal] = useState([]);
  const [selectedPass, setSelectedPass] = useState(null);
  const [isFull, setIsFull] = useState(false);

  const { data, isLoading, error } = useQuery(['usersList', gymId], () => fetchUsersList(gymId), {
    enabled: gymId !== null
  });

  useEffect(
    () => {
      setStatus(users.length === 0 ? 'noData' : 'ok');
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      setIsFull(selectedPass && selectedPass.places <= selectedUsers.length);
      if (selectedUsers.length > 0 && selectedPass) {
        setSelectedData({ pass: selectedPass, users: selectedUsers });
        setFilteredUsers(returnUsersWithSamePasses());
      }
    }, // eslint-disable-next-line
    [selectedUsers, selectedPass]
  );

  useEffect(
    () => {
      setStatus(isLoading ? 'isLoading' : 'error');
      if (data) {
        const usersData = data;
        setStatus(usersData.length === 0 ? 'noData' : 'ok');
        setUsersList(
          usersData.length === 0
            ? []
            : usersData.filter((elem) => elem.status === 'active' && elem.PTclassesLeft.length > 0)
        );
      }
    }, // eslint-disable-next-line
    [data, isLoading, error]
  );

  if (usersList.length === 0) {
    return <div className={classes.noData}>No clients with PT passes.</div>;
  }
  const mapClientToSelect = (array) =>
    array.map((elem) => {
      return {
        ...elem,
        label: `${elem.name} ${elem.last_name}`,
        value: elem
      };
    });

  const modalPassSelection = () =>
    selectedUsers &&
    selectedUsers[0] && (
      <Modal
        isOpen={showModal}
        className="ss-modal"
        onRequestClose={() => {
          setShowModal(false);
          removeUser(selectedUsers[0]._id);
        }}>
        <div className="grommet">
          <h4 className="ss-list-header">{selectedUsers[0].name}'s PT Packages</h4>
          <List>
            {selectedUsers[0].PTclassesLeft.map((elem, index) => (
              <ListItem
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
                key={index}
                direction="column"
                align="start"
                separator="horizontal">
                {elem.classCount} X {elem.duration} minutes (Places: {elem.places}) ({elem.classesLeft} left)
                <Button
                  label="Select"
                  secondary={true}
                  onClick={() => {
                    setSelectedPass(elem);
                    setShowModal(false);
                  }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Modal>
    );

  const addUser = (user) => {
    if (selectedUsers.length === 0) {
      setShowModal(true);
    }
    setSelectedUsers([...selectedUsers, user]);
  };

  const removeUser = (clientId) => {
    const selectedClients = selectedUsers.filter((client) => client._id !== clientId);
    if (selectedClients.length === 0) {
      setSelectedPass(null);
      setFilteredUsers(null);
      setSelectedData(null);
    }
    setSelectedUsers(selectedClients);
  };

  const renderUsersList = () => {
    return (
      selectedUsers.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <h4>Client list</h4>
          <List>
            {selectedUsers.map((client) => (
              <ListItem
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
                key={client._id}
                direction="column"
                align="start"
                separator="horizontal">
                {client.label}
                <Button label="Remove" secondary={true} onClick={() => removeUser(client._id)} />
              </ListItem>
            ))}
          </List>
        </div>
      )
    );
  };

  const onFilterClientList = (text) => {
    const filter = text.toLowerCase();
    const data = filteredUsers ? filteredUsers : usersList;
    const filteredClientList = data.filter(
      (x) => x.name.toLowerCase().startsWith(filter) || x.last_name.toLowerCase().startsWith(filter)
    );

    setFilteredUsers(filteredClientList);
  };

  const returnUsersWithSamePasses = () =>
    returnClientsListWithSamePTPasses(usersList, selectedPass.duration, selectedPass.places).filter(
      (client) => selectedUsers.filter((user) => user._id !== client._id).length > 0
    );

  const renderClientSelector = isFull ? (
    <Select value={'All places taken'} options={[]} />
  ) : (
    <Select
      options={mapClientToSelect(filteredUsers || usersList)}
      value="Add client"
      onChange={(e) => addUser(e.option)}
      onSearch={(e) => onFilterClientList(e.target.value)}
    />
  );

  return (
    <div style={{ marginBottom: '20px' }}>
      {modalPassSelection()}
      {showLabel && <Label>Add client</Label>}
      {renderClientSelector}
      {isValid !== null && !isValid && (
        <div className={classes.selector}>
          <span>Please select client</span>
        </div>
      )}
      {renderUsersList()}
    </div>
  );
};

export default PTClientSelector;
