import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ALL_CLASS_TYPES_WITH_DETAILS } from '../../constants';
import { Tab, Tabs } from 'grommet';
import DefaultTimetable from '../../containers/Timetables/DefaultTimetable/DefaultTimetable/DefaultTimetable';
import Button from 'grommet/components/Button';
import UpcomingClassesForAllGymsFilters from '../../containers/Timetables/UpcomingClassesForAllGyms/UpcomingClassesForAllGymsFilters';
import { fetchClientUpcomingClasses } from '../../apiFunctions/apiFunctions';
import classes from './TrainersUpcomingClasses.module.scss';
const returnClassesList = (array = []) => {
  let result = [];
  result = array;
  result = result.map((elem) => {
    return {
      limit: elem.limit,
      _id: elem._id,
      name: elem.name,
      cost: elem.cost,
      currency: elem.currency,
      classDate: elem.classDate,
      classTime: elem.classTime,
      gymId: [
        {
          _id: elem.locationId,
          name: elem.locationName
        }
      ],
      gymName: [elem.locationName],
      joinedUsers: elem.joinedUsers,

      active: true,
      duration: elem.duration,
      __t: elem.__t
    };
  });

  return result;
};

const TrainersUpcomingClasses = ({ userGyms }) => {
  const classTypes = ALL_CLASS_TYPES_WITH_DETAILS();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showFilters, setShowFilters] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { data, isLoading, error } = useQuery(['allTrainerUpcomingClasses'], () => fetchClientUpcomingClasses('all'));

  useEffect(() => {
    setFilteredData(data !== undefined ? returnClassesList(data) : []);
  }, [data]);

  const renderDataByTab = (elem) => {
    let dataToSend = filteredData.filter((data) => data.__t === elem.type);
    return (
      <DefaultTimetable
        classesList={dataToSend}
        classesListLoading={isLoading}
        classesListError={error}
        classesDetails={elem}
        classType={elem.type}
        showAllGymsData={true}
        classConfig={{}}
      />
    );
  };

  const renderTabs = (
    <Tabs justify="start" activeIndex={activeIndex} onActive={setActiveIndex}>
      {classTypes.map((elem) => (
        <Tab title={elem.title} key={elem.type}>
          {renderDataByTab(elem)}
        </Tab>
      ))}
    </Tabs>
  );

  return (
    <div>
      <div className={classes.addNewButton}>
        <Button primary label="Filters" onClick={() => setShowFilters((showFilters) => !showFilters)} />
      </div>

      <UpcomingClassesForAllGymsFilters
        showFilters={showFilters}
        gyms={userGyms}
        onFilter={setFilteredData}
        data={returnClassesList(data)}
        hideFiters={() => setShowFilters(false)}
      />
      {renderTabs}
    </div>
  );
};

export default withRouter(TrainersUpcomingClasses);
