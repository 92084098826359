import React from 'react';

import withUser from '../../../store/hoc/withUser';
import { withRouter } from 'react-router-dom';
import { returnClassConfig } from '../../../shared/gymFunctions';
import { useQuery } from 'react-query';
import { fetchUpcomingClasses, fetchPasses } from '../../../apiFunctions/apiFunctions';
import { returnClassesDetails } from '../../../shared/classFunctions';
import UserView from './UsersView/UserView';
import AdminView from './UsersView/AdminView';
import { CLASS, PRODUCT_TYPES } from '../../../constants';

const ManageTimetable = ({ userData, userProducts = [], selectedGym, classType }) => {
  const gymId = userData.role === 'user' ? userData.gymId : selectedGym._id;
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);
  const classesDetails = returnClassesDetails(classType);
  const vodEnabled = selectedGym?.settings?.videoOnDemand || false;

  const {
    data: classesList,
    error: classesListError,
    isLoading: classesListLoading,
    isFetching,
    refetch: classesListReFetch
  } = useQuery(['upcomingClasses', classType], () => fetchUpcomingClasses(classType, gymId), {
    enabled: classType !== CLASS.VIDEO
  });
  const { data: videoProducts } = useQuery(['videoPasses', classType], () => fetchPasses('video', gymId), {
    enabled: classType !== CLASS.VIDEO && vodEnabled,
    select: (data) => data.filter((product) => product.__t === PRODUCT_TYPES.VIDEO && product.forClass === classType)
  });

  const userVideoProducts = vodEnabled
    ? userProducts.filter((product) => product.__t === PRODUCT_TYPES.VIDEO && product.forClass === classType)
    : undefined;

  if (userData.role === 'user') {
    return (
      <UserView
        classesList={classesList}
        classesListLoading={classesListLoading || isFetching}
        classesListError={classesListError}
        onFetchClasses={classesListReFetch}
        classesDetails={classesDetails}
        classConfig={classConfig}
        userVideoProducts={userVideoProducts}
        videoProducts={videoProducts}
      />
    );
  }

  return (
    <AdminView
      classType={classType}
      classesList={classesList}
      classesListLoading={classesListLoading || isFetching}
      classesListError={classesListError}
      onFetchClasses={classesListReFetch}
      classesDetails={classesDetails}
      classConfig={classConfig}
    />
  );
};

export default withRouter(withUser(ManageTimetable));
