import React, { useState, useEffect } from 'react';
import Tab from 'grommet/components/Tab';
import Tabs from 'grommet/components/Tabs';
import Table from 'grommet/components/Table';
import TableRow from 'grommet/components/TableRow';
import TextInput from 'grommet/components/TextInput';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import moment from 'moment';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import { useQuery } from 'react-query';
import { fetchDebtorsReport } from '../../../../apiFunctions/apiFunctions';
import './DebtorReports.scss';
import Loading from '../../../../components/Loading/Loading';
import Notification from 'grommet/components/Notification';
import Select from 'grommet/components/Select';
import RefreshIcon from 'grommet/components/icons/base/Refresh';
export const DebtorsReports = (props) => {
  const [selectedGym, setSelectedGym] = useState({});
  const [debtorReportsPayg, setDebtorsReportPayg] = useState([]);
  const [debtorReportsPaygAllData, setDebtorsReportPaygAllData] = useState([]);
  const [debtorReportsMembership, setDebtorsReportMembership] = useState([]);
  const [debtorReportsMembershipAllData, setDebtorsReportMembershipAllData] = useState([]);
  const [membershipSearch, setMembershipSearch] = useState('');
  const [paygSearch, setPaygSearch] = useState('');
  const [filteredGym, setFilteredGym] = useState(null);
  const { data, isLoading, error } = useQuery(['fetchDebtorsReport', selectedGym.id], () =>
    fetchDebtorsReport(selectedGym.id)
  );

  let options = props.locations.map((elem) => {
    return {
      label: elem.name,
      id: elem._id
    };
  });

  useEffect(
    () => {
      if (data && !isLoading && !error) {
        setDebtorsReportPayg(data.unpaidOneOffCharges);
        setDebtorsReportPaygAllData(data.unpaidOneOffCharges);
        setDebtorsReportMembership(data.failedSubscriptionCharges);
        setDebtorsReportMembershipAllData(data.failedSubscriptionCharges);
        filterDataBySearchText(data.unpaidOneOffCharges, data.failedSubscriptionCharges);
      }
    }, // eslint-disable-next-line
    [selectedGym, data, isLoading, error]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  const getTableHeader = (type) => {
    if (type === 'payg') {
      return (
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Created</th>
          <th>Class Date</th>
          <th>Amount</th>
        </tr>
      );
    } else if (type === 'membership') {
      return (
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Failed On</th>
          <th>Plan</th>
          <th>Expired</th>
          <th>Amount</th>
        </tr>
      );
    }
  };

  const getTableRows = (type) => {
    if (type === 'payg') {
      return debtorReportsPayg.map((debtorReport, index) => {
        return (
          <TableRow key={index} className="booking-item">
            <td>
              {debtorReport.name} {debtorReport.lastName}
            </td>
            <td>{debtorReport.userType}</td>
            <td>{moment(debtorReport.createdDate).format('DD-MM-YYYY')}</td>
            <td>{moment(debtorReport.classDate).format('DD-MM-YYYY')}</td>
            <td>{debtorReport.cost}</td>
          </TableRow>
        );
      });
    } else if (type === 'membership') {
      return debtorReportsMembership.map((debtorReport, index) => {
        return (
          <TableRow key={index} className="booking-item">
            <td>
              {debtorReport.name} {debtorReport.lastName}
            </td>
            <td>{debtorReport.userType}</td>
            <td>{moment(debtorReport.date).format('DD-MM-YYYY')}</td>
            <td>{debtorReport.planName ? debtorReport.planName : '-'}</td>
            <td>{debtorReport.currentPeriodEnd ? moment(debtorReport.currentPeriodEnd).format('DD-MM-YYYY') : '-'}</td>
            <td>{debtorReport.cost}</td>
          </TableRow>
        );
      });
    }
  };

  const onSearchChanged = (type, searchValue) => {
    const searchText = searchValue;
    let arrayForFilter = type === 'payg' ? debtorReportsPaygAllData : debtorReportsMembershipAllData;

    if (type === 'payg') {
      setPaygSearch(searchText);
    } else {
      setMembershipSearch(searchText);
    }

    const filteredArray = arrayForFilter.filter(function (item) {
      return item.name.toLowerCase().includes(searchText) || item.lastName.toLowerCase().includes(searchText);
    });

    if (type === 'payg') {
      setDebtorsReportPayg(filteredArray);
    } else {
      setDebtorsReportMembership(filteredArray);
    }
  };

  const onFilterGymList = (e) => {
    const filter = e.target.value.toLowerCase();
    const filteredGymList = props.locations.filter((gym) => {
      return gym.name.toLowerCase().includes(filter);
    });

    setFilteredGym(
      filteredGymList.map((elem) => {
        return {
          label: elem.name,
          id: elem._id
        };
      })
    );
  };

  const filterDataBySearchText = (unpaidOneOffCharges, failedSubscriptionCharges) => {
    let filteredArray;
    if (paygSearch !== '') {
      filteredArray = unpaidOneOffCharges.filter(function (item) {
        return item.name.toLowerCase().includes(paygSearch) || item.lastName.toLowerCase().includes(paygSearch);
      });
      setDebtorsReportPayg(filteredArray);
    }

    if (membershipSearch !== '') {
      filteredArray = failedSubscriptionCharges.filter(function (item) {
        return (
          item.name.toLowerCase().includes(membershipSearch) || item.lastName.toLowerCase().includes(membershipSearch)
        );
      });
      setDebtorsReportMembership(filteredArray);
    }
  };

  const gymSelection = (type) => {
    let arrayAccordingType = type === 'payg' ? debtorReportsPaygAllData : debtorReportsMembershipAllData;

    let filteredArrayAccordingType = type === 'payg' ? debtorReportsPayg : debtorReportsMembership;

    return (
      <div>
        <div className="report__filter_debtors">
          <div>
            <h4>Select gym</h4>
            <div>
              <Select
                placeHolder="Gym: all"
                options={filteredGym || options}
                value={selectedGym}
                onChange={(event) => setSelectedGym(event.option)}
                onSearch={onFilterGymList}
              />
            </div>
          </div>

          {selectedGym.id && (
            <div>
              <RefreshIcon className="refresh_icon" onClick={() => setSelectedGym({})} />
            </div>
          )}

          {arrayAccordingType && arrayAccordingType.length > 0 && (
            <div>
              <h4>Filter by name</h4>

              {type === 'payg' && (
                <TextInput
                  id="paygSearch"
                  value={paygSearch}
                  name="client-name-filter"
                  placeHolder="client name"
                  onDOMChange={(e) => onSearchChanged('payg', e.target.value.toLowerCase())}
                />
              )}

              {type === 'membership' && (
                <TextInput
                  id="membershipSearch"
                  value={membershipSearch}
                  name="client-name-filter"
                  placeHolder="client name"
                  onDOMChange={(e) => onSearchChanged('membership', e.target.value.toLowerCase())}
                />
              )}
            </div>
          )}

          {filteredArrayAccordingType && filteredArrayAccordingType.length > 0 && (
            <div>
              {type === 'payg' && (
                <ExportToCSV data={debtorReportsPayg} type={'debtorsReportsPayg'} gymName={selectedGym.label} />
              )}

              {type === 'membership' && (
                <ExportToCSV
                  data={debtorReportsMembership}
                  type={'debtorsReportsMembership'}
                  gymName={selectedGym.label}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTabBody = (type) => {
    let arrayAccordingType = type === 'payg' ? debtorReportsPayg : debtorReportsMembership;
    return (
      <>
        {gymSelection(type)}
        {arrayAccordingType && arrayAccordingType.length > 0 ? (
          <Table>
            <thead>{getTableHeader(type)}</thead>
            <tbody>{getTableRows(type)}</tbody>
          </Table>
        ) : (
          <div>
            <div className="report__filter_debtors">
              <div>
                <h3>No history</h3>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Tabs responsive={false} justify="start" data-cy="tabPanel">
        <Tab title="PAYG">{renderTabBody('payg')}</Tab>
        <Tab title="Membership">{renderTabBody('membership')}</Tab>
      </Tabs>
    </>
  );
};

export default withAdminDashboard(DebtorsReports);
