import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from 'react-query';
import uniqBy from 'lodash.uniqby';
import uniq from 'lodash.uniq';
import Thumbnail from './Thumbnail/Thumbnail';
import Tiles from 'grommet/components/Tiles';
import Tile from 'grommet/components/Tile';
import Accordion from 'grommet/components/Accordion';
import AccordionPanel from 'grommet/components/AccordionPanel';
import Label from 'grommet/components/Label';
import Select from 'grommet/components/Select';
import Button from 'grommet/components/Button';
import TrainerSelector from '../../../components/Selectors/TrainerSelector/TrainerSelector';
import Reorder from './Reorder/Reorder';
import { getVideoKey } from '../../../constants';
import withUser from '../../../store/hoc/withUser';
import { videoPassCompleted } from '../../../apiFunctions/apiFunctions';
import CustomToast from '../../../components/CustomToast/CustomToast';

import classes from './Menu.module.scss';

const Menu = ({
  videos = [],
  picker = false,
  pickerSelection = [],
  onPick = () => {},
  onReorder = () => {},
  productList = [],
  gymId,
  isAdmin = false,
  enforceVideoOrder = false,
  selectedProduct,
  userData,
  ...props
}) => {
  const [watched, setWatched] = useState({});
  const [allWatched, setAllWatched] = useState(false);
  const [purchaseId, setPurchaseId] = useState('');
  const [filters, setFilters] = useState({});
  const [reorder, setReorder] = useState(false);
  const [toast, setToast] = useState(null);
  const { isLoading, mutate } = useMutation([], (id) => videoPassCompleted(id), {
    onSuccess: ({ msg }) => {
      setToast({
        status: 'ok',
        msg,
        show: true
      });
    },
    onError: ({ response }) => {
      if (response?.data?.errors[0]?.msg === 'you have already watched all the videos') {
        setToast({
          status: 'warning',
          msg: 'Your confirmation is already registered',
          show: true
        });
      } else {
        setToast({
          status: 'critical',
          msg: 'An error occurred submitting your confirmation',
          show: true
        });
      }
    }
  });

  const selectedVideos = pickerSelection.map((selection) => videos.find((video) => video._id === selection));

  const uniqueVideos = uniqBy(videos, '_id');

  useEffect(() => {
    const userId = userData?._id || '';
    const USER_VOD = getVideoKey(userId);
    const persisted = localStorage.getItem(USER_VOD);
    const watchedVideos = persisted ? JSON.parse(persisted) : {};

    setWatched(watchedVideos);
    if (videos && videos.length) {
      if (videos.every((video) => !!watchedVideos[video._id])) {
        setAllWatched(true);
      }
    }
  }, [userData?._id, videos]);

  useEffect(() => {
    if (selectedProduct) {
      const purchase = userData?.products?.find((product) => product?.productId?._id === selectedProduct?._id);

      setPurchaseId(purchase?._id);
    }
  }, [selectedProduct, userData?.products]);

  const metadata = uniqueVideos
    .flatMap((video) => video.videoConfig)
    .filter((category) => !!category.active)
    .reduce((acc, category) => {
      const prev = acc[category.name];
      if (prev && prev.options) {
        return {
          ...acc,
          [category.name]: { ...acc[category.name], options: uniq([...prev.options, ...category.options]) }
        };
      }
      return {
        ...acc,
        [category.name]: { ...category }
      };
    }, {});

  const categories = Object.values(metadata);
  const filteredVideos = uniqueVideos
    .filter((video) => {
      for (const key in filters) {
        const filterValue = filters[key];

        if (key === 'trainer') {
          return filterValue === video.trainer;
        }

        if (key === 'products') {
          return filterValue.every((filterValue) => filterValue.videos.includes(video._id));
        }

        const videoConfigCategory = video.videoConfig && video.videoConfig.find((config) => config.name === key);
        const videoTags = videoConfigCategory.options || [];

        if (!videoTags || !videoTags.length) {
          return false;
        }

        if (Array.isArray(filterValue) && !filterValue.every((filterValue) => videoTags.includes(filterValue))) {
          return false;
        }
      }

      return true;
    })
    .map((video) => ({ ...video, watched: !!watched[video._id] || false }));

  return (
    <>
      <CustomToast toast={toast} />
      {isAdmin ? (
        <>
          <Accordion key={isAdmin}>
            <AccordionPanel key={isAdmin} heading="Filters">
              <div className={classes.filterBar}>
                {productList && !!productList.length && (
                  <div>
                    <Label id="product">{`Product`}</Label>
                    <Select
                      aria-labelledby="product"
                      multiple
                      value={filters.products}
                      options={productList.map((product) => ({
                        label: product.name,
                        value: product._id,
                        videos: product.videos || []
                      }))}
                      onChange={({ value }) => {
                        setFilters({
                          ...filters,
                          products: value
                        });
                      }}
                    />
                  </div>
                )}

                {isAdmin && (
                  // trainer details is admin only request
                  <TrainerSelector
                    selectedTrainer={filters.trainer}
                    onSelectTrainer={(trainer) => {
                      setFilters({
                        ...filters,
                        trainer: trainer._id
                      });
                    }}
                    gymId={gymId}
                  />
                )}

                {categories.map((category, index) => (
                  <div key={category.name}>
                    <Label id={index}>{category.name}</Label>
                    <Select
                      aria-labelledby={index}
                      multiple
                      options={category.options}
                      value={filters[category.name]}
                      onChange={({ value }) => {
                        setFilters({
                          ...filters,
                          [category.name]: value
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </AccordionPanel>
          </Accordion>
          <div className={classes.viewActionBar}>
            {picker && (
              <Button label={reorder ? 'Pick' : 'Reorder'} secondary={true} onClick={() => setReorder(!reorder)} />
            )}
            <Button label="Clear Filters" secondary={true} onClick={() => setFilters({})} />
          </div>
        </>
      ) : null}
      {
        <>
          <h3>Videos</h3>
          {!isAdmin && !!purchaseId?.length && allWatched && (
            <Button
              disabled={isLoading}
              className={classes.completedVideo}
              label="Confirm completed"
              secondary={true}
              onClick={() => mutate(purchaseId)}
            />
          )}
          {reorder ? (
            <Reorder videos={selectedVideos} onChange={onReorder} />
          ) : (
            <Tiles className={classes.videoMenu}>
              {filteredVideos.map((video, index) => (
                <Tile key={`${video._id}-`}>
                  <Thumbnail
                    video={video}
                    selected={pickerSelection.includes(video._id)}
                    productList={productList}
                    gymId={gymId}
                    isAdmin={isAdmin}
                    picker={picker}
                    onPick={onPick}
                    locked={enforceVideoOrder ? index > 0 && !filteredVideos[index - 1]?.watched : false}
                    watched={video?.watched}
                    {...props}
                  />
                </Tile>
              ))}
            </Tiles>
          )}
        </>
      }
    </>
  );
};

export default withUser(withRouter(Menu));
