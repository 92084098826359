import React from 'react';
import { CSVLink } from 'react-csv';
import classes from './ExportToCSV.module.scss';
import moment from 'moment';
import { CLIENTS_TYPES } from '../../../constants';

const ExportToCSV = ({ data, type, gymName = '' }) => {
  let csvData = [];

  switch (type) {
    case 'classPassesReport': {
      csvData = data.map((elem) => {
        return {
          gym: gymName,
          name: elem.user.name,
          last_name: elem.user.last_name,
          classPassesOverall: elem.total
        };
      });
      break;
    }
    case 'attendanceReport': {
      csvData = data.map((elem) => {
        return {
          gym: gymName,
          name: elem.userId.name,
          last_name: elem.userId.last_name,
          createdDate: moment(elem.createdDate).format('YYYY-MM-DD')
        };
      });
      break;
    }
    case 'clientsDetailReport': {
      csvData = data.map((elem) => {
        return {
          gym: gymName,
          name: elem.props.client.name,
          last_name: elem.props.client.last_name,
          email: elem.props.client.email,
          address_line1: elem.props.client.address_line1,
          account_type: elem.props.client.customerType === CLIENTS_TYPES.FULL_MEMBER ? 'Membership' : 'Class Pass',
          membership_status:
            elem.props.client.customerType === CLIENTS_TYPES.FULL_MEMBER ? elem.props.client.status : 'N/A',
          marketing: elem.props.client.marketing === 'true' ? 'Y' : 'N',
          planName: elem.props.client.currentMembershipPlan ? elem.props.client.currentMembershipPlan.planName : 'N/A',
          current_period_end: elem.props.client.currentMembershipPlan
            ? moment(elem.props.client.currentMembershipPlan.current_period_end).format('YYYY-MM-DD')
            : 'N/A',
          products_purchased: elem.props.client.products.length > 0 ? 'true' : 'false'
        };
      });
      break;
    }
    case 'classAttendanceReport': {
      csvData = data.map((elem) => {
        return {
          gym: gymName,
          classId: elem.id,
          name: elem.eventName,
          type: elem.eventType,
          trainer: elem.hostName,
          date: moment(elem.date).format('YYYY-MM-DD'),
          time: elem.time,
          capacity: elem.capacity,
          attendanceStatus_ACTIVE: elem.attendances && elem.attendances.active ? elem.attendances.active.count : 0,
          attendanceStatus_ABSENT: elem.attendances && elem.attendances.absent ? elem.attendances.absent.count : 0,
          attendanceStatus_ATTENDED:
            elem.attendances && elem.attendances.attended ? elem.attendances.attended.count : 0,
          attendanceStatus_CANCELLED:
            elem.attendances && elem.attendances.cancelled ? elem.attendances.cancelled.count : 0,
          attendanceStatus_LATE_CANCELLATION:
            elem.attendances && elem.attendances['late-cancellation'] ? elem.attendances['late-cancellation'].count : 0,
          attendanceStatus_PENDING: elem.attendances && elem.attendances.pending ? elem.attendances.pending.count : 0
        };
      });
      break;
    }
    case 'classAttendanceNameReport': {
      data.forEach((classDetail) => {
        if (classDetail.attendances) {
          for (let status in classDetail.attendances) {
            if (classDetail.attendances[status].attendeesDetail) {
              const attendeesDetail = classDetail.attendances[status].attendeesDetail;
              for (let index = 0; index < attendeesDetail.length; index++) {
                csvData.push({
                  gym: gymName,
                  classId: classDetail.id,
                  name: classDetail.eventName,
                  type: classDetail.eventType,
                  trainer: classDetail.hostName,
                  date: moment(classDetail.date).format('YYYY-MM-DD'),
                  time: classDetail.time,
                  capacity: classDetail.capacity,
                  userId: attendeesDetail[index].id,
                  user: `${attendeesDetail[index].name} ${attendeesDetail[index].lastName}`,
                  status
                });
              }
            }
          }
        } else {
          let csvRow = {
            gym: gymName,
            classId: classDetail.id,
            name: classDetail.eventName,
            type: classDetail.eventType,
            trainer: classDetail.hostName,
            date: moment(classDetail.date).format('YYYY-MM-DD'),
            time: classDetail.time,
            capacity: classDetail.capacity
          };
          csvData.push(csvRow);
        }
      });
      break;
    }
    case 'classDetail': {
      csvData = data.attendanceList.map((attendance) => {
        return {
          gym: gymName,
          id: data._id,
          name: data.name,
          date: moment(data.classDate).format('YYYY-MM-DD'),
          time: data.classTime,
          trainer: data.trainer ? `${data.trainer.name} ${data.trainer.last_name}` : ' ',
          user: `${attendance.user.name} ${attendance.user.last_name}`,
          status: attendance.status
        };
      });
      break;
    }
    case 'debtorsReportsPayg': {
      csvData = data.map((element) => {
        return {
          gym: element.gymName,
          name: element.name,
          lastName: element.lastName,
          type: element.userType,
          createdDate: moment(element.createdDate).format('YYYY-MM-DD'),
          classDate: moment(element.classDate).format('YYYY-MM-DD'),
          amount: element.cost
        };
      });
      break;
    }
    case 'debtorsReportsMembership': {
      csvData = data.map((element) => {
        return {
          gym: element.gymName,
          name: element.name,
          lastName: element.lastName,
          type: element.userType,
          dateFailedOn: moment(element.dateFailedOn).format('YYYY-MM-DD'),
          planName: element.planName,
          expiryDate: moment(element.currentPeriodEnd).format('YYYY-MM-DD'),
          amount: element.cost
        };
      });
      break;
    }
    case 'payrollReport': {
      data.map((report) => {
        report.classes.map((element) => {
          csvData.push({
            classId: element._id,
            className: element.name,
            classDate: moment(element.classDate).format('YYYY-MM-DD'),
            classType: element.classType,
            classTime: element.classTime,
            gymId: element.gymId,
            gymName: element.gymName,
            trainerId: report._id,
            trainerName: report.trainer
          });
        });
      });
      break;
    }
    default:
      return (csvData = [{ info: 'wrong data' }]);
  }

  return (
    <CSVLink
      data={csvData}
      className={`${
        type === 'debtorsReportsPayg' || type === 'debtorsReportsMembership' ? classes.buttonDebtors : classes.button
      }`}
      filename={`${gymName}_${type}_${moment().format('DD/MM/YYYY')}.csv`}>
      Export to CSV
    </CSVLink>
  );
};

export default ExportToCSV;
