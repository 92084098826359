import React, { useState, useEffect } from 'react';
import classes from './AddEditCategory.module.scss';
import { Heading, Button, TextInput, FormField } from 'grommet';
import AddIcon from 'grommet/components/icons/base/Add';
import DeleteIcon from 'grommet/components/icons/base/Trash';
import { useMutation } from 'react-query';
import { addVideoCategory, editVideoCategory, delayFetchFunctions } from '../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../shared/utility';
import CustomToast from '../../../components/CustomToast/CustomToast';

const AddEditCategory = ({ category = null, onClose }) => {
  const [name, setName] = useState('');
  const [options, setOptions] = useState(['']);
  const [toast, setToast] = useState(null);
  const isEditMode = category !== null;
  const canSave = name.length && options.every((option) => option.length);
  const saveCategory = useMutation(
    () => {
      if (isEditMode) {
        return editVideoCategory(category.id, { name, options });
      }
      return addVideoCategory(name, options);
    },
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: isEditMode ? 'Successfully updated' : 'Successfully added',
          show: true
        });

        delayFetchFunctions(['fetchVideoCategories'], 1000);
        setTimeout(() => {
          onClose();
        }, 1000);
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  useEffect(() => {
    if (isEditMode) {
      setName(category.name);
      setOptions(category.options);
    }
  }, [isEditMode, category]);

  const addOption = () => setOptions([...options, '']);

  const deleteOption = (index) => {
    let arrOptions = [...options];
    arrOptions.splice(index, 1);
    setOptions(arrOptions);
  };

  const handleOptionChange = (value, index) => {
    const arrOptions = [...options];
    arrOptions[index] = value;
    setOptions(arrOptions);
  };

  const handleCategoryChange = ({ target }) => setName(target.value);

  return (
    <>
      <CustomToast toast={toast} />
      <Heading tag="h3">Category name:</Heading>
      <TextInput value={name} onDOMChange={handleCategoryChange} placeHolder="Enter category name" />

      <div className={classes.optionContainer}>
        <div className={classes.optionsHeader}>
          <Heading tag="h3">Options:</Heading>
          <AddIcon onClick={addOption} className={classes.icon} />
        </div>
        {options.map((option, index) => (
          <div className={classes.categoryOption} key={index}>
            <FormField className={classes.tagInput}>
              <TextInput
                value={option}
                onDOMChange={({ target }) => handleOptionChange(target.value, index)}
                placeHolder="Enter tag"
              />
            </FormField>
            <DeleteIcon onClick={() => deleteOption(index)} className={classes.icon} />
          </div>
        ))}
      </div>

      <div className={classes.btnContainer}>
        <Button label="Cancel" secondary={false} onClick={onClose} />
        {canSave ? <Button label="Save" secondary={true} onClick={saveCategory.mutate} /> : null}
      </div>
    </>
  );
};
export default AddEditCategory;
