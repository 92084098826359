import React, { useEffect } from 'react';
import videojs from 'video.js';
import qualityLevels from 'videojs-contrib-quality-levels';
import hlsQualitySelector from 'videojs-hls-quality-selector';
import classes from './VideoPlayer.module.scss';
import 'video.js/dist/video-js.css';
videojs.registerPlugin('qualityLevels', qualityLevels);
videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);

export const VideoPlayer = ({ sources, disableScrubbing = true, onVideoComplete = () => {} }) => {
  const videoRef = React.useRef(null);

  const VideoHtml = () => (
    <div data-vjs-player className={classes.videoGrommetReset}>
      <video data-testid="vp" ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    let player;
    if (videoElement) {
      player = videojs(videoElement, {
        html5: {
          vhs: {
            overrideNative: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          nativeTextTracks: false,
          hls: {
            overrideNative: true
          }
        },
        controls: true,
        responsive: true,
        fluid: true,
        preload: 'auto',
        controlBar: { pictureInPictureToggle: false },
        plugins: {
          qualityLevels: {},
          hlsQualitySelector: {}
        },
        sources
      });

      player.on('ended', onVideoComplete);
      if (disableScrubbing) {
        player.controlBar.progressControl.disable();
      }
    }
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [sources, disableScrubbing, onVideoComplete]);

  return (
    <div className={classes.videoGrommetReset}>
      <VideoHtml />
    </div>
  );
};

export default VideoPlayer;
