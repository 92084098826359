import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinning from 'grommet/components/icons/Spinning';

const TermsOfService = ({ termsUrl, termsLoadFailed }) => {
  const [terms, setTerms] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);

  useEffect(
    () => {
      setLoading(true);
      const getTerms = async () => {
        try {
          const termsResponse = await axios.get(termsUrl);
          setTerms(termsResponse.data.terms);
          setLoading(false);
          termsLoadFailed !== undefined && termsLoadFailed(false);
          setFailedToLoad(false);
        } catch (error) {
          setLoading(false);
          termsLoadFailed !== undefined && termsLoadFailed(true);
          setFailedToLoad(true);
          setTerms('We are experiencing some issues please try to create your account at a later date.');
        }
      };
      getTerms();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [termsUrl]
  );

  if (isLoading) {
    return <Spinning />;
  }
  if (failedToLoad) {
    return (
      <div className="container--center">
        <h3 style={{ marginTop: '10%', textAlign: 'center', lineHeight: '60px' }}>
          Sorry, we're facing some technical issues showing the terms and conditions. Please visit again soon or contact
          us to find out when the issue will be resolved.
        </h3>
      </div>
    );
  }

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: terms }}></div>
    </>
  );
};

export default TermsOfService;
