import React, { useState, useEffect } from 'react';
import axios from '../../axios-global';

import Button from 'grommet/components/Button';
import Notification from 'grommet/components/Notification';
import Toast from 'grommet/components/Toast';
import Spinning from 'grommet/components/icons/Spinning';

import FormCreatorSmall from '../UI/FormCreatorSmall/FormCreatorSmall';
import HeaderWithBorder from '../UI/HeaderWithBorder/HeaderWithBorder';

import { updateObject, checkValidity, returnErrorTextForField } from '../../shared/utility';
import { CUSTOMER_TYPE } from '../../constants';

const cloneDeep = require('lodash.clonedeep');

const UserSettings = (props) => {
  const [personalDetails, setPersonalDetails] = useState({
    name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Enter name',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    last_name: {
      label: 'Last name',
      type: 'text',
      placeholder: 'Enter last name',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    email: {
      label: 'E-mail',
      type: 'text',
      placeholder: 'Enter e-mail',
      value: '',
      validation: {
        required: true,
        isEmail: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    phone_number: {
      label: 'Phone number',
      type: 'text',
      placeholder: 'Enter telephone number',
      value: '',
      validation: {
        required: true,
        isUkTelephone: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    address_line1: {
      label: 'Address',
      type: 'text',
      placeholder: 'Enter address',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    city: {
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    postcode: {
      label: 'Postcode',
      type: 'text',
      placeholder: 'Enter postcode',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    gymId: {
      label: 'Home gym',
      type: 'select',
      placeholder: 'Select',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    customerType: {
      label: 'Account type',
      type: 'select',
      placeholder: 'Select',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorText: null
    }
  });

  const [emergencyContactForm, setEmergencyContactForm] = useState({
    emergency_contact_name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Enter emergency contact name',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorText: null
    },
    emergency_contact_number: {
      label: 'Phone',
      type: 'text',
      placeholder: 'Enter emergency contact phone',
      value: '',
      validation: {
        required: true,
        isNumeric: true
      },
      valid: false,
      touched: false,
      errorText: null
    }
  });

  const [formIsValid, setFormIsValid] = useState(true);
  const [notificationText, setNotificationText] = useState(null);
  const [newDataSaving, setNewDataSaving] = useState(false);
  const [toast, setToast] = useState(null);
  const [toastText, setToastText] = useState(null);
  const [gymChangeToast, setGymChangeToast] = useState(null);
  const [toastStatus, setToastStatus] = useState(null);

  useEffect(
    () => {
      let options = props.gymList.map((elem) => {
        return {
          label: elem.name,
          value: elem._id
        };
      });
      assignExistingValues(options);
    },
    // eslint-disable-next-line
    []
  );

  const assignExistingValues = (options) => {
    const formNames = Object.keys(props.clientData);
    const previousValues = Object.values(props.clientData);
    const copiedPersonalDetailsForm = cloneDeep(personalDetails);
    const copiedEmergencyContactFormForm = cloneDeep(emergencyContactForm);

    formNames.forEach((element, index) => {
      if (element === 'gymId' || element === 'customerType') {
        let optionsToSet = element === 'gymId' ? options : CUSTOMER_TYPE;
        copiedPersonalDetailsForm[element] = {
          options: optionsToSet,
          ...copiedPersonalDetailsForm[element],
          value: optionsToSet.find((elem) => elem.value === previousValues[index]),
          valid: true,
          touched: true
        };
      } else {
        if (copiedPersonalDetailsForm.hasOwnProperty(element) && copiedPersonalDetailsForm[element] !== undefined) {
          copiedPersonalDetailsForm[element] = {
            ...copiedPersonalDetailsForm[element],
            value: previousValues[index],
            valid: true,
            touched: true
          };
        }

        if (
          copiedEmergencyContactFormForm.hasOwnProperty(element) &&
          copiedEmergencyContactFormForm[element] !== undefined
        ) {
          copiedEmergencyContactFormForm[element] = {
            ...copiedEmergencyContactFormForm[element],
            value: previousValues[index],
            valid: true,
            touched: true
          };
        }
      }
    });

    setPersonalDetails(copiedPersonalDetailsForm);
    setEmergencyContactForm(copiedEmergencyContactFormForm);
  };

  const personalDetailsValueChangedHandler = (newValue, identifier) => {
    if (identifier === 'gymId' && props.clientData.currentMembershipPlan && newValue.value !== props.clientData.gymId) {
      setGymChangeToast(true);
    }

    const updatedPersonalDetails = updateObject(personalDetails, {
      [identifier]: updateObject(personalDetails[identifier], {
        value: newValue,
        valid: checkValidity(newValue, personalDetails[identifier].validation),
        errorText: returnErrorTextForField(personalDetails[identifier].validation),
        touched: true
      })
    });

    return setPersonalDetails(updatedPersonalDetails);
  };

  const emergencyContactValueChangedHandler = (newValue, identifier) => {
    const updatedEmergencyContactForm = updateObject(emergencyContactForm, {
      [identifier]: updateObject(emergencyContactForm[identifier], {
        value: newValue,
        valid: checkValidity(newValue, emergencyContactForm[identifier].validation),
        errorText: returnErrorTextForField(emergencyContactForm[identifier].validation),
        touched: true
      })
    });

    return setEmergencyContactForm(updatedEmergencyContactForm);
  };

  const checkFormValidity = () => {
    setFormIsValid(true);
    setNotificationText(null);
    setNewDataSaving(true);

    for (let input in personalDetails) {
      if (!personalDetails[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        const updatedPersonalDetails = updateObject(personalDetails, {
          [input]: updateObject(personalDetails[input], {
            valid: checkValidity(personalDetails[input].value, personalDetails[input].validation),
            errorText: returnErrorTextForField(personalDetails[input].validation),
            touched: true
          })
        });

        setPersonalDetails(updatedPersonalDetails);
        setFormIsValid(false);
        setNotificationText('Form is not filled in correctly.');
        setNewDataSaving(false);
        return;
      }
    }

    for (let input in emergencyContactForm) {
      if (!emergencyContactForm[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        const updatedEmergencyContactForm = updateObject(emergencyContactForm, {
          [input]: updateObject(emergencyContactForm[input], {
            valid: checkValidity(emergencyContactForm[input].value, emergencyContactForm[input].validation),
            errorText: returnErrorTextForField(emergencyContactForm[input].validation),
            touched: true
          })
        });
        setEmergencyContactForm(updatedEmergencyContactForm);
        setFormIsValid(false);
        setNotificationText('Form is not filled in correctly.');
        setNewDataSaving(false);

        return;
      }
    }

    setFormIsValid(true);
    setNotificationText(null);
    saveAccountDetails();
    return;
  };

  const saveAccountDetails = () => {
    const newData = {};
    for (let input in personalDetails) {
      newData[input] = personalDetails[input].value;
      if (input === 'gymId') {
        newData.gymId = personalDetails['gymId'].value.value;
      }
      if (input === 'customerType') {
        newData.customerType = personalDetails['customerType'].value.value;
      }
    }
    for (let input in emergencyContactForm) {
      newData[input] = emergencyContactForm[input].value;
    }
    newData.marketing = null;

    axios
      .patch(`/users/${props.clientData._id}`, newData)
      .then(() => {
        setNewDataSaving(false);
        setToast(true);
        setToastText('Profile updated');
        setToastStatus('ok');
        props.onFetchClientDetails();
        props.onFetchUsersFullDataForGym();
      })
      .catch((error) => passErrorMessagesToForm(error));
  };

  const passErrorMessagesToForm = (error) => {
    if (error.response && error.response.status === 500) {
      setFormIsValid(false);
      setNewDataSaving(false);
      setNotificationText('Server error.');
      return;
    }

    const copiedPersonalDetailsForm = cloneDeep(personalDetails);
    const copiedEmergencyContactFormForm = cloneDeep(emergencyContactForm);

    error.response.data.errors.forEach((element) => {
      if (personalDetails.hasOwnProperty(element.field) && personalDetails[element.field] !== undefined) {
        copiedPersonalDetailsForm[element.field] = {
          ...copiedPersonalDetailsForm[element.field],
          valid: false,
          errorText: element.message,
          touched: true
        };
      }

      if (emergencyContactForm.hasOwnProperty(element.field) && emergencyContactForm[element.field] !== undefined) {
        copiedEmergencyContactFormForm[element.field] = {
          ...copiedEmergencyContactFormForm[element.field],
          valid: false,
          errorText: element.message,
          touched: true
        };
      }
    });

    setPersonalDetails(copiedPersonalDetailsForm);
    setEmergencyContactForm(copiedEmergencyContactFormForm);
  };

  const returnNotification = () => {
    if (!formIsValid) {
      return <Notification message={notificationText} status="warning" className="ss-top-notification" />;
    }
  };

  const closeToast = () => {
    setToast(null);
    setToastText(null);
    setGymChangeToast(null);
    setToastStatus('ok');
  };

  const returnToast = () => {
    if (toast) {
      return (
        <Toast status={toastStatus} onClose={closeToast}>
          {toastText}
        </Toast>
      );
    }

    if (gymChangeToast) {
      return (
        <Toast status="warning" onClose={closeToast}>
          Please ensure you have checked any remaining entitlement for this person and updated as necessary, before
          confirming the change.
        </Toast>
      );
    }

    return null;
  };

  return (
    <div>
      {returnNotification()}
      {returnToast()}
      <HeaderWithBorder header={'Basic Data'} />
      <FormCreatorSmall formData={personalDetails} valueChanged={personalDetailsValueChangedHandler} />

      <HeaderWithBorder header={'Emergency contact'} />
      <FormCreatorSmall formData={emergencyContactForm} valueChanged={emergencyContactValueChangedHandler} />

      {newDataSaving ? (
        <Spinning />
      ) : (
        <Button
          style={{
            margin: '20px 0',
            display: 'block',
            width: '100%',
            maxWidth: '100%'
          }}
          label="Save settings"
          secondary={true}
          onClick={checkFormValidity}
        />
      )}
    </div>
  );
};

export default UserSettings;
