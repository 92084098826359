import React from 'react';
import Button from 'grommet/components/Button';
import Modal from 'react-modal';
import classes from './CalendarModal.module.scss';

const CalendarModal = ({ children, isOpen, onRequestClose }) => (
  <Modal className="ss-modal" isOpen={isOpen} onRequestClose={onRequestClose}>
    <h2 className={classes.header}>Add to Calendar</h2>
    <div className={classes.content}>{children}</div>
    <Button onClick={onRequestClose} secondary className={classes.btn}>
      Close
    </Button>
  </Modal>
);
export default CalendarModal;
