import React, { useState, useEffect } from 'react';
import withAdminDashboard from '../../store/hoc/withAdminDashboard';
import { useMutation, useQuery } from 'react-query';
import {
  addClass,
  createClassAndSignUpAllUsersToClass,
  delayFetchFunctions,
  editClass,
  fetchUsersList
} from '../../apiFunctions/apiFunctions';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';
import { returnFormFields } from './returnFormFields';
import { Button } from 'grommet';
import { checkValidity, returnErrorFromResponse, returnErrorTextForField, updateObject } from '../../shared/utility';
import FormCreator from '../../components/UI/FormCreator/FormCreator';
import { WaitListForm } from '../WaitList/WaitListForm/WaitListForm';
import moment from 'moment';
import classes from './AddEditSingleClass.module.scss';
import PTClientSelector from '../../components/Selectors/ClientSelector/PTClientSelector';
import TrainerSelector from '../../components/Selectors/TrainerSelector/TrainerSelector';
import TemplateSelector from '../../components/Selectors/TemplateSelector/TemplateSelector';
import { DEFAULT_WAIT_LIST_CONFIG } from '../../constants';
import CustomToast from '../../components/CustomToast/CustomToast';

const AddEditSingleClass = ({
  classType,
  selectedGym,
  selectedClass = null,
  classConfig,
  onClose = () => {},
  gymSelector = null
}) => {
  const [gym, setGym] = useState(selectedGym);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateSelectorStatus, setTemplateSelectorStatus] = useState('ok');
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [trainerSelectorStatus, setTrainerSelectorStatus] = useState('ok');
  const [userSelectorData, setUserSelectorData] = useState(null);
  const [userSelectorStatus, setUserSelectorStatus] = useState('ok');

  const [waitListConfig, setWaitListConfig] = useState(
    selectedClass && selectedClass.waitListConfig ? selectedClass.waitListConfig : DEFAULT_WAIT_LIST_CONFIG
  );
  const [isWaitListValid, setIsWaitListValid] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState([]);
  const [formFields, setFormFields] = useState(returnFormFields(classType, selectedClass, gym));
  const [toast, setToast] = useState(null);

  const showTemplates = classType !== 'personalTraining';
  const showTrainerSelector = classConfig.trainerRequired;
  const showUserSelector = classType === 'personalTraining';
  const isNew = selectedClass === null;

  useEffect(
    () => {
      setFormFields(returnFormFields(classType, selectedTemplate, gym, isNew));
      setWaitListConfig(
        selectedClass && selectedClass.waitListConfig ? selectedClass.waitListConfig : DEFAULT_WAIT_LIST_CONFIG
      );
      setIsWaitListValid(true);
    }, // eslint-disable-next-line
    [selectedTemplate]
  );

  useEffect(
    () => {
      if (selectedClass) {
        setFormFields(returnFormFields(classType, selectedClass, gym, isNew));
        setSelectedTrainer(selectedClass.trainer);
        setWaitListConfig(selectedClass.waitListConfig);
      }
    }, // eslint-disable-next-line
    [selectedClass]
  );

  useEffect(() => {
    if (gymSelector) {
      setGym(gymSelector);
    }
  }, [gymSelector]);

  const {
    data: users,
    isLoading: usersLoading,
    error: usersError
  } = useQuery(['usersList'], () => fetchUsersList(gym._id), {
    enabled: showUserSelector
  });

  const mutateSave = useMutation(
    (formData) => (isNew ? addClass(gym._id, classType, formData) : editClass(selectedClass._id, formData)),
    {
      onSuccess: () => {
        isNew ? delayFetchFunctions([['upcomingClasses', classType]], 0) : delayFetchFunctions([['singleClass']], 0);
        onClose();
        setToast({
          status: 'ok',
          msg: isNew ? 'Successfully created' : 'Successfully updated',
          show: true
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  const mutateSaveAndSignUp = useMutation(
    (formData) => createClassAndSignUpAllUsersToClass(gym._id, classType, formData, userSelectorData.users),
    {
      onSuccess: () => {
        delayFetchFunctions([['upcomingClasses', classType]], 0);
        setToast({
          status: 'ok',
          msg: 'Successfully created',
          show: true
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        onClose();
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  if (usersLoading) {
    return <Spinning />;
  }
  if (usersError) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  const valueChangedHandler = (newValue, identifier) => {
    const updated = updateObject(formFields, {
      [identifier]: updateObject(formFields[identifier], {
        value: newValue,
        valid: checkValidity(newValue, formFields[identifier].validation),
        errorText: returnErrorTextForField(formFields[identifier].validation),
        touched: true
      })
    });
    setFormFields(updated);
  };

  const save = () => {
    const formData = {};
    for (let input in formFields) {
      formData[input] =
        typeof formFields[input].value === 'string' ? formFields[input].value.trim() : formFields[input].value;
    }

    formData.classDate = moment(formData.dateTime, 'DD/MM/YYYY').startOf('d').format('YYYY-MM-DD');
    formData.classTime = moment(formData.dateTime, 'DD/MM/YYYY hh:mm A').format('hh:mm A');
    delete formData.dateTime;

    if (showTrainerSelector) {
      formData.trainerId = selectedTrainer._id;
    }
    if (isNew && !showUserSelector) {
      formData.currency = formData.currency.value;
      formData.gymId = gym._id;
    }
    if (isNew && showTemplates) {
      formData.classTemplateId = selectedTemplate._id;
      formData.stripeProductId = selectedTemplate.stripeProductId;
    }

    formData.waitListConfig = waitListConfig;

    if (isNew && showUserSelector) {
      formData.duration = userSelectorData.pass.duration;
      formData.limit = userSelectorData.pass.limit;
      formData.places = userSelectorData.pass.places;
      formData.cost = userSelectorData.pass.cost;

      mutateSaveAndSignUp.mutate(formData);
    } else {
      mutateSave.mutate(formData);
    }
  };

  const checkFormValidity = () => {
    setFormValidated(true);
    let errors = [];

    if (showTemplates && selectedTemplate === null && isNew) {
      errors.push('template');
    }
    if (showTrainerSelector && selectedTrainer == null && isNew) {
      errors.push('trainer');
    }

    if (
      showUserSelector &&
      userSelectorData !== null &&
      userSelectorData.users.length !== userSelectorData.pass.places
    ) {
      errors.push('users');
    }

    if (errors.length > 0 || !isWaitListValid) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      setFormValidationErrors(errors);
      setFormIsValid(false);
      return false;
    } else {
      setFormValidationErrors([]);
    }

    for (let input in formFields) {
      if (!formFields[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return setFormIsValid(false);
      }
    }
    setFormIsValid(true);
    save();
  };

  let formWarning = !formIsValid && formValidated && (
    <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
  );

  const button =
    mutateSave.isLoading || mutateSaveAndSignUp.isLoading ? (
      <Spinning style={{ marginTop: '50px', display: 'block' }} />
    ) : (
      <Button
        data-cy="addOrSaveBtn"
        style={{ marginTop: '50px', display: 'block' }}
        label={selectedClass ? 'Save' : 'Add'}
        secondary={true}
        onClick={checkFormValidity}
      />
    );

  const checkWaitList = (valid, waitListConfig) => {
    setWaitListConfig(waitListConfig);
    setIsWaitListValid(valid);
  };

  const returnForm = () => {
    const renderTemplatesSelection = isNew && classConfig.template && (
      <TemplateSelector
        classType={classType}
        gymId={gym._id}
        selectedTemplate={selectedTemplate}
        onSelectTemplate={setSelectedTemplate}
        isValid={formValidationErrors.indexOf('template') === -1}
        fetchData={classConfig.template}
        setStatus={setTemplateSelectorStatus}
      />
    );
    const renderTrainerSelection = classConfig.trainerRequired && (
      <TrainerSelector
        selectedTrainer={selectedTrainer}
        isValid={formValidationErrors.indexOf('trainer') === -1}
        onSelectTrainer={setSelectedTrainer}
        setStatus={setTrainerSelectorStatus}
        gymId={gym._id}
      />
    );

    const userSelector = showUserSelector && (
      <PTClientSelector
        users={users.filter((elem) => elem.status === 'active' && elem.PTclassesLeft.length > 0)}
        setSelectedData={setUserSelectorData}
        isValid={formValidationErrors.indexOf('users') === -1}
        setStatus={setUserSelectorStatus}
        gymId={gym._id}
      />
    );

    return isNew && classConfig.template && templateSelectorStatus !== 'ok' ? (
      renderTemplatesSelection
    ) : classConfig.trainerRequired && trainerSelectorStatus !== 'ok' ? (
      renderTrainerSelection
    ) : showUserSelector && userSelectorStatus !== 'ok' ? (
      userSelector
    ) : (
      <>
        {renderTemplatesSelection}
        {renderTrainerSelection}
        {userSelector}
        {formWarning}
        <FormCreator formData={formFields} valueChanged={valueChangedHandler} />
        <WaitListForm waitListConfig={waitListConfig} checkWaitList={checkWaitList} />
        {button}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <CustomToast toast={toast} />
      {returnForm()}
    </div>
  );
};

export default withAdminDashboard(AddEditSingleClass);
