import React from 'react';

import NavigationLink from '../../../components/Navigation/NavigationLink/NavigationLink';

import whiteLogo from '../../logo-icons/brand-logo-for-dark-background.png';
import classes from '../../components/Footer/Footer.module.scss';

export default () => (
  <div className={classes.footer}>
    <div className="container">
      <div className={classes.footerContainer}>
        <img src={whiteLogo} alt="logo" className={classes.footerLogo} />

        <nav className={classes.mobileMenu}>
          <NavigationLink link="/contact" light>
            Contact
          </NavigationLink>
          <NavigationLink link="/privacy-policy" light>
            Privacy Policy
          </NavigationLink>
          <NavigationLink link="/terms-of-website" light>
            Terms of Website
          </NavigationLink>
          <NavigationLink link="/terms-of-service" light>
            Membership Terms and Conditions
          </NavigationLink>
        </nav>
      </div>
    </div>
  </div>
);
