import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, TableHeader, TableRow } from 'grommet';
import Notification from 'grommet/components/Notification';
import withUser from '../../store/hoc/withUser';
import { countClassAttendees } from '../../shared/classFunctions';
import Clipboard from 'grommet/components/icons/base/Clipboard';
import { isFullAccessAdminOrTrainer } from '../../shared/userFunctions';
import { useQuery } from 'react-query';
import { fetchClassesHistory } from '../../apiFunctions/apiFunctions';
import { returnClassConfig } from '../../shared/gymFunctions';
import Loading from '../../components/Loading/Loading';
import { getCurrencySymbol } from '../../shared/utility';

const SingleClassTypeHistory = ({ history, userData, classType, selectedGym, showAllGymsData = false }) => {
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);
  const showClassName = classType === 'class';
  const showTrainerName = classConfig && classConfig.trainerRequired;
  const showLocation = isFullAccessAdminOrTrainer(userData) && classConfig && classConfig.showLocation;
  const showAttendeesNames = classConfig && classConfig.showAttendeesNames;

  const {
    data: classesHistory,
    error: classesHistoryError,
    isLoading: classesHistoryLoading
  } = useQuery(['classesHistory', classType], () =>
    fetchClassesHistory(classType, showAllGymsData ? 'all' : selectedGym._id)
  );

  if (classesHistoryLoading) {
    return <Loading size={'medium'} />;
  }

  if (classesHistoryError) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  if (classesHistory && classesHistory.length > 0) {
    const returnAttendees = (ssClass) => {
      const returnUsersName = (attendanceList) =>
        attendanceList.map((attendance) => (
          <div key={attendance._id}>
            {attendance.user.name} {attendance.user.last_name}
            <br />
          </div>
        ));

      return showAttendeesNames
        ? returnUsersName(ssClass.attendanceList)
        : `${countClassAttendees(ssClass.attendanceList)} / ${ssClass.limit}`;
    };

    const rows = classesHistory.map((element) => {
      const redirectToClassPage = () => history.push(`/gym/${element.gymId._id}/class/${element._id}`);
      return (
        <TableRow key={element._id} onClick={redirectToClassPage}>
          {showLocation && <td>{element.gymId.name}</td>}
          {showClassName && <td>{element.name}</td>}
          <td>{moment(element.classDate, 'YYYY-MM-DD').format('DD/MM')}</td>
          <td>{element.classTime.toUpperCase()}</td>
          <td>{element.duration}</td>
          <td>{`${getCurrencySymbol(element.currency)} ${element.cost}`}</td>
          <td>
            {element.membershipClassPrice
              ? `${getCurrencySymbol(element.currency)} ${element.membershipClassPrice}`
              : '-'}
          </td>
          {showTrainerName && (
            <td>
              {element.trainer.name} {element.trainer.last_name}
            </td>
          )}
          <td>{returnAttendees(element)}</td>
          <td>
            <Clipboard
              size={'small'}
              type={'status'}
              style={{ margin: '0 5px', cursor: 'pointer' }}
              onClick={redirectToClassPage}
            />
          </td>
        </TableRow>
      );
    });

    let tableHeader = showClassName
      ? ['Name', 'Date', 'Time', 'Duration', 'Cost', 'Membership Class Price', 'Attendees', '']
      : ['Date', 'Time', 'Duration', 'Cost', 'Membership Class Price', 'Attendees', ''];

    if (showTrainerName) {
      const indexOfAttendees = tableHeader.indexOf('Attendees');
      tableHeader.splice(indexOfAttendees, 0, 'Trainer');
    }

    if (showLocation) {
      tableHeader.unshift('Location');
    }

    return (
      <div>
        <Table selectable={true} responsive={false}>
          <TableHeader style={{ color: 'black' }} labels={tableHeader} />
          <tbody>{rows}</tbody>
        </Table>
      </div>
    );
  }

  return <h3>No history</h3>;
};

export default withRouter(withUser(SingleClassTypeHistory));
