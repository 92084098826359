import React, { useState } from 'react';
import Button from 'grommet/components/Button';
import Modal from 'react-modal';
import ManageVideos from '../ManageVideos';
import classes from './VideoPicker.module.scss';

const VideoPicker = ({ onPick, selection = [] }) => {
  const [open, setOpen] = useState(false);

  const onChange = (video) => {
    let update;
    if (selection.includes(video._id)) {
      update = selection.filter((videoId) => videoId !== video._id);
    } else {
      update = [...selection, video._id];
    }
    onPick(update);
  };

  const onReorder = (reorderedVideos) => {
    const newOrder = reorderedVideos.map((video) => video?._id);
    onPick(newOrder);
  };
  return (
    <>
      <Modal
        appElement={document.getElementById('root')}
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        className={`${classes.modal} ss-modal`}>
        <ManageVideos
          picker
          pickerSelection={selection}
          onReorder={onReorder}
          onPick={onChange}
          onClosePicker={() => setOpen(false)}
        />
      </Modal>
      <div className={classes.container}>
        <Button label="Choose Videos" onClick={() => setOpen(true)} />
      </div>
    </>
  );
};

export default VideoPicker;
