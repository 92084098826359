import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import {
  delayFetchFunctions,
  orderAndPayForPass,
  getDiscountedPriceForCoupon
} from '../../../apiFunctions/apiFunctions';
import Icons from '../../../components/UI/Icons/Icons';
import { HashLink as Link } from 'react-router-hash-link';
import Button from 'grommet/components/Button';
import withUser from '../../../store/hoc/withUser';
import { getCurrencyDetail, returnErrorFromResponse, getCurrencySymbol } from '../../../shared/utility';
import Modal from 'react-modal';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Loading from '../../../components/Loading/Loading';
import GoogleApplePayModal from '../../ActionButtons/GoogleApplePayButton/GoogleApplePayModal';
import TextInput from 'grommet/components/TextInput';
import classes from './RenderPassActionButtons.module.scss';
import CircleInformation from 'grommet/components/icons/base/CircleInformation';
const RenderPassActionButtons = ({
  sourcesList,
  isAdmin,
  isUser,
  classPass,
  editPass,
  goBackToPreviousPage,
  buttonStyle = {},
  classType
}) => {
  const [toast, setToast] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const currency = getCurrencyDetail(classPass.currency).value;
  const [promoCode, setPromoCode] = useState('');
  const [discountMessage, setDiscountMessage] = useState(null);
  const mutateOrderAndPay = useMutation(() => orderAndPayForPass(classPass._id, 'web', currency, promoCode), {
    onSuccess: () => {
      setShowModal(false);
      setToast({
        status: 'ok',
        msg: 'Successfully paid',
        show: true
      });
      delayFetchFunctions([['userData'], ['passes', classType], ['videoPasses']]);
      goBackToPreviousPage();
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  const getDiscountedPriceForCouponCall = async () => {
    setIsLoading(true);
    try {
      const response = await getDiscountedPriceForCoupon(classPass._id, promoCode);
      let message = response.message;
      let lastIndex = message.lastIndexOf(' ');
      let priceWithCurrency = getCurrencySymbol(response.currency) + message.substring(lastIndex, message.length);
      message = message.substring(0, lastIndex);
      let discountMsg = null;
      if (response.discountedCost !== 0) {
        discountMsg = `${message} ${priceWithCurrency}, you have to pay only ${getCurrencySymbol(response.currency)}${
          response.discountedCost
        } for the pass of worth ${getCurrencySymbol(response.currency)} ${response.actualCost}`;
      } else if (response.discountedCost <= 0) {
        discountMsg = `${message} ${priceWithCurrency},  New cost is ${getCurrencySymbol(response.currency)} 0`;
      }
      setDiscountMessage(discountMsg);
      setIsLoading(false);
    } catch (error) {
      setDiscountMessage(error.response.data.errors[0].msg);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDiscountMessage(null);
  }, [promoCode]);

  if (isAdmin) {
    return <Icons edit editClick={() => editPass(classPass)} />;
  }

  if (isUser) {
    if (sourcesList && sourcesList.length === 0 && classPass.cost > 0) {
      return <GoogleApplePayModal product={classPass} productType={'pass'} classType={classType} />;
    }

    const validateCoupon = () => {
      if (classPass._id !== undefined && classPass._id !== null && promoCode.trim().length !== 0) {
        getDiscountedPriceForCouponCall();
      }
    };

    const renderModal = (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={showModal}
        className="ss-modal"
        onRequestClose={() => setShowModal(false)}>
        {isLoading ? (
          <Loading topOffset={10} />
        ) : (
          <div>
            <div className={classes.info}>
              {discountMessage !== null && <CircleInformation />}
              <div>{discountMessage}</div>
            </div>
            <div className={classes.promoCodeDiv}>
              Please enter a coupon code if you have any
              <TextInput
                placeHolder="Enter coupon code"
                value={promoCode}
                className={classes.promoCode}
                onDOMChange={(e) => setPromoCode(e.target.value)}
              />
              <Button box label="Apply" className={classes.apply} secondary={true} onClick={validateCoupon} />
            </div>
          </div>
        )}
        <div className="grommet">
          Do you want to pay for the selected product? <br />
          This product will be paid for using your default card payment. You can change it{' '}
          <Link to="/gym/payment-methods">here</Link> if you prefer.
          <div className="ss-modal__buttons-container">
            {mutateOrderAndPay.isLoading ? (
              <Loading topOffset={10} />
            ) : (
              <>
                <Button box label="Yes" secondary={true} onClick={mutateOrderAndPay.mutate} />
                <Button box label="Cancel" primary onClick={() => setShowModal(false)} />
              </>
            )}
          </div>
        </div>
      </Modal>
    );

    const openModal = () => {
      setShowModal(true);
      setDiscountMessage(null);
      setPromoCode('');
    };

    return (
      <>
        {renderModal}
        <CustomToast toast={toast} />
        <Button
          style={{ width: '100%', height: '50px', margin: 'auto 0px', ...buttonStyle }}
          data-cy="payBtn"
          label="Pay"
          secondary={true}
          onClick={openModal}
        />
      </>
    );
  }

  return null;
};

export default withUser(RenderPassActionButtons);
