import React, { useState } from 'react';
import classes from './BulkEditing.module.scss';
import { DateTime, Select } from 'grommet';
import withAdminDashboard from '../../store/hoc/withAdminDashboard';
import BulkModal from './BulkModal/BulkModal';
import Notification from 'grommet/components/Notification';
import moment from 'moment';
import Button from 'grommet/components/Button';
import { useQuery } from 'react-query';
import {
  fetchAllScheduledClasses,
  fetchClassesPerScheduleId,
  fetchUpcomingClasses
} from '../../apiFunctions/apiFunctions';
import { returnAllUpcomingClasses } from '../../shared/classFunctions';
import Spinning from 'grommet/components/icons/Spinning';
import { typeSelectOptions } from './constants';

const BulkEditing = ({ selectedGym, classesDetailsInSelectedGym }) => {
  const [selectedOption, setSelectedOption] = useState({
    value: null,
    label: null
  });
  const [selectedSchedule, setSelectedSchedule] = useState({
    value: null,
    label: null
  });
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const {
    data: scheduledClassesList,
    error: scheduledClassesListError,
    isLoading: scheduledClassesListLoading
  } = useQuery(['allScheduledClassesList'], () => fetchAllScheduledClasses(selectedGym._id), {
    enabled: selectedOption.value === 'schedule'
  });

  const {
    data: bulkData,
    error,
    isLoading
  } = useQuery(
    ['bulkData'],
    () =>
      selectedOption.value === 'schedule' && selectedSchedule && selectedSchedule.value !== null
        ? fetchClassesPerScheduleId(selectedSchedule.value._id)
        : fetchUpcomingClasses(selectedOption.value, selectedGym._id, dateFrom, dateTo),
    {
      enabled: openModal
    }
  );

  const renderScheduleSelection = () => {
    if (selectedOption && selectedOption.value === 'schedule') {
      if (scheduledClassesListLoading) {
        return <Spinning size="large" />;
      }

      if (scheduledClassesListError) {
        return <Notification message="Something went wrong, please try again" status="critical" />;
      }

      const options =
        scheduledClassesList && scheduledClassesList.length > 0
          ? scheduledClassesList.map((elem) => {
              return { value: elem, label: elem.name === 'n/a' ? elem.classType : elem.name };
            })
          : [];

      return (
        <div>
          <h5>Please select schedule name</h5>
          <Select
            className={classes.selector}
            options={options}
            placeHolder={scheduledClassesList.length === 0 ? 'No schedules' : null}
            value={selectedSchedule}
            onChange={(e) => {
              setSelectedSchedule(e.option);
              setOpenModal(true);
            }}
          />
        </div>
      );
    }
    return null;
  };

  const renderDatePicker = () => {
    if (selectedOption && selectedOption.value !== 'schedule') {
      const selectDate = (date, type) => {
        setDateError(null);
        if (type === 'start') {
          if (moment(date, 'DD-MM-YYYY').isSameOrAfter(moment().startOf('day'))) {
            setDateFrom(date);
          } else {
            setDateFrom(null);
            setDateError('Date cannot be in the past');
          }
        } else {
          if (
            moment(date, 'DD-MM-YYYY').isSameOrAfter(moment().startOf('day')) &&
            dateFrom &&
            moment(date, 'DD-MM-YYYY').isSameOrAfter(moment(dateFrom, 'DD-MM-YYYY'))
          ) {
            setDateTo(date);
          } else {
            setDateTo(null);
            setDateError('Date cannot be in the past and before start date');
          }
        }
      };

      return (
        <div>
          <h5>Date from</h5>
          <DateTime
            id="datePickerFrom"
            onChange={(date) => selectDate(date, 'start')}
            value={dateFrom}
            format="DD-MM-YYYY"
          />
          <h5>Date to</h5>
          <DateTime id="datePickerTo" onChange={(date) => selectDate(date, 'end')} value={dateTo} format="DD-MM-YYYY" />
        </div>
      );
    }
    return null;
  };

  const typeSelector = () => (
    <>
      <h5>Please select type</h5>
      <Select
        className={classes.selector}
        options={typeSelectOptions(classesDetailsInSelectedGym)}
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.option)}
      />
    </>
  );

  return (
    <div className={classes.root}>
      {dateError && <Notification message={dateError} status="warning" />}
      {typeSelector()}
      {renderScheduleSelection()}
      {renderDatePicker()}
      <Button primary className={classes.button} label="Show data" onClick={() => setOpenModal(true)} />
      <BulkModal
        isLoading={isLoading}
        error={error}
        data={selectedOption.value === 'schedule' ? bulkData : returnAllUpcomingClasses(bulkData)}
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
        classType={selectedOption.value}
        scheduleId={selectedSchedule && selectedSchedule.value !== null ? selectedSchedule.value._id : ''}
      />
    </div>
  );
};

export default withAdminDashboard(BulkEditing);
