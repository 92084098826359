import React from 'react';
import PropTypes from 'prop-types';
import Label from 'grommet/components/Label';
import Button from 'grommet/components/Button';

import classes from './Upload.module.scss';

function Upload({ selectedVideo = null, onSelectVideo }) {
  return (
    <div className={classes.fileSelect}>
      {!selectedVideo && (
        <>
          <Label id="choose-file" style={{ margin: 0 }}>
            Choose file to upload:
          </Label>
          <input
            style={{ padding: '1rem 0' }}
            type="file"
            name="video"
            accept="video/*"
            aria-labelledby="choose-file"
            onChange={({ target }) => {
              onSelectVideo(target.files[0]);
            }}
          />
        </>
      )}
      {selectedVideo && (
        <>
          <Label style={{ margin: 0 }}>Selected:</Label>
          <b>{`Name: ${selectedVideo.name}`}</b>
          {selectedVideo.size ? <b>{`Size: ${Number(selectedVideo.size / 1024 / 1024).toFixed(2)}MB`}</b> : null}
          <b>{`Type: ${selectedVideo.type}`}</b>
          <div className={classes.videoButtons}>
            <Button label="Remove" secondary={true} onClick={() => onSelectVideo(null)} />
          </div>
        </>
      )}
    </div>
  );
}

Upload.propTypes = {
  selectedVideo: PropTypes.object,
  onSelectVideo: PropTypes.func
};

export default Upload;
