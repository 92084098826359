import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../../axios-global';

import Button from 'grommet/components/Button';
import Notification from 'grommet/components/Notification';
import Spinning from 'grommet/components/icons/Spinning';
import CheckBox from 'grommet/components/CheckBox';
import classes from './AddEditMembershipPlan.module.scss';
import FormCreator from '../../../../components/UI/FormCreator/FormCreator';
import { ALL_CURRENCIES } from '../../../../constants';
import { updateObject, checkValidity, returnErrorTextForField, getCurrencyDetail } from '../../../../shared/utility';

import * as actions from '../../../../store/actions';

const cloneDeep = require('lodash.clonedeep');

const mapStateToProps = (state) => {
  return {
    selectedGym: state.admin.selectedGym
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchPlansList: (gymId) => dispatch(actions.fetchPlansList(gymId))
  };
};

class AddEditMembershipPlan extends Component {
  state = {
    formIsValid: false,
    formValidated: false,
    loading: false,
    error: null,
    newPlan: {
      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Plan name',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      description: {
        label: 'Description',
        type: 'text',
        placeholder: 'Plan description',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      currency: {
        label: 'Currency',
        type: 'select',
        options: ALL_CURRENCIES,
        placeholder: 'Select',
        value: getCurrencyDetail(this.props.selectedGym.settings.currency),
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      price: {
        label: 'Price',
        type: 'text',
        placeholder: 'Plan price',
        value: '',
        validation: {
          required: true,
          isNumericDecimal: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      minContractLength: {
        label: 'Minimum contract length (in months)',
        type: 'text',
        placeholder: 'Plan min. duration',
        value: '',
        validation: {
          required: true,
          isNumeric: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      classPrice: {
        label: 'Class price',
        type: 'text',
        placeholder: 'Class price',
        value: '',
        validation: {
          required: true,
          isNumericDecimal: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      joiningFee: {
        label: 'Joining fee',
        type: 'optional',
        placeholder: 'Joining fee',
        value: '',
        validation: {
          required: true,
          isNumericDecimal: true
        },
        valid: true,
        touched: false,
        checkbox: {
          label: 'Add joining fee',
          enabled: false
        },
        errorText: null
      },
      displayOrder: {
        label: 'Display order',
        type: 'text',
        placeholder: 'Display order',
        value: '',
        validation: {
          required: true,
          isNumericWithLimit: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      classesIncluded: {
        label: 'Classes included',
        type: 'number',
        placeholder: 'Classes included',
        value: '',
        validation: {
          required: false,
          isNumeric: true
        },
        valid: true,
        touched: false,
        errorText: null
      }
    },
    unlimitedClasses: false,
    active: true
  };

  componentDidMount() {
    if (this.props.editedPlan) {
      const joiningFeeCheckboxEnabled = this.props.editedPlan.joiningFee ? true : false;

      const newPlan = {
        name: {
          label: 'Name',
          type: 'text',
          placeholder: 'Plan name',
          value: this.props.editedPlan.name,
          validation: {
            required: true
          },
          valid: true,
          touched: false,
          errorText: null
        },
        description: {
          label: 'Description',
          type: 'text',
          placeholder: 'Plan description',
          value: this.props.editedPlan.description,
          validation: {
            required: true
          },
          valid: true,
          touched: false,
          errorText: null
        },
        minContractLength: {
          label: 'Minimum contract length (in months)',
          type: 'text',
          placeholder: 'Plan min. duration',
          value: this.props.editedPlan.minContractLength,
          validation: {
            required: true,
            isNumeric: true
          },
          valid: true,
          touched: false,
          errorText: null
        },
        classPrice: {
          label: 'Class price',
          type: 'text',
          placeholder: 'Class price',
          value: this.props.editedPlan.classPrice,
          validation: {
            required: true,
            isNumericDecimal: true
          },
          valid: true,
          touched: false,
          errorText: null
        },
        joiningFee: {
          label: 'Joining fee',
          type: 'optional',
          placeholder: 'Joining fee',
          value: this.props.editedPlan.joiningFee,
          validation: {
            required: false,
            isNumericDecimal: true
          },
          valid: true,
          touched: false,
          checkbox: {
            label: 'Add joining fee',
            enabled: joiningFeeCheckboxEnabled
          },
          errorText: null
        },
        displayOrder: {
          label: 'Display order',
          type: 'text',
          placeholder: 'Display order',
          value: this.props.editedPlan.displayOrder,
          validation: {
            required: true,
            isNumericWithLimit: true
          },
          valid: true,
          touched: false,
          errorText: null
        }
      };

      if (this.props.editedPlan && !this.props.editedPlan.unlimited) {
        newPlan.classesIncluded = {
          label: 'Classes included',
          type: 'text',
          placeholder: 'Classes included',
          value: parseInt(this.props.editedPlan.classesIncluded),
          validation: {
            required: true,
            isNumeric: true
          },
          valid: true,
          touched: false,
          errorText: null
        };
      }

      this.setState({
        newPlan,
        active: this.props.editedPlan.active,
        unlimitedClasses: this.props.editedPlan.unlimited
      });
    }
  }

  handleClassesCheckbox = (checkboxEvent) => {
    const copiedForm = cloneDeep(this.state.newPlan);

    if (checkboxEvent.target.checked) {
      delete copiedForm.classesIncluded;
    } else {
      copiedForm.classesIncluded = {
        label: 'Classes included',
        type: 'number',
        placeholder: 'Classes included',
        value: '',
        validation: {
          required: true,
          isNumeric: true
        },
        valid: false,
        touched: false,
        errorText: null
      };
    }

    this.setState({
      newPlan: copiedForm,
      unlimitedClasses: checkboxEvent.target.checked
    });
  };

  optionalElementChangedHandler = (checkboxEvent, identifier) => {
    let updatednewPlan = undefined;
    if (!checkboxEvent.target.checked) {
      updatednewPlan = updateObject(this.state.newPlan, {
        [identifier]: updateObject(this.state.newPlan[identifier], {
          value: '',
          validation: updateObject(this.state.newPlan[identifier].validation, {
            required: checkboxEvent.target.checked
          }),
          checkbox: updateObject(this.state.newPlan[identifier].checkbox, {
            enabled: checkboxEvent.target.checked
          }),
          errorText: '',
          touched: true
        })
      });
    } else {
      updatednewPlan = updateObject(this.state.newPlan, {
        [identifier]: updateObject(this.state.newPlan[identifier], {
          validation: updateObject(this.state.newPlan[identifier].validation, {
            required: checkboxEvent.target.checked
          }),
          checkbox: updateObject(this.state.newPlan[identifier].checkbox, {
            enabled: checkboxEvent.target.checked
          })
        })
      });
    }

    return this.setState({
      newPlan: updatednewPlan,
      formValidated: false
    });
  };

  handleVisibleClassesCheckbox = (event) => {
    this.setState({ active: event.target.checked });
  };

  valueChangedHandler = (newValue, identifier) => {
    const updatednewPlan = updateObject(this.state.newPlan, {
      [identifier]: updateObject(this.state.newPlan[identifier], {
        value: newValue,
        valid: checkValidity(newValue, this.state.newPlan[identifier].validation),
        errorText: returnErrorTextForField(this.state.newPlan[identifier].validation),
        touched: true
      })
    });
    return this.setState({
      newPlan: updatednewPlan,
      formValidated: false
    });
  };

  checkFormValidity = () => {
    this.setState({ formValidated: true });
    for (let input in this.state.newPlan) {
      if (!this.state.newPlan[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return this.setState({ formIsValid: false });
      }
    }

    this.setState({ formIsValid: true });
    this.addEditPlan();
  };

  addEditPlan = () => {
    this.setState({ loading: true });

    const formData = {};
    for (let input in this.state.newPlan) {
      formData[input] =
        typeof this.state.newPlan[input].value === 'string'
          ? this.state.newPlan[input].value.trim()
          : this.state.newPlan[input].value;
    }
    formData['active'] = this.state.active;

    formData['unlimited'] = this.state.unlimitedClasses;
    if (this.state.unlimitedClasses) {
      formData['classesIncluded'] = 0;
    }

    if (this.state.newPlan.joiningFee && !this.state.newPlan.joiningFee.checkbox.enabled) {
      formData['joiningFee'] = 0;
    }

    if (this.props.editedPlan) {
      axios
        .patch(`/membership-plan/${this.props.editedPlan._id}`, formData)
        .then(() => {
          this.setState({ loading: false });
          this.props.onFetchPlansList(this.props.selectedGym._id);
          this.props.history.push(`/gym/${this.props.selectedGym._id}/membership-plans`);
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.setState({ error: err.message });
        });
    } else {
      formData.gymId = this.props.selectedGym._id;
      if (formData.classesIncluded === undefined) {
        formData.unlimited = true;
      }
      formData.currency = formData.currency.value;

      axios
        .post('/membership-plan/create', formData)
        .then(() => {
          this.setState({ loading: false });
          this.props.onFetchPlansList(this.props.selectedGym._id);
          this.props.history.push(`/gym/${this.props.selectedGym._id}`);
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.setState({ error: err.message });
        });
    }
  };

  render() {
    let formWarning = null;
    if (!this.state.formIsValid && this.state.formValidated) {
      formWarning = (
        <Notification
          message="Form is not filled in correctly"
          status="warning"
          className="ss-top-notification"
          style={{ width: '100%' }}
        />
      );
    }

    let button = (
      <Button
        style={{ marginTop: '50px', display: 'block' }}
        label={this.props.editedPlan ? 'Save' : 'Add'}
        secondary={true}
        onClick={this.checkFormValidity}
      />
    );
    if (this.state.loading) {
      button = <Spinning style={{ marginTop: '50px', display: 'block' }} />;
    }

    let error = null;
    if (this.state.error) {
      error = <Notification message={this.state.error} status="critical" className="ss-top-notification" />;
    }

    return (
      <>
        {error}
        {formWarning}

        <FormCreator
          formData={this.state.newPlan}
          valueChanged={this.valueChangedHandler}
          optionalElementChanged={this.optionalElementChangedHandler}
        />

        <CheckBox
          className={classes.unlimitedClasses}
          checked={this.props.editedPlan ? this.state.unlimitedClasses : this.state.unlimitedClasses}
          onChange={this.handleClassesCheckbox}
          label="Unlimited classes included"
        />

        <CheckBox checked={this.state.active} onChange={this.handleVisibleClassesCheckbox} label="Visible" />

        {button}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditMembershipPlan);
