import moment from 'moment';

// backend can return date in both string ("date") format and unix, that's why we are checking isNaN()

export const returnFormattedDate = (date) => {
  if (!date) {
    return '';
  }

  if (isNaN(date)) {
    return moment(date).format('Do MMM YY h:mm A');
  } else {
    return moment.unix(date).format('Do MMM YY h:mm A');
  }
};

export const isDateExpired = (date) => {
  if (isNaN(date)) {
    return moment().isAfter(date);
  } else {
    return moment().unix() > date;
  }
};

export const returnDateAndTime = (classDate, classTime) =>
  moment(classDate, 'YYYY-MM-DD')
    .set({
      hour: moment(classTime, ['hh:mm A']).get('hour'),
      minute: moment(classTime, ['hh:mm A']).get('minute')
    })
    .format('DD/MM/YYYY h:mm a');
