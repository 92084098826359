import React, { useState, useEffect } from 'react';
import classes from './ManageWorkingDays.module.scss';
import Button from 'grommet/components/Button';
import DateTime from 'grommet/components/DateTime';
const ManageWorkingDays = ({ shifts, isEditTrainer, shiftGenerated, isShiftAreValid }) => {
  const [fields, setFields] = useState([{ day: 'monday', startingFrom: null, endsAt: null }]);
  const [dayList] = useState([
    {
      id: 'monday',
      name: 'Monday'
    },
    {
      id: 'tuesday',
      name: 'Tuesday'
    },
    {
      id: 'wednesday',
      name: 'Wednesday'
    },
    {
      id: 'thursday',
      name: 'Thursday'
    },
    {
      id: 'friday',
      name: 'Friday'
    },
    {
      id: 'saturday',
      name: 'Saturday'
    },
    {
      id: 'sunday',
      name: 'Sunday'
    }
  ]);
  const [shiftsAreValid, setShiftsAreValid] = useState(true);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(
    () => {
      if (isEditTrainer) {
        if (shifts !== undefined && shifts.length !== 0) {
          setFields(shifts);
        } else {
          setFields(fields);
        }
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      setShiftsAreValid(true);
      if (isEditTrainer) {
        validateFields(fields);
      } else {
        if (initialRender === true) {
          setInitialRender(false);
        } else {
          validateFields(fields);
        }
      }
    },
    // eslint-disable-next-line
    [fields]
  );

  useEffect(
    () => {
      handleShiftChange();
    },
    // eslint-disable-next-line
    [shiftsAreValid]
  );

  const validateFields = (fields) => {
    const invalidFields = fields.filter(
      (field) => field.startingFrom === null || field.endsAt === null || field.startingFrom >= field.endsAt
    );

    if (invalidFields !== null && invalidFields.length !== 0) {
      setShiftsAreValid(false);
    } else {
      handleShiftChange();
    }
  };

  const handleSelectChange = (i, event) => {
    const values = [...fields];
    values[i].day = event.target.value;
    setFields(values);
  };

  const handleShiftChange = () => {
    shiftGenerated(fields);
    isShiftAreValid(shiftsAreValid);
  };

  const handleTimeChange = (i, event, key) => {
    const values = [...fields];

    if (key === 'fromTime') {
      values[i].startingFrom = event;
    } else {
      values[i].endsAt = event;
    }

    setFields(values);
  };

  const handleRemove = (i) => {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  };

  const handleAdd = () => {
    const values = [...fields];
    values.push({ day: 'monday', startingFrom: null, endsAt: null });
    setFields(values);
  };

  return (
    <div>
      {fields.map((field, idx) => {
        return (
          <div className={classes.workingDays} key={`${field}-${idx}`}>
            <div>
              <div className={classes.WorkingDayField}>
                {idx === 0 && <h4 className={classes.label}>Day</h4>}
                <select value={field.day || ''} onChange={(e) => handleSelectChange(idx, e)}>
                  {Object.keys(dayList).map((day) => (
                    <option key={dayList[day].id} value={dayList[day].id}>
                      {dayList[day].name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <div className={classes.WorkingDayField}>
                {idx === 0 && <h4 className={classes.label}>From</h4>}
                <div>
                  <DateTime
                    format="HH:mm"
                    value={field.startingFrom || ''}
                    onChange={(e) => handleTimeChange(idx, e, 'fromTime')}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={classes.WorkingDayField}>
                {idx === 0 && <h4 className={classes.label}>To</h4>}
                <div>
                  <DateTime
                    format="HH:mm"
                    value={field.endsAt || ''}
                    onChange={(e) => handleTimeChange(idx, e, 'toTime')}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                {fields.length !== 1 && (
                  <Button
                    className={`${idx === 0 ? classes.btnFirst : classes.btn}`}
                    secondary
                    onClick={() => handleRemove(idx)}>
                    X
                  </Button>
                )}
              </div>
            </div>
            <div>
              <div>
                {idx === fields.length - 1 && (
                  <Button
                    className={`${idx === 0 ? classes.btnFirst : classes.btn}`}
                    primary
                    onClick={() => handleAdd()}>
                    +
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ManageWorkingDays;
