import React from 'react';
import Heading from 'grommet/components/Heading';
import TextInput from 'grommet/components/TextInput';
import Label from 'grommet/components/Label';
import Select from 'grommet/components/Select';
import TrainerSelector from '../../../components/Selectors/TrainerSelector/TrainerSelector';
import PropTypes from 'prop-types';
import classes from './EditVideoMetaData.module.scss';

function EditVideoMetaData({
  productList = [],
  selectedProducts,
  setSelectedProducts,
  categories = [],
  videoMetaData,
  setVideoMetaData
}) {
  const products = productList
    .filter(({ active }) => !!active)
    .map((product) => ({
      label: product.name,
      value: product._id
    }));

  const onSelectCategory = (name, options) => {
    setVideoMetaData({
      ...videoMetaData,
      videoConfig: [
        ...videoMetaData.videoConfig.filter((config) => config.name !== name),
        { name, options, active: true }
      ]
    });
  };

  return (
    <div>
      <Heading tag="h3">Video MetaData</Heading>
      <div className={classes.formField}>
        <Label id="title" className={classes.label}>
          Title
        </Label>
        <TextInput
          aria-labelledby="title"
          value={videoMetaData.title}
          placeHolder={'Please enter title'}
          onDOMChange={({ target }) => {
            setVideoMetaData({
              ...videoMetaData,
              title: target.value
            });
          }}
        />
      </div>

      {!!products.length && (
        <div className={classes.formField}>
          <Label id="product" className={classes.label}>{`Select Product`}</Label>
          <Select
            aria-labelledby="product"
            multiple
            value={selectedProducts}
            options={products}
            onChange={({ value }) => setSelectedProducts(value)}
          />
        </div>
      )}

      <TrainerSelector
        selectedTrainer={videoMetaData.selectedTrainer}
        onSelectTrainer={(trainer) => {
          setVideoMetaData({
            ...videoMetaData,
            selectedTrainer: trainer._id
          });
        }}
      />

      {categories.length
        ? categories.map((category) => {
            const value =
              videoMetaData.videoConfig && videoMetaData.videoConfig.find((config) => config.name === category.name);
            return (
              <div key={category.id} className={classes.formField}>
                <Label id={category.id} className={classes.label}>{`Select ${category.name}`}</Label>
                <Select
                  aria-labelledby={category.id}
                  multiple
                  value={value && value.options ? value.options : null}
                  options={category.options || []}
                  onChange={({ value }) => onSelectCategory(category.name, value)}
                />
              </div>
            );
          })
        : null}
    </div>
  );
}

EditVideoMetaData.propTypes = {
  productList: PropTypes.array,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  categories: PropTypes.array,
  videoMetaData: PropTypes.object,
  setVideoMetaData: PropTypes.func
};

export default EditVideoMetaData;
