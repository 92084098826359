import React, { useState } from 'react';
import { Select, Label } from 'grommet';
import classes from './TrainerSelector.module.scss';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import Notification from 'grommet/components/Notification';
import { HashLink as Link } from 'react-router-hash-link';
import { useQuery } from 'react-query';
import { fetchTrainers } from '../../../apiFunctions/apiFunctions';
import Loading from '../../Loading/Loading';

const TrainerSelector = ({
  trainersList,
  trainersListLoading,
  trainersListError,
  selectedTrainer,
  onSelectTrainer,
  gymId = null,
  isValid = null,
  setStatus = () => {},
  showLabel = true
}) => {
  const [filteredTrainers, setFilteredTrainers] = useState();

  const { data, isLoading, error } = useQuery(['trainersList', gymId], () => fetchTrainers(gymId), {
    enabled: gymId !== null
  });

  let value = null;

  if (trainersListLoading || isLoading) {
    setStatus('loading');
    return <Loading />;
  }

  if (trainersListError || error) {
    setStatus('error');
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  const trainers = gymId !== null ? data : trainersList;

  if (selectedTrainer && typeof selectedTrainer === 'string') {
    const selection = trainers.find((trainer) => trainer._id === selectedTrainer);
    value = `${selection.name} ${selection.last_name}`;
  }
  if (selectedTrainer && selectedTrainer.name && selectedTrainer.last_name) {
    value = `${selectedTrainer.name} ${selectedTrainer.last_name}`;
  }

  if (trainers && trainers.length === 0) {
    setStatus('noData');
    return (
      <Link className={classes.noData} to={`/gym/${gymId}/trainers`}>
        Please add at least one trainer first
      </Link>
    );
  }

  if (trainers && trainers.length > 0) {
    setStatus('ok');
    const list = trainers.map((elem) => {
      return {
        ...elem,
        label: `${elem.name} ${elem.last_name}`,
        value: elem
      };
    });

    const onFilterTrainerList = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredTrainerList = list.filter(
        (trainer) => trainer.name.toLowerCase().startsWith(filter) || trainer.last_name.toLowerCase().startsWith(filter)
      );
      setFilteredTrainers(filteredTrainerList);
    };

    return (
      <div className={classes.bottomSpace}>
        {showLabel && <Label id="trainer">Trainer</Label>}
        <Select
          aria-labelledby="trainer"
          options={filteredTrainers || list}
          value={value}
          onChange={({ value }) => onSelectTrainer(value)}
          onSearch={onFilterTrainerList}
        />
        {isValid !== null && !isValid && <div className={classes.trainerError}>Please select a trainer</div>}
      </div>
    );
  }
};

export default withAdminDashboard(TrainerSelector);
