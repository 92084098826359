import React, { useState } from 'react';
import withUser from '../../../../store/hoc/withUser';
import Modal from 'react-modal';
import ListItem from 'grommet/components/ListItem';
import Toast from 'grommet/components/Toast';
import { connect } from 'react-redux';
import Icons from '../../../../components/UI/Icons/Icons';
import TrainersAddEditTrainer from '../TrainersAddEditTrainer/TrainersAddEditTrainer';
import * as actions from '../../../../store/actions/index';

import classes from './SingleTrainer.module.scss';
import { isFullAccessAdminOrTrainer } from '../../../../shared/userFunctions';

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveSingleTrainer: (trainer, gymId, gymsList) => dispatch(actions.removeSingleTrainer(trainer, gymId, gymsList))
  };
};

function SingleTrainer({
  trainer,
  trainerFullAccess,
  gymsList,
  onRemoveSingleTrainer,
  selectedGym,
  adminFullAccess,
  refreshData,
  isFromSearch,
  refreshAfterDelete
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const renderNotification = () => {
    if (showConfirm) {
      return (
        <Toast status="warning" onClose={() => setShowConfirm(false)}>
          Click once again to confirm the deletion of the trainer
        </Toast>
      );
    }
  };

  const handleTrainerDelete = () => {
    if (!showConfirm) {
      return setShowConfirm(true);
    }
    //TODO: delete trainer
    onRemoveSingleTrainer(trainer, adminFullAccess ? true : selectedGym._id, gymsList);
    if (isFromSearch) {
      refreshAfterDelete();
    }
  };

  const onEditModalClose = (refresh) => {
    setShowModal(false);
    if (refresh) {
      refreshData();
    }
  };

  return (
    <div>
      {renderNotification()}

      <Modal onRequestClose={() => setShowModal(false)} isOpen={showModal} className="ss-modal">
        <div className="grommet">
          <TrainersAddEditTrainer
            editTrainer={trainer}
            closeModal={(refresh) => onEditModalClose(refresh)}
            gymsList={gymsList}
            trainerFullAccess={trainerFullAccess}
          />
        </div>
      </Modal>

      <ListItem direction="column" align="start" separator="horizontal">
        <div className={classes.singleTrainer}>
          <div className={classes.trainerStatus}>
            <span className={classes.trainerName}>
              {trainer.name} {trainer.last_name}
              {isFullAccessAdminOrTrainer(trainer) && <span> - gyms full access</span>}
            </span>
          </div>

          <Icons edit delete editClick={() => setShowModal(true)} deleteClick={handleTrainerDelete} />
        </div>
      </ListItem>
    </div>
  );
}

export default withUser(connect(null, mapDispatchToProps)(SingleTrainer));
