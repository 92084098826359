import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ArrowRight from '../../../../brandConfig/assets/svg/arrow-right.svg';
import ArrowLeft from '../../../../brandConfig/assets/svg/arrow-left.svg';
import classes from './SingleDay.module.scss';
import withUser from '../../../../store/hoc/withUser';
import DefaultTimetable from './DefaultTimetable';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';
import Heading from 'grommet/components/Heading';

const DefaultSingleDayTimetable = ({
  classesList,
  classesListLoading,
  classesListError,
  classesDetails,
  onFetchClasses,
  classConfig,
  showAllGymsData
}) => {
  const [currentDay, setCurrentDay] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  useEffect(() => {
    if (classesList && classesList.length > 0) {
      setCurrentDay(0);
      setHasPrevious(false);
      setHasNext(classesList.length > 1);
    }
  }, [classesList]);

  if (classesList === undefined || classesListLoading) {
    return <Spinning />;
  }

  if (classesListError) {
    return <Notification message="Something went wrong. Please try again." status="critical" />;
  }

  const changeDay = (value) => {
    setCurrentDay(currentDay + value);
    setHasPrevious(currentDay + value >= 1);
    setHasNext(classesList.length - 1 > currentDay + value);
  };

  const renderDay = (date) => {
    return (
      <div className={classes.dayHeader}>
        {hasPrevious ? (
          <img src={ArrowLeft} className={classes.arrowLeft} alt="previousDayIcon" onClick={() => changeDay(-1)} />
        ) : (
          <span></span>
        )}
        <Heading tag="h3">
          {moment(date, 'YYYY-MM-DD').format('dddd').toUpperCase()} - {moment(date, 'YYYY-MM-DD').format('DD/MM')}
        </Heading>
        {hasNext ? (
          <img src={ArrowRight} className={classes.arrowRight} alt="nextDayIcon" onClick={() => changeDay(1)} />
        ) : (
          <span></span>
        )}
      </div>
    );
  };

  return (
    <>
      {classesList && !!classesList.length ? (
        <div data-testid="default-single-day-timetable" className={classes.root}>
          {classesList[currentDay] && renderDay(classesList[currentDay]._id)}
          {classesList[currentDay] && (
            <DefaultTimetable
              classesList={classesList[currentDay].classes}
              classesListLoading={classesListLoading}
              classesListError={classesListError}
              classType={classesDetails.type}
              classesDetails={classesDetails}
              onFetchClasses={onFetchClasses}
              classConfig={classConfig}
              showAllGymsData={showAllGymsData}
            />
          )}
        </div>
      ) : (
        <Heading tag="h3">No bookings</Heading>
      )}
    </>
  );
};

export default withUser(DefaultSingleDayTimetable);
