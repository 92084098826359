import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchVideoCategories, fetchVideos, fetchPasses } from '../../apiFunctions/apiFunctions';
import withAdminDashboard from '../../store/hoc/withAdminDashboard';
import Loading from '../../components/Loading/Loading';
import Button from 'grommet/components/Button';
import Notification from 'grommet/components/Notification';
import ShowVideos from './ShowVideos/Menu';
import AddVideo from './AddVideo/AddVideo';
import AddCategory from './AddCategory/AddCategory';
import EditCategory from './EditCategory/EditCategory';

import classes from './ManageVideos.module.scss';

const ManageVideos = ({
  isAdmin,
  selectedGym,
  picker = false,
  pickerSelection = [],
  onPick = () => {},
  onReorder = () => {},
  onClosePicker = () => {}
}) => {
  const [addVideo, setAddVideo] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const { data: videos, isLoading: videosLoading, error: videosError } = useQuery(['fetchVideos'], () => fetchVideos());
  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError
  } = useQuery(['fetchVideoCategories'], () => fetchVideoCategories());
  const {
    data: videoProducts,
    isLoading: videoProductsLoading,
    error: videoProductsError
  } = useQuery(['fetchPasses'], () => fetchPasses('video', selectedGym._id));

  if (videosLoading || categoriesLoading || videoProductsLoading) {
    return <Loading size={'large'} />;
  }

  if (videosError || categoriesError || videoProductsError) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  return (
    <>
      {isAdmin && (
        <>
          <div className={classes.buttonBar}>
            <Button label="Add Video" primary onClick={() => setAddVideo(true)} />
            {picker ? (
              <Button label="Done" secondary={true} onClick={onClosePicker} />
            ) : (
              <>
                <Button label="Add Category" secondary={true} onClick={() => setAddCategory(true)} />
                <Button label="Edit Categories" secondary={true} onClick={() => setEditCategory(true)} />
              </>
            )}
          </div>

          <AddVideo
            categories={categories}
            open={addVideo}
            onClose={() => setAddVideo(false)}
            productList={picker ? [] : videoProducts}
          />
          <AddCategory open={addCategory} onClose={() => setAddCategory(false)} />
          <EditCategory
            categories={categories}
            isAdmin={isAdmin}
            open={editCategory}
            onClose={() => setEditCategory(false)}
          />
        </>
      )}

      <ShowVideos
        videos={videos}
        isAdmin={isAdmin}
        picker={picker}
        pickerSelection={pickerSelection}
        onPick={onPick}
        onReorder={onReorder}
        categories={categories}
        productList={videoProducts}
        gymId={selectedGym._id}
      />
    </>
  );
};

export default withAdminDashboard(ManageVideos);
