import React, { useState, useEffect, useCallback } from 'react';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import Spinning from 'grommet/components/icons/Spinning';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import DateTime from 'grommet/components/DateTime';
import FormField from 'grommet/components/FormField';
import Select from 'grommet/components/Select';
import './PayrollReports.scss';
import RefreshIcon from 'grommet/components/icons/base/Refresh';
import { CLASS_TYPES, CLASS_LABELS } from '../../../../constants';
import moment from 'moment';
import Table from 'grommet/components/Table';
import TableRow from 'grommet/components/TableRow';
import { useQuery } from 'react-query';
import { fetchTrainers, fetchPayrollReport } from '../../../../apiFunctions/apiFunctions';
import Notification from 'grommet/components/Notification';
import { returnErrorFromResponse } from '../../../../shared/utility';
import CustomToast from '../../../../components/CustomToast/CustomToast';
export const PayrollReports = (props) => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [trainers, setTrainers] = useState([]);
  const [filteredTrainerList, setFilteredTrainerList] = useState(null);
  const [selectedTrainer, setSelectedTrainer] = useState([]);
  const [filteredPass, setFilteredPass] = useState(null);
  const [selectedPass, setSelectedPass] = useState([]);
  const [reqData, setReqData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(null);

  const {
    data: trainersList,
    isLoading: isLoadingTrainer,
    error: isTrainerError
  } = useQuery(['trainersList', props.selectedGym._id], () => fetchTrainers(props.selectedGym._id), {
    enabled: props.selectedGym._id !== null
  });

  const getReport = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchPayrollReport(reqData);
      setData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(error),
        show: true
      });
    }
  }, [reqData]);

  useEffect(() => {
    if (reqData !== undefined && reqData !== null) {
      getReport();
    }
  }, [reqData, getReport]);

  useEffect(() => {
    if (
      startDate !== undefined &&
      endDate !== undefined &&
      moment(startDate, 'DD/MM/YYYY') >= moment(endDate, 'DD/MM/YYYY')
    ) {
      return setToast({
        status: 'warning',
        msg: 'Please select start date before end date',
        show: true
      });
    } else if (
      startDate !== undefined &&
      endDate !== undefined &&
      moment(startDate, 'DD/MM/YYYY') < moment(endDate, 'DD/MM/YYYY') &&
      selectedTrainer.length > 0
    ) {
      let data = {
        fromDate: moment(startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        toDate: moment(endDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
      };
      if (selectedPass.length > 0) {
        data['classTypes'] = selectedPass.map((type) => type.value);
      }
      if (selectedTrainer.length > 0) {
        data['trainerIds'] = selectedTrainer.map((trainer) => trainer.value);
      }
      setReqData(data);
    } else {
      setReqData(null);
      setData([]);
    }
  }, [selectedTrainer, startDate, endDate, selectedPass]);

  useEffect(() => {
    if (trainersList) {
      const trainersData = trainersList.map((trainer) => {
        return {
          value: trainer._id,
          name: trainer.name,
          last_name: trainer.last_name,
          label: `${trainer.name} ${trainer.last_name}`
        };
      });

      setTrainers(trainersData);
    }
  }, [trainersList, isLoadingTrainer, isTrainerError]);

  if (isLoadingTrainer) {
    return <Spinning />;
  }

  if (isTrainerError) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  const renderPassSelection = () => {
    let passesOptions = [];

    CLASS_TYPES.forEach((value) => {
      if (value !== 'video') {
        passesOptions.push({
          label: CLASS_LABELS[value],
          value: value
        });
      }
    });

    const onFilterPassList = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredPassesList = passesOptions.filter((pass) => pass.label.toLowerCase().includes(filter));

      setFilteredPass(
        filteredPassesList.map((elem) => {
          return {
            label: elem.label,
            value: elem.value
          };
        })
      );
    };

    const choosePassType = (event) => {
      setSelectedPass(event.value);
    };

    return (
      <div className="margin-top">
        <h4>Select type</h4>
        <div className="refresh-div">
          <Select
            placeHolder="Pass: all"
            multiple={true}
            options={filteredPass || passesOptions}
            value={selectedPass}
            onChange={choosePassType}
            onSearch={onFilterPassList}
          />
          {selectedPass.length > 0 && (
            <div>
              <RefreshIcon className="margin-left" onClick={() => setSelectedPass([])} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTrainer = () => {
    const searchTrainer = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredTrainerList = trainers.filter(
        (trainer) => trainer.name.toLowerCase().includes(filter) || trainer.last_name.toLowerCase().includes(filter)
      );
      setFilteredTrainerList(filteredTrainerList);
    };

    const trainerSelected = (trainer) => {
      setSelectedTrainer(trainer.value);
    };
    return (
      <div>
        <h4>Trainers</h4>
        <div className="refresh-div">
          <Select
            placeHolder="Trainer: all"
            multiple={true}
            options={filteredTrainerList || trainers}
            value={selectedTrainer}
            onChange={trainerSelected}
            onSearch={searchTrainer}
          />
          {selectedTrainer.length > 0 && (
            <div>
              <RefreshIcon className="margin-left" onClick={() => trainerSelected({ value: [] })} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDates = () => {
    const startDateChange = (date) => {
      setStartDate(date);
    };
    const endDateChange = (date) => {
      setEndDate(date);
    };

    return (
      <>
        <div>
          <h4>Start Date</h4>
          <FormField>
            <DateTime
              id="filterDate"
              name="filterDate"
              format="DD/MM/YYYY"
              onChange={startDateChange}
              value={startDate}
            />
          </FormField>
        </div>
        <div className="margin-top">
          <h4>End Date</h4>
          <FormField>
            <DateTime id="filterDate" name="filterDate" format="DD/MM/YYYY" onChange={endDateChange} value={endDate} />
          </FormField>
        </div>
      </>
    );
  };

  const getTableRows = () => {
    return data.map((report) => {
      return report.classes.map((elem, id) => {
        return (
          <TableRow key={id} className="booking-item">
            <td>{elem.name}</td>
            <td>{elem.classType}</td>
            <td>{elem.gymName}</td>
            <td>{report.trainer}</td>
            <td>{moment(elem.classDate).format('DD-MM-YYYY')}</td>
            <td>{elem.classTime}</td>
          </TableRow>
        );
      });
    });
  };

  const renderTable = () => {
    return (
      <>
        {data && data.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Gym</th>
                <th>Trainer</th>
                <th>Date</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>{getTableRows()}</tbody>
          </Table>
        ) : (
          <div>
            <div className="report__filter_debtors">
              <div>
                <h3>No history</h3>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {data !== undefined && data.length > 0 && (
        <ExportToCSV data={data} type={'payrollReport'} gymName={props.selectedGym.name} />
      )}
      <div>
        <div className="attendenceReport__filter">
          <CustomToast toast={toast} />
          <div>
            {renderTrainer()}
            {renderPassSelection()}
          </div>
          <div>{renderDates()}</div>
        </div>

        {loading ? <Spinning /> : renderTable()}
      </div>
    </>
  );
};

export default withAdminDashboard(PayrollReports);
