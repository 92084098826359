import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from 'react-query';
import isEqual from 'lodash/isEqual';
import classes from './Thumbnail.module.scss';
import Lock from 'grommet/components/icons/base/Lock';
import Image from 'grommet/components/Image';
import Button from 'grommet/components/Button';
import Heading from 'grommet/components/Heading';
import EditIcon from 'grommet/components/icons/base/Edit';
import DeleteIcon from 'grommet/components/icons/base/Trash';
import EditVideoMetaData from '../../EditVideo/EditVideoMetaData';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { returnErrorFromResponse } from '../../../../shared/utility';
import CustomToast from '../../../../components/CustomToast/CustomToast';
import Spinning from 'grommet/components/icons/Spinning';
import {
  editVideo,
  delayFetchFunctions,
  addVideoToProducts,
  removeVideoFromProducts,
  deleteVideo
} from '../../../../apiFunctions/apiFunctions';

const Thumbnail = ({
  video,
  isAdmin,
  categories = { active: [] },
  productList,
  gymId,
  picker = false,
  selected = false,
  onPick,
  watched = false,
  locked = false
}) => {
  const [toast, setToast] = useState(null);
  const [openSaveDialogue, setOpenSaveDialogue] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [videoMetaData, setVideoMetaData] = useState({
    title: '',
    selectedTrainer: '',
    videoConfig: []
  });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { isLoading: metaDataIsLoading, mutateAsync: mutateMetaData } = useMutation(({ videoId, body }) =>
    editVideo(videoId, body)
  );
  const { isLoading: removeVideoFromProductsIsLoading, mutateAsync: mutateRemoveVideoFromProducts } = useMutation(
    ({ videoId, products }) => removeVideoFromProducts(videoId, products)
  );
  const { isLoading: addVideoToProductsIsLoading, mutateAsync: mutateAddVideoToProducts } = useMutation(
    ({ videoId, products }) => addVideoToProducts(videoId, products)
  );
  const { isLoading: deleteIsLoading, mutateAsync: mutateDeleteVideo } = useMutation(({ videoId, products }) =>
    deleteVideo(videoId, products)
  );

  const thumbnailUrl = video.media.find((media) => media.type === 'Thumbnail').files[0].path;

  const isValid = useCallback(() => {
    if (videoMetaData.title.length) {
      return true;
    }
    return false;
  }, [videoMetaData]);

  const setConfig = (productList, video) => {
    const { title, trainer, videoConfig } = video;
    setSelectedProducts(
      productList
        ? productList
            .filter((product) => product.videos && product.videos.includes(video._id))
            .map((product) => ({
              label: product.name,
              value: product._id
            }))
        : []
    );
    setVideoMetaData({
      title,
      selectedTrainer: trainer,
      videoConfig
    });
  };

  useEffect(() => {
    setConfig(productList, video);
  }, [productList, video]);

  const onSave = async () => {
    const selectedProductIds = selectedProducts.map((selectedProduct) => selectedProduct.value);
    const deletedFrom = productList.filter(
      (product) => product.videos && product.videos.includes(video._id) && !selectedProductIds.includes(product._id)
    );
    const addedTo = productList.filter(
      (product) =>
        !product.videos ||
        (product.videos && !product.videos.includes(video._id) && selectedProductIds.includes(product._id))
    );
    const titleChanged = video.title !== videoMetaData.title;
    const trainerChanged = video.trainer !== videoMetaData.selectedTrainer;
    const categoriesChanged = !isEqual(video.videoConfig, videoMetaData.videoConfig);

    try {
      if (titleChanged || trainerChanged || categoriesChanged) {
        await mutateMetaData({
          videoId: video._id,
          body: {
            ...(titleChanged ? { title: videoMetaData.title } : {}),
            ...(trainerChanged ? { trainer: videoMetaData.selectedTrainer } : {}),
            ...(categoriesChanged ? { videoConfig: videoMetaData.videoConfig } : {})
          }
        });
      }
      if (deletedFrom.length) {
        await mutateRemoveVideoFromProducts({
          videoId: video._id,
          products: deletedFrom
        });
      }
      if (addedTo.length) {
        await mutateAddVideoToProducts({
          videoId: video._id,
          products: addedTo
        });
      }
      setToast({
        status: 'ok',
        msg: 'Video Updated',
        show: true
      });
      delayFetchFunctions(['fetchVideos', 'fetchPasses'], 0);
    } catch (error) {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(error),
        show: true
      });
    }
    setOpenSaveDialogue(false);
  };

  const onDelete = async () => {
    try {
      await mutateDeleteVideo({
        videoId: video._id,
        products: productList
      });
      setToast({
        status: 'ok',
        msg: 'Video Deleted',
        show: true
      });
      delayFetchFunctions(['fetchVideos', 'fetchPasses'], 0);
    } catch (error) {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(error),
        show: true
      });
    }
    setOpenDeleteDialogue(false);
  };

  const getContent = () => (
    <>
      <div className={classes.thumbnailImg}>
        {locked && <Lock className={classes.lockIcon} />}

        <Image src={thumbnailUrl} alt={video.title} fit="cover" />
      </div>
      <div className={classes.content}>
        <Heading tag="h4">{video.title}</Heading>
      </div>
    </>
  );

  return (
    <>
      <CustomToast toast={toast} />
      <Modal
        appElement={document.getElementById('root')}
        isOpen={openSaveDialogue}
        onRequestClose={() => {
          setOpenSaveDialogue(false);
          setConfig(productList, video);
        }}
        className={`ss-modal`}>
        {metaDataIsLoading || addVideoToProductsIsLoading || removeVideoFromProductsIsLoading ? (
          <div className={classes.spinner}>
            <Spinning size="xlarge" />
          </div>
        ) : (
          <>
            <EditVideoMetaData
              productList={productList}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              categories={categories.active}
              videoMetaData={videoMetaData}
              setVideoMetaData={setVideoMetaData}
            />
            <div className={classes.buttonBar}>
              <Button
                label="Cancel"
                secondary={true}
                onClick={() => {
                  setOpenSaveDialogue(false);
                  setConfig(productList, video);
                }}
              />
              {isValid() && <Button label="Save" primary onClick={onSave} />}
            </div>
          </>
        )}
      </Modal>
      <Modal
        appElement={document.getElementById('root')}
        isOpen={openDeleteDialogue}
        onRequestClose={() => setOpenDeleteDialogue(false)}
        className={`ss-modal`}>
        {deleteIsLoading ? (
          <div className={classes.spinner}>
            <Spinning size="xlarge" />
          </div>
        ) : (
          <>
            <Heading tag="h3">{`Are you sure you wish to delete ${video.title}?`}</Heading>
            <div className={classes.buttonBar}>
              <Button label="Cancel" secondary={true} onClick={() => setOpenDeleteDialogue(false)} />
              <Button label="Delete" primary onClick={onDelete} />
            </div>
          </>
        )}
      </Modal>
      <div className={`${classes.thumbnail} ${locked ? classes.locked : ''}`}>
        {picker ? (
          <div
            className={`${classes.picker} ${selected ? classes.selected : ''}`}
            onClick={() => onPick(video)}
            aria-label={`Pick ${video.title}`}>
            {getContent()}
          </div>
        ) : (
          <Link
            key={video._id}
            aria-label={`View ${video.title}`}
            to={isAdmin ? `/gym/${gymId}/videos/${video._id}` : `/gym/videos/${video._id}`}
            className={`${classes.link} ${watched ? classes.watched : ''} ${locked ? classes.locked : ''}`}>
            {getContent()}
          </Link>
        )}
        {isAdmin && !picker ? (
          <div className={classes.actionBar}>
            <Button icon={<EditIcon />} onClick={() => setOpenSaveDialogue(true)} />
            <Button icon={<DeleteIcon />} onClick={() => setOpenDeleteDialogue(true)} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Thumbnail;
