import React, { useState } from 'react';

import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';
import classes from './ManageMembershipPlans.module.scss';
import { TableRow, Table, TableHeader } from 'grommet';
import { getCurrencySymbol } from '../../../shared/utility';
import Icons from '../../../components/UI/Icons/Icons';
import classesSingleMembership from '../../../components/SingleMembershipPlan/SingleMembershipPlan.module.scss';
import Modal from 'react-modal';
import AddEditMembershipPlan from '../../../containers/AdminDashboard/ManageMembershipPlans/AddEditMembershipPlan/AddEditMembershipPlan';

const ManageMembershipPlans = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  let plans = <Spinning />;
  if (!props.loading && props.error) {
    plans = <Notification message={props.error} status="critical" />;
  }
  const renderControls = (element) => {
    let controls = (
      <div className={classesSingleMembership.adminControls}>
        <Icons
          edit
          editClick={() => {
            setSelectedPlan(element);
            setShowModal(true);
          }}
        />
      </div>
    );
    return controls;
  };

  const renderPasses = () => {
    return props.plansList.map((element) => {
      return (
        <TableRow key={element._id}>
          <td>{element.name}</td>
          <td>
            {getCurrencySymbol(element.currency)} {element.price}
          </td>
          <td>{element.active ? 'Yes' : 'No'}</td>
          <td>{element.unlimited ? 'Unlimited' : element.classesIncluded ? element.classesIncluded : '-'}</td>
          <td>{element.displayOrder}</td>
          <td>{renderControls(element)}</td>
        </TableRow>
      );
    });
  };

  if (!props.loading && !props.error) {
    plans =
      props.plansList && props.plansList.length > 0 ? (
        <Table responsive={true}>
          <TableHeader labels={['Name', 'Price', 'Active', 'Classes', 'Display Order', 'Action']} />
          <tbody>{renderPasses()}</tbody>
        </Table>
      ) : (
        <h3>No plans</h3>
      );
  }

  return (
    <>
      <h4 className={classes.listHeader}>Membership plans</h4>
      {plans}
      <Button
        style={{ marginTop: '50px', display: 'inline-block' }}
        label="Add"
        secondary={true}
        path={`${props.match.url}/membership-plans/add`}
      />
      <Modal onRequestClose={() => setShowModal(false)} isOpen={showModal} className="ss-modal">
        <div className="grommet">
          <AddEditMembershipPlan editedPlan={selectedPlan} />
        </div>
      </Modal>
    </>
  );
};
export default ManageMembershipPlans;
