import React from 'react';
import { withRouter } from 'react-router-dom';
import Heading from 'grommet/components/Heading';
import Table from 'grommet/components/Table';
import TableRow from 'grommet/components/TableRow';
import TableHeader from 'grommet/components/TableHeader';
import Button from 'grommet/components/Button';
import { getCurrencySymbol } from '../../shared/utility';
import RenderPassActionButtons from '../ManagePasses/RenderPassActionButtons/RenderPassActionButtons';

import classes from './VideoOnDemandPasses.module.scss';

function VideoOnDemandPasses({ videoProducts = [], userVideoProducts = [], history }) {
  return (
    <>
      <div className={classes.header}>
        <Heading tag="h3">Video On Demand</Heading>
      </div>
      {(!!userVideoProducts.length || !!videoProducts.length) && (
        <Table>
          <TableHeader labels={['Name', 'Description', 'Cost', '']} />
          <tbody>
            {userVideoProducts
              .sort((v1, v2) => v1.displayOrder - v2.displayOrder)
              .map((product) => (
                <TableRow key={product._id}>
                  <td>{product.name}</td>
                  <td>{product.description}</td>
                  <td>{product.cost ? `${getCurrencySymbol(product.currency)} ${product.cost}` : '-'}</td>
                  <td>
                    <div className={classes.vodAction}>
                      <Button
                        label="View"
                        className={classes.passButton}
                        onClick={() => {
                          history.push(`/gym/pass/${product._id}`);
                        }}
                      />
                    </div>
                  </td>
                </TableRow>
              ))}
            {videoProducts
              .sort((v1, v2) => v1.displayOrder - v2.displayOrder)
              .map((product) => (
                <TableRow key={product._id}>
                  <td>{product.name}</td>
                  <td>{product.description}</td>
                  <td>{product.cost ? `${getCurrencySymbol(product.currency)} ${product.cost}` : '-'}</td>
                  <td>
                    <div className={classes.vodAction}>
                      <RenderPassActionButtons
                        buttonStyle={{ height: '40px' }}
                        classPass={product}
                        classType={product.forClass}
                        editPass={() => {}}
                        goBackToPreviousPage={() => {}}
                      />
                    </div>
                  </td>
                </TableRow>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default withRouter(VideoOnDemandPasses);
