import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Loading from '../../components/Loading/Loading';

export default (Component) => {
  const mapStateToProps = (state) => {
    const userProducts =
      state.auth.userData && state.auth.userData.products
        ? state.auth.userData.products.map((product) => product.productId)
        : [];
    return {
      userData: state.auth.userData,
      userProducts,
      token: state.auth.token,
      error: state.auth.error,
      loading: state.auth.loading,

      locations: state.location.locations,
      locationsLoading: state.location.loading,

      selectedGym: state.admin.selectedGym,
      classesConfig:
        state.admin.selectedGym && state.admin.selectedGym.settings && state.admin.selectedGym.settings.classConfig
          ? state.admin.selectedGym.settings.classConfig
          : [],

      sourcesList: state.client.sourcesList,
      sourcesListLoading: state.client.sourcesListLoading,
      sourcesListError: state.client.sourcesListError,

      clientsList: state.admin.clientsList,

      isAdmin: state.auth.userData && state.auth.userData.role === 'admin',
      isUser: state.auth.userData && state.auth.userData.role === 'user',
      width: state.utils.width
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      onAuthCheckStatus: () => dispatch(actions.authCheckStatus()),
      onFetchSources: () => dispatch(actions.fetchSources()),
      onFetchUserDetails: () => dispatch(actions.fetchUserDetails()),
      onClassLimitAdjustment: (adjustment, nextMonth) =>
        dispatch(actions.adjustClassesLeftNumber(adjustment, nextMonth))
    };
  };

  class Wrapper extends React.Component {
    componentDidMount() {
      if (this.props.userData || this.props.loading || this.props.sourcesList || this.props.sourcesListLoading) {
        return;
      }
      this.props.onAuthCheckStatus();
      this.props.onFetchSources();
    }

    render() {
      if (this.props.loading) {
        return <Loading size={'large'} />;
      }
      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
