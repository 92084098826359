import React from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import Heading from 'grommet/components/Heading';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';
import classes from './DefaultClientUpcomingPTs.module.scss';
import { fetchUpcomingPts } from '../../../../apiFunctions/apiFunctions';

const DefaultClientUpcomingPTs = () => {
  const { data, isLoading, error } = useQuery('fetchUpcomingPts', () => fetchUpcomingPts());

  let upcomingPts = <Spinning />;

  if (!isLoading && error) {
    upcomingPts = <Notification message={error} status="critical" />;
  }

  if (!isLoading && data && data.length === 0) {
    upcomingPts = `You don't have any personal training sessions scheduled`;
  }

  if (data && !isLoading && !error && data.length > 0) {
    upcomingPts = data.map((element) => (
      <ListItem key={element._id} direction="column" align="start" separator="horizontal">
        <div className={classes.singleSession}>
          <div>
            <Heading tag="h4" className={classes.tableListHeader}>
              {moment(element.class.classDate).format('Do MMM')}, {element.class.classTime.toUpperCase()}
            </Heading>
            {element.class.notesForClient && (
              <span>
                <b className={classes.noteTitle}>From your trainer: </b> {element.class.notesForClient}
              </span>
            )}
          </div>
        </div>
      </ListItem>
    ));
  }

  return (
    <div className={classes.singleDay}>
      <h2 className={classes.listHeader}>Personal Training Bookings</h2>
      <List>{upcomingPts}</List>
    </div>
  );
};

export default DefaultClientUpcomingPTs;
