import axios from '../../../../axios-global';
import React, { useState, useEffect } from 'react';
import { TextInput, Table, TableHeader, TableRow, RadioButton } from 'grommet';
import DateTime from 'grommet/components/DateTime';
import FormField from 'grommet/components/FormField';
import CheckBox from 'grommet/components/CheckBox';
import './ClassAttendancesReports.scss';
import moment from 'moment';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import Spinning from 'grommet/components/icons/Spinning';
import Select from 'grommet/components/Select';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';

export const ClassAttendanceReports = (props) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [notUpdated, setNotUpdated] = useState(false);
  const [classAttendenceReport, setClassAttendenceReport] = useState([]);
  const [filteredReport, setFilteredReport] = useState([]);
  const [filterTrainer, setFilterTrainer] = useState('');
  const [filterClass, setFilterClass] = useState('');
  const [reportType, setReportType] = useState('totals');
  const [selectedClassType, setSelectedClassType] = useState({
    class: true,
    personalTraining: true,
    induction: true,
    gymClass: true,
    swimmingClass: true,
    tennisClass: true,
    massageClass: true
  });
  const [selectedGym, setSelectedGym] = useState({
    label: props.selectedGym.name,
    id: props.selectedGym._id
  });
  const [filteredGym, setFilteredGym] = useState(null);

  useEffect(
    () => {
      const getReport = () => {
        setLoading(true);
        let url = `/reports/class-attendance/${selectedGym.id}?fromDate=${moment(startDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )}&toDate=${moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}${
          reportType === 'name-details' ? '&includeAttendeesDetails=true' : ''
        }`;
        axios
          .get(url)
          .then((response) => {
            setLoading(false);
            setClassAttendenceReport(response.data);
            onSearchUpdate(response.data);
          })
          .catch(() => setLoading(false));
      };
      if (startDate !== undefined && endDate !== undefined) {
        getReport();
      }
    }, //eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, selectedGym, reportType]
  );

  useEffect(
    () => {
      onSearchUpdate(classAttendenceReport);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [notUpdated, selectedClassType, filterTrainer, filterClass]
  );

  const trainerUpdate = (event) => {
    setFilterTrainer(event.target.value);
  };

  const classNameUpdate = (event) => {
    setFilterClass(event.target.value);
  };
  const onSearchUpdate = (unFilteredArray) => {
    let filteredArray = unFilteredArray.filter((classObj) => classObj.eventName.toLowerCase().includes(filterClass));

    if (filterTrainer !== '') {
      filteredArray = filteredArray.filter(
        (classObj) => classObj.hostName !== undefined && classObj.hostName.toLowerCase().includes(filterTrainer)
      );
    }

    if (filteredArray.length > 0 && notUpdated) {
      filteredArray = filteredArray.filter((classObj) => {
        return classObj.attendances && classObj.attendances.pending;
      });
    }
    filteredArray = filteredArray.filter((elem) => {
      if (props.selectedGym.settings.class && selectedClassType.class && elem.eventType === 'class') {
        return elem;
      }
      if (
        props.selectedGym.settings.personalTraining &&
        selectedClassType.personalTraining &&
        elem.eventType === 'personalTraining'
      ) {
        return elem;
      }
      if (props.selectedGym.settings.induction && selectedClassType.induction && elem.eventType === 'induction') {
        return elem;
      }
      if (props.selectedGym.settings.gymClass && selectedClassType.gymClass && elem.eventType === 'gymClass') {
        return elem;
      }
      if (
        props.selectedGym.settings.swimmingClass &&
        selectedClassType.swimmingClass &&
        elem.eventType === 'swimmingClass'
      ) {
        return elem;
      }
      if (props.selectedGym.settings.tennisClass && selectedClassType.tennisClass && elem.eventType === 'tennisClass') {
        return elem;
      }
      if (
        props.selectedGym.settings.massageClass &&
        selectedClassType.massageClass &&
        elem.eventType === 'massageClass'
      ) {
        return elem;
      }
      return false;
    });

    setFilteredReport(filteredArray);
  };

  const viewClass = (classReport) => {
    props.history.push(`/gym/${props.selectedGym._id}/class/${classReport.id}`);
  };

  const startDateChange = (date) => {
    setStartDate(date);
  };
  const endDateChange = (date) => {
    setEndDate(date);
  };

  const handleNotUpdatedCheckbox = () => {
    setNotUpdated(!notUpdated);
  };

  const handleClassTypeChange = (type) => {
    setSelectedClassType({
      ...selectedClassType,
      [type]: !selectedClassType[type]
    });
  };

  const countAttendees = (attendance) => {
    const activeCount = attendance && attendance.active && attendance.active.count ? attendance.active.count : 0;
    const attendedCount =
      attendance && attendance.attended && attendance.attended.count ? attendance.attended.count : 0;
    return activeCount + attendedCount;
  };

  const renderGymSelection = () => {
    let options = props.locations.map((elem) => {
      return {
        label: elem.name,
        id: elem._id
      };
    });
    const onFilterGymList = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredGymList = props.locations.filter((gym) => {
        return gym.name.toLowerCase().includes(filter);
      });

      setFilteredGym(
        filteredGymList.map((elem) => {
          return {
            label: elem.name,
            id: elem._id
          };
        })
      );
    };
    return (
      <div style={{ margin: '30px 0' }}>
        <h4>Select gym</h4>
        <Select
          placeHolder="Select gym name"
          options={filteredGym || options}
          value={selectedGym}
          onChange={(event) => setSelectedGym(event.option)}
          onSearch={onFilterGymList}
        />
      </div>
    );
  };

  const rows = () =>
    filteredReport.map((classReport, index) => (
      <TableRow key={index} onClick={() => viewClass(classReport)}>
        <td>{classReport.eventName}</td>
        <td>{classReport.eventType}</td>
        <td>{classReport.hostName}</td>
        <td>{moment(classReport.date).format('DD/MM/YYYY')}</td>
        <td>{classReport.time}</td>
        <td>
          {countAttendees(classReport.attendances)}/{classReport.capacity}
        </td>
        {reportType === 'name-details' &&
          classReport.attendances &&
          classReport.attendances.active &&
          classReport.attendances.active.attendeesDetail && (
            <td>
              {classReport.attendances.active.attendeesDetail[0].name}{' '}
              {classReport.attendances.active.attendeesDetail[0].lastName}
            </td>
          )}
      </TableRow>
    ));

  const columnLabels = () => {
    let headers = ['Name', 'Type', 'Trainer', 'Date', 'Time', 'Attendance'];
    if (reportType === 'name-details') {
      headers.push('First Attendee');
    }
    return headers;
  };

  return (
    <>
      {filteredReport.length > 0 && (
        <ExportToCSV
          data={filteredReport}
          type={reportType === 'totals' ? 'classAttendanceReport' : 'classAttendanceNameReport'}
          gymName={selectedGym.label}
        />
      )}
      <div className="report">
        <div className="report__filter">
          <div>
            <div>
              <h4>Start Date</h4>
              <FormField>
                <DateTime
                  id="filterDate"
                  name="filterDate"
                  format="DD/MM/YYYY"
                  onChange={startDateChange}
                  value={startDate}
                />
              </FormField>
            </div>
            <div className="report__filter__trainer">
              <h4>Filter by name</h4>
              <TextInput
                className="report__filter__input"
                placeHolder="Class name"
                name="className"
                onDOMChange={classNameUpdate}
              />
            </div>
            <div>{renderGymSelection()}</div>
          </div>
          <div>
            <div>
              <h4>End Date</h4>
              <FormField>
                <DateTime
                  id="filterDate"
                  name="filterDate"
                  format="DD/MM/YYYY"
                  onChange={endDateChange}
                  value={endDate}
                />
              </FormField>
            </div>
            <div className="report__filter__trainer">
              <h4>Filter by trainer</h4>
              <TextInput
                className="report__filter__input"
                placeHolder="Trainer"
                name="trainerName"
                onDOMChange={trainerUpdate}
              />
            </div>
          </div>
          <div className="report__filter__checkbox">
            <CheckBox checked={notUpdated} onChange={handleNotUpdatedCheckbox} label="Not updated attendance" />
            <div className={'report__classType'}>
              {props.selectedGym.settings.class && (
                <CheckBox
                  checked={selectedClassType.class}
                  onChange={() => handleClassTypeChange('class')}
                  label="Classes"
                />
              )}
              {props.selectedGym.settings.personalTraining && (
                <CheckBox
                  checked={selectedClassType.personalTraining}
                  onChange={() => handleClassTypeChange('personalTraining')}
                  label="PT sessions"
                  className={'report__classType__checkbox'}
                />
              )}
              {props.selectedGym.settings.induction && (
                <CheckBox
                  className={'report__classType__checkbox'}
                  checked={selectedClassType.induction}
                  onChange={() => handleClassTypeChange('induction')}
                  label="Induction"
                />
              )}
              {props.selectedGym.settings.gymClass && (
                <CheckBox
                  className={'report__classType__checkbox'}
                  checked={selectedClassType.gymClass}
                  onChange={() => handleClassTypeChange('gymClass')}
                  label="Gym bookings"
                />
              )}
              {props.selectedGym.settings.swimmingClass && (
                <CheckBox
                  className={'report__classType__checkbox'}
                  checked={selectedClassType.swimmingClass}
                  onChange={() => handleClassTypeChange('swimmingClass')}
                  label="Swim bookings"
                />
              )}
              {props.selectedGym.settings.tennisClass && (
                <CheckBox
                  className={'report__classType__checkbox'}
                  checked={selectedClassType.tennisClass}
                  onChange={() => handleClassTypeChange('tennisClass')}
                  label="Tennis bookings"
                />
              )}

              {props.selectedGym.settings.massageClass && (
                <CheckBox
                  className={'report__classType__checkbox'}
                  checked={selectedClassType.massageClass}
                  onChange={() => handleClassTypeChange('massageClass')}
                  label="Massage bookings"
                />
              )}
            </div>
            <div className={'report__type'}>
              <RadioButton
                name="totals"
                label="Totals"
                checked={reportType === 'totals'}
                onChange={() => setReportType('totals')}
              />
              <RadioButton
                name="name-details"
                label="Name details"
                checked={reportType === 'name-details'}
                onChange={() => setReportType('name-details')}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <Spinning />
        ) : (
          <>
            {filteredReport.length === 0 && <div>No any class found</div>}
            {filteredReport.length > 0 && (
              <Table selectable={true}>
                <TableHeader style={{ color: 'black' }} labels={columnLabels()} />
                <tbody>{rows()}</tbody>
              </Table>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default withAdminDashboard(ClassAttendanceReports);
