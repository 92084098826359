import React, { useEffect, useState } from 'react';
import axios from '../../../../axios-global';
import Label from 'grommet/components/Label';
import FormField from 'grommet/components/FormField';
import Button from 'grommet/components/Button';
import SingleTrainer from '../../ManageTrainers/SingleTrainer/SingleTrainer';
import SingleAdmin from '../../ManageAdmins/SingleTrainer/SingleAdmin';
import withUser from '../../../../store/hoc/withUser';
import withManageAdmins from '../../../../store/hoc/withManageAdmins';
import { isFullAccessAdminOrTrainer } from '../../../../shared/userFunctions';
import { withRouter } from 'react-router-dom';
import classes from './SearchAdminTrainers.module.scss';
import Loading from '../../../../components/Loading/Loading';

function SearchAdminTrainers({ history, locations, userData, role, match, onDeleteAdmin, deletedAdmin }) {
  const [name, setName] = useState('');
  const [gymsList, setGymsList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [trainerFullAccess, setTrainerFullAccess] = useState({});

  useEffect(() => {
    if (locations) {
      let gymsList = {};
      locations.forEach((elem) => {
        gymsList[elem._id] = false;
      });
      setGymsList(gymsList);
    }

    setTrainerFullAccess(isFullAccessAdminOrTrainer(userData));
  }, [locations, userData]);

  useEffect(() => {
    //console.log(deletedAdmin);
  }, [deletedAdmin]);

  const searchFunction = () => {
    setIsLoading(true);
    const query = name.trim().toLowerCase();

    axios
      .get(`users/${role === 'admin' ? 'admins' : 'trainers'}?name=${query}`)
      .then((resp) => {
        setIsLoading(false);
        setSearchData(resp.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const deleteAdminFunction = (adminId) => {
    onDeleteAdmin(adminId);
    setTimeout(() => searchFunction(), 1000);
  };

  const refreshAfterDelete = () => {
    setTimeout(() => searchFunction(), 1000);
  };

  const search = () => {
    return (
      <div>
        <Label className={classes.label}>Please enter name or last name</Label>
        <FormField className={classes.input}>
          <input value={name} onChange={(e) => setName(e.target.value)}></input>
        </FormField>
        <Button primary label="Search" onClick={searchFunction} />
      </div>
    );
  };

  const searchResult = () => {
    if (searchData) {
      if (searchData.length) {
        return searchData.map((user) => {
          if (role === 'admin') {
            return (
              <SingleAdmin
                adminId={userData._id}
                hasFullAccess={trainerFullAccess}
                singleAdmin={user}
                key={user._id}
                gymsList={gymsList}
                history={history}
                match={match}
                deleteAdmin={() => deleteAdminFunction(user._id)}
                refreshData={() => searchFunction()}
              />
            );
          } else {
            return (
              <SingleTrainer
                key={user._id}
                trainer={user}
                history={history}
                match={match}
                gymsList={gymsList}
                trainerFullAccess={trainerFullAccess}
                adminFullAccess={trainerFullAccess}
                refreshData={searchFunction}
                isFromSearch={true}
                refreshAfterDelete={refreshAfterDelete}
              />
            );
          }
        });
      } else {
        if (role === 'admin') {
          return <h3>No admins</h3>;
        } else {
          return <h3>No trainers</h3>;
        }
      }
    }
    return null;
  };

  return (
    <div className={classes.root}>
      {search()}
      {searchResult()}
      {isLoading && <Loading />}
    </div>
  );
}

export default withRouter(withUser(withManageAdmins(SearchAdminTrainers)));
