import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import withUser from '../../store/hoc/withUser';
import { StripeProvider } from 'react-stripe-elements';
import Notification from 'grommet/components/Notification';
import Spinning from 'grommet/components/icons/Spinning';
import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';
import Title from '../../components/UI/Title/Title';
import Membership from './Membership/Membership';
import MembershipPlansList from './ClientMembership/MembershipPlansList/MembershipPlansList';
import ClientSubscribeToPlan from './ClientSubscribeToPlan/ClientSubscribeToPlan';
import ClientCreditDetails from './ClientCreditDetails/ClientCreditDetails';
import ClientQrCode from './ClientQrCode/ClientQrCode';
import UserSettings from '../UserSettings/User/UserSettings';
import PaymentMethods from '../UserSettings/User/PaymentMethods/PaymentMethods';
import { Elements } from 'react-stripe-elements';
import classes from './ClientDashboard.module.scss';
import KitList from '../../components/KitList/KitList';
import { isActiveUser } from '../../shared/userFunctions';
import DefaultClientUpcomingClasses from '../Timetables/DefaultTimetable/DefaultClientUpcomingClasses/DefaultClientUpcomingClasses';
import LiveStreamViewer from '../LiveStream/LiveStreamViewer/LiveStreamViewer';
import DefaultClientUpcomingPTs from '../Timetables/DefaultTimetable/DefaultClientUpcomingPTs/DefaultClientUpcomingPTs';
import TermsOfService from '../../containers/TermsOfService/TermsOfService';
import ManageTimetable from '../Timetables/ManageTimetable/ManageTimetable';
import ManagePasses from '../ManagePasses/ManagePasses';
import withClientDashboard from '../../store/hoc/withClientDashboard';
import { useQuery } from 'react-query';
import { fetchUserData } from '../../apiFunctions/apiFunctions';
import TilesBoard from '../../components/UI/TilesBoard/TilesBoard';
import { menuItemsArray } from '../../components/Navigation/MenuNavigation/menuItems';
import ManageFAQ from '../FAQ/ManageFAQ';
import Viewer from '../ManageVideos/ShowVideos/Viewer/Viewer';
import VideoPass from '../ManagePasses/ShowPasses/VideoPass/VideoPass';
import { CLASS } from '../../constants';

const ClientDashboard = (props) => {
  const [showAvailablePlans, setShowAvailablePlans] = useState(false);

  // eslint-disable-next-line
  const { data } = useQuery('userData', () => (props.userData.role === 'user' ? fetchUserData() : null));

  const notification = () => {
    if (!props.userData.joiningFeePaid && props.location.pathname === '/gym') {
      return null;
    }

    if (props.plansListError || props.currentStatusError) {
      return (
        <Notification
          message={props.plansListError ? props.plansListError : props.currentStatusError}
          status="critical"
          className="ss-top-notification"
        />
      );
    }

    return null;
  };

  const clientLinks = () => {
    const tiles = menuItemsArray(props.userData, props.gym.settings, props.match.url, null, 'showAsTile');
    const clientDashboard = (
      <>
        {props.gym.settings ? (
          <ClientCreditDetails
            gymSettings={props.gym.settings}
            selectedClient={props.userData}
            history={props.history}
          />
        ) : (
          <Redirect to={props.match.url} />
        )}
        {props.gym.settings.class && isActiveUser(props.userData) ? (
          <DefaultClientUpcomingClasses classType={CLASS.CLASS} />
        ) : (
          <Redirect to={props.match.url} />
        )}

        {props.gym.settings.personalTraining ? (
          <DefaultClientUpcomingClasses
            classType={CLASS.PERSONAL_TRAINING}
            PTupcomingClasses={props.upcomingPts}
            PTupcomingClassesLoading={props.upcomingPtsLoading}
            PTupcomingClassesError={props.upcomingPtsError}
          />
        ) : (
          <Redirect to={props.match.url} />
        )}

        {props.gym.settings.gymClass ? (
          <DefaultClientUpcomingClasses classType={CLASS.GYM_CLASS} />
        ) : (
          <Redirect to={props.match.url} />
        )}

        {props.gym.settings.swimmingClass ? (
          <DefaultClientUpcomingClasses classType={CLASS.SWIMMING_CLASS} />
        ) : (
          <Redirect to={props.match.url} />
        )}

        {props.gym.settings.tennisClass ? (
          <DefaultClientUpcomingClasses classType={CLASS.TENNIS_CLASS} />
        ) : (
          <Redirect to={props.match.url} />
        )}

        {props.gym.settings.massageClass ? (
          <DefaultClientUpcomingClasses classType={CLASS.MASSAGE_CLASS} />
        ) : (
          <Redirect to={props.match.url} />
        )}

        {props.gym.settings.induction ? (
          <DefaultClientUpcomingClasses classType={CLASS.INDUCTION} />
        ) : (
          <Redirect to={props.match.url} />
        )}

        <div className={classes.tileBoard}>
          <TilesBoard tiles={tiles} />
        </div>
      </>
    );

    return (
      <Switch>
        <Route
          path={`${props.match.url}/payment-methods`}
          render={() => (
            <>
              <Title header="Payment methods" subHeader="Please add card details" />
              <Elements>
                <PaymentMethods history={props.history} />
              </Elements>
            </>
          )}
        />

        <Route
          path={`${props.match.url}/settings`}
          render={() => (
            <>
              <Title header="Account settings" subHeader="Your account settings" />
              <UserSettings />
            </>
          )}
        />
        {props.gym.termsUrl && (
          <Route
            path={`${props.match.url}/terms-and-conditions`}
            render={() => <TermsOfService termsUrl={props.gym.termsUrl} />}
          />
        )}

        {props.gym.settings.faq && (
          <Route
            path={`${props.match.url}/faq`}
            render={() => (
              <>
                <Title
                  header="FAQ"
                  subHeader="See the FAQs below for further information about the services we offer."
                />
                <ManageFAQ />
              </>
            )}
          />
        )}

        <Route
          path={`${props.match.url}/kit-list`}
          exact
          render={() =>
            props.gym.settings.kitList ? (
              <>
                <Title header="Kit list" subHeader="Below is the list of equipment which is in gym" />
                <KitList editableView={false} />
              </>
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/qr`}
          render={() =>
            props.gym.settings.qrCode && isActiveUser(props.userData) ? (
              <ClientQrCode
                id={props.userData.qrCode ? props.userData.qrCode : props.userData._id}
                isActive={props.userData.customerType === 'full-member' ? props.currentStatus.active : true}
              />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/personal-training-timetable`}
          render={() =>
            props.gym.settings.personalTraining ? <DefaultClientUpcomingPTs /> : <Redirect to={props.match.url} />
          }
        />

        <Route
          path={`${props.match.url}/personal-training-passes`}
          exact
          render={() =>
            props.gym.settings.personalTraining ? (
              <ManagePasses classType={CLASS.PERSONAL_TRAINING} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/induction-timetable`}
          render={() =>
            props.gym.settings.induction ? (
              <ManageTimetable classType={CLASS.INDUCTION} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/induction-passes`}
          exact
          render={() =>
            props.gym.settings.induction ? (
              <ManagePasses classType={CLASS.INDUCTION} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        {/**VIDEO ROUTES**/}
        <Route
          path={`${props.match.url}/video-passes`}
          exact
          render={() =>
            props.gym.settings.videoOnDemand ? (
              <ManagePasses classType={CLASS.VIDEO} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/videos/:videoId`}
          exact
          render={({ match }) => <Viewer videoId={match.params.videoId} />}
        />

        <Route
          path={`${props.match.url}/pass/:passId`}
          exact
          render={({ match }) => {
            const product = props.userProducts.find(({ _id }) => match.params.passId === _id);
            return props.gym.settings.videoOnDemand && !!product ? (
              <VideoPass product={product} />
            ) : (
              <Redirect to={props.match.url} />
            );
          }}
        />

        <Route
          path={`${props.match.url}/gym-timetable`}
          exact
          render={() =>
            props.gym.settings.gymClass ? (
              <ManageTimetable classType={CLASS.GYM_CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/gym-passes`}
          exact
          render={() =>
            props.gym.settings.gymClass ? (
              <ManagePasses classType={CLASS.GYM_CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/swim-timetable`}
          exact
          render={() =>
            props.gym.settings.swimmingClass ? (
              <ManageTimetable classType={CLASS.SWIMMING_CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/swim-passes`}
          exact
          render={() =>
            props.gym.settings.swimmingClass ? (
              <ManagePasses classType={CLASS.SWIMMING_CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/tennis-timetable`}
          exact
          render={() =>
            props.gym.settings.tennisClass ? (
              <ManageTimetable classType={CLASS.TENNIS_CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/massage-timetable`}
          exact
          render={() =>
            props.gym.settings.massageClass ? (
              <ManageTimetable classType={CLASS.MASSAGE_CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/tennis-passes`}
          exact
          render={() =>
            props.gym.settings.tennisClass ? (
              <ManagePasses classType={CLASS.TENNIS_CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/massage-passes`}
          exact
          render={() =>
            props.gym.settings.massageClass ? (
              <ManagePasses classType={CLASS.MASSAGE_CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/class-timetable`}
          exact
          render={() =>
            props.gym.settings.class && isActiveUser(props.userData) ? (
              <ManageTimetable classType={CLASS.CLASS} />
            ) : (
              <Redirect to={props.match.url} />
            )
          }
        />

        <Route
          path={`${props.match.url}/videos/live-stream-viewer`}
          render={() => <LiveStreamViewer userData={props.userData} />}
        />

        {/**
         * FULL-MEMBER WITHOUT PAID JOINING FEE
         */}
        {!props.userData.joiningFeePaid && props.userData.customerType === 'full-member' && (
          <>
            <Route
              path={props.match.url}
              exact
              render={() => (
                <>
                  <Title
                    header="Feel the best you ever have..."
                    subHeader="Please select the membership you wish to sign up for and feel the best you ever have."
                  />

                  <MembershipPlansList
                    showJoiningFee={!props.userData.joiningFeePaid}
                    availablePlans={props.plansList}
                    buyPlan={(plan) => {
                      props.history.push(`${props.match.url}/membership/pay/${plan._id}`);
                    }}
                  />
                </>
              )}
            />

            <Route
              path={`${props.match.url}/membership`}
              exact
              render={() => (
                <>
                  <Title header="Your membership" />
                  <Membership
                    userData={props.userData}
                    currentStatus={props.currentStatus}
                    availablePlans={props.plansList}
                    showPlansList={showAvailablePlans}
                    joiningFeePaid={props.userData.joiningFeePaid}
                    openPlansList={() => setShowAvailablePlans(true)}
                    buyPlan={(plan) => {
                      props.history.push(`${props.match.url}/membership/pay/${plan._id}`);
                    }}
                  />
                </>
              )}
            />

            <Route
              path={`${props.match.url}/membership/pay/:planId`}
              render={() => (
                <>
                  <Title header="Payment" />
                  <ClientSubscribeToPlan
                    joiningFeePaid={props.userData.joiningFeePaid}
                    plansList={props.plansList}
                    plansListLoading={props.plansListLoading}
                    currentStatus={props.currentStatus}
                  />
                </>
              )}
            />
          </>
        )}

        {/**
         * FULL-MEMBER WITH PAID JOINING FEE
         */}
        {props.userData.joiningFeePaid && props.userData.customerType === 'full-member' && (
          <>
            <Route
              path={props.match.url}
              exact
              render={() => (
                <>
                  <Title header="Your dashboard" subHeader="Strive for progress, not perfection" />
                  {!props.currentStatus.active && (
                    <>
                      <h3 style={{ marginTop: '25px' }}>Membership</h3>
                      <Membership
                        userData={props.userData}
                        currentStatus={props.currentStatus}
                        availablePlans={props.plansList}
                        showPlansList={showAvailablePlans}
                        joiningFeePaid={props.userData.joiningFeePaid}
                        openPlansList={() => setShowAvailablePlans(true)}
                        buyPlan={(plan) => {
                          props.history.push(`${props.match.url}/membership/pay/${plan._id}`);
                        }}
                      />
                    </>
                  )}
                  {clientDashboard}
                </>
              )}
            />

            <Route
              path={`${props.match.url}/membership`}
              exact
              render={() => (
                <>
                  <Title header="Your membership" />
                  <Membership
                    userData={props.userData}
                    currentStatus={props.currentStatus}
                    availablePlans={props.plansList}
                    showPlansList={showAvailablePlans}
                    openPlansList={() => setShowAvailablePlans(true)}
                    buyPlan={(plan) => {
                      props.history.push(`${props.match.url}/membership/pay/${plan._id}`);
                    }}
                  />
                </>
              )}
            />

            <Route
              path={`${props.match.url}/membership/pay/:planId`}
              render={() => (
                <>
                  <Title header="Payment" />
                  <ClientSubscribeToPlan
                    joiningFeePaid={props.userData.joiningFeePaid}
                    plansList={props.plansList}
                    plansListLoading={props.plansListLoading}
                    currentStatus={props.currentStatus}
                  />
                </>
              )}
            />
          </>
        )}

        {/**
         * CLASS-PASS-MEMBER
         */}
        {props.userData.customerType === 'class-pass-member' && (
          <>
            <Route
              path={props.match.url}
              exact
              render={() => (
                <>
                  <Title header="Your dashboard" subHeader="Strive for progress, not perfection" />
                  {clientDashboard}
                </>
              )}
            />

            <Route
              path={`${props.match.url}/class-passes`}
              exact
              render={() =>
                props.gym.settings.class ? <ManagePasses classType={'class'} /> : <Redirect to={props.match.url} />
              }
            />
          </>
        )}

        <Redirect to={props.match.url} />
      </Switch>
    );
  };

  const inactiveClient = (status) => {
    const waitingForApproval =
      'Thanks for registering. We need some further information before you can proceed to book, we’ll review your account as soon as possible, please check back later and feel free to contact us if you don’t hear from us soon.';
    const permissionDenied =
      'Your access to the gym has been denied, if you believe this is an error please contact us';
    const inactive = 'Your account has been marked as inactive, if you think this is an error please contact us.';
    return (
      <Switch>
        <Route
          path={props.match.url}
          exact
          render={() => (
            <>
              <Title header="Your account needs approval" subHeader="" />
              <Notification
                message={
                  status === 'waitingForApproval'
                    ? waitingForApproval
                    : status === 'permissionDenied'
                    ? permissionDenied
                    : inactive
                }
                status="warning"
                className="ss-top-notification"
              />
            </>
          )}
        />

        {props.gym.settings.faq && (
          <Route
            path={`${props.match.url}/faq`}
            render={() => (
              <>
                <Title
                  header="FAQ"
                  subHeader="See the FAQs below for further information about the services we offer."
                />
                <ManageFAQ />
              </>
            )}
          />
        )}
        <Redirect to={props.match.url} />
      </Switch>
    );
  };

  return (
    <>
      <StripeProvider
        apiKey={
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_STRIPE_KEY_DEV
            : process.env.REACT_APP_STRIPE_KEY_PROD
        }>
        {props.userDataLoading ||
        !props.userData ||
        !props.currentStatus ||
        props.currentStatusLoading ||
        props.plansListLoading ||
        props.gymDataLoading ? (
          <Spinning />
        ) : (
          <>
            <WhiteBackground>
              <div className={classes.dashboardContainer}>
                {notification()}
                {props.userData.status === 'active' ? clientLinks() : inactiveClient(props.userData.status)}
              </div>
            </WhiteBackground>
          </>
        )}
      </StripeProvider>
    </>
  );
};

export default withUser(withClientDashboard(ClientDashboard));
