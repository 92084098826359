import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import classes from '../../../Timetables/DefaultTimetable/DefaultTimetable/SingleDay.module.scss';
import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import withUser from '../../../../store/hoc/withUser';
import { returnClassConfig } from '../../../../shared/gymFunctions';
import { returnClassesDetails } from '../../../../shared/classFunctions';
import { delayFetchFunctions, signUpAndPayForClass } from '../../../../apiFunctions/apiFunctions';
import { useMutation } from 'react-query';
import CustomToast from '../../../../components/CustomToast/CustomToast';

const SignUpAndPayButton = ({ history, userData, sourcesList, selectedClass, selectedGym }) => {
  const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useState(null);
  const [userHasClassesLeftToUse, setUserHasClassesLeftToUse] = useState(false);
  const classesDetails = returnClassesDetails(selectedClass.__t);
  const config = returnClassConfig(selectedGym.settings.classConfig, selectedClass.__t);
  const isFreeEvent = selectedClass.cost === 0;
  const isClassMembershipUser = userData.customerType === 'full-member' && selectedClass.__t === 'class';

  useEffect(() => {
    setUserHasClassesLeftToUse(
      userData[classesDetails.usersClassesLeft] > 0 ||
        (userData.customerType === 'full-member' &&
          userData.currentMembershipPlan &&
          userData.currentMembershipPlan.ssPlanId &&
          userData.currentMembershipPlan.ssPlanId.unlimited &&
          selectedClass.__t === 'class')
    );
  }, [userData, classesDetails, selectedClass]);

  const mutateSignUpAndPay = useMutation(
    ({ isSinglePayment }) => signUpAndPayForClass(selectedClass._id, userData._id, isSinglePayment),
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: isFreeEvent || userHasClassesLeftToUse ? 'Booking successful.' : 'Successfully added to this class',
          show: true
        });
        setShowModal(false);

        delayFetchFunctions([
          ['upcomingClasses', selectedClass.__t],
          ['clientUpcomingClasses', selectedClass.__t],
          ['userData']
        ]);
      },
      onError: (err) => {
        setShowModal(false);
        setToast({
          status: 'critical',
          msg:
            err.response && err.response.status === 403
              ? 'Please activate your membership plan'
              : err.response && err.response.status === 422
              ? err.response.data.message
              : 'Something went wrong. Try again',
          show: true
        });
      }
    }
  );

  const renderPaymentUpModal = () => {
    const needsAddPaymentMethod = sourcesList && sourcesList.length === 0 && selectedClass.cost > 0;
    const closeModal = (
      <Button
        style={{
          fontWeight: 700,
          backgroundColor: '#414142',
          margin: '15px auto',
          width: '100%'
        }}
        box
        label="Cancel"
        secondary
        onClick={() => setShowModal(false)}
      />
    );
    return (
      <Modal onRequestClose={() => setShowModal(false)} isOpen={showModal} className="ss-modal">
        <div className="grommet" style={{ marginTop: '15px' }}>
          <span className={classes.modalMsg}>
            {!isFreeEvent && config.allowedToBuyPasses && !userHasClassesLeftToUse
              ? isClassMembershipUser
                ? 'Do you want to pay for single one?'
                : 'Pay for this single one or buy a block?'
              : 'Confirm booking'}
          </span>
          {mutateSignUpAndPay.isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Spinning size="large" />
            </div>
          ) : (
            <div className="ss-modal__buttons-container" style={{ display: 'block' }}>
              {isFreeEvent || userHasClassesLeftToUse ? (
                <Button
                  primary
                  label="Book"
                  secondary
                  className={classes.modalButtons}
                  onClick={() => mutateSignUpAndPay.mutate({ isSinglePayment: true })}
                />
              ) : (
                <Button
                  className={classes.modalButtons}
                  primary
                  label="Pay for single"
                  secondary
                  onClick={() =>
                    needsAddPaymentMethod
                      ? history.push(`/gym/payment-methods`)
                      : mutateSignUpAndPay.mutate({
                          isSinglePayment: true,
                          userId: userData._id,
                          classId: selectedClass._id
                        })
                  }
                />
              )}
              {config.allowedToBuyPasses && !userHasClassesLeftToUse && !isFreeEvent && !isClassMembershipUser && (
                <Button
                  label="Buy a block"
                  primary
                  className={classes.modalButtons}
                  onClick={() =>
                    history.push({
                      pathname: `/gym/${classesDetails.passesUrl}`,
                      state: { goBackToPreviousPage: true }
                    })
                  }
                />
              )}

              {closeModal}
            </div>
          )}
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <CustomToast toast={toast} />
      {renderPaymentUpModal()}
      <Button
        style={{ width: '100%', maxWidth: '100%', whiteSpace: 'nowrap' }}
        label="Sign up"
        secondary
        onClick={() => setShowModal(true)}
        data-cy="signUpBtn"
      />
    </div>
  );
};

export default withRouter(withUser(SignUpAndPayButton));
