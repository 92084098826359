import React, { useState } from 'react';
import axios from '../../../axios-global';
import Modal from 'react-modal';

import Button from 'grommet/components/Button';
import Select from 'grommet/components/Select';
import Tab from 'grommet/components/Tab';
import Tabs from 'grommet/components/Tabs';

import ClientProfilePaymentMethodSelection from '../ClientProfilePaymentMethodSelection';
import HeaderWithBorder from '../../UI/HeaderWithBorder/HeaderWithBorder';
import './ClientProfileAddProducts.scss';

export default function ClientProfileAddProducts(props) {
  const [modalState, setModalState] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [product, setProduct] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);

  let classPasses = [];
  if (props.classPasses) {
    classPasses = props.classPasses.map((elem) => {
      return {
        label: `${elem.name} (x${elem.classCount})`,
        value: elem._id
      };
    });
  }

  let ptSessions = [];
  if (props.personalTrainings) {
    ptSessions = props.personalTrainings.map((elem) => {
      return {
        label: `${elem.name} - ${elem.classCount} x ${elem.duration} mins, Places: ${elem.places}`,
        value: elem._id
      };
    });
  }

  let gymPasses = [];
  if (props.gymPasses) {
    gymPasses = props.gymPasses.map((elem) => {
      return {
        label: `${elem.name} - cost: ${elem.cost} (x${elem.classCount})`,
        value: elem._id
      };
    });
  }

  let swimPasses = [];
  if (props.swimPasses) {
    swimPasses = props.swimPasses.map((elem) => {
      return {
        label: `${elem.name} - cost: ${elem.cost} (x${elem.classCount})`,
        value: elem._id
      };
    });
  }

  let tennisPasses = [];
  if (props.tennisPasses) {
    tennisPasses = props.tennisPasses.map((elem) => {
      return {
        label: `${elem.name} - cost: ${elem.cost} (x${elem.classCount})`,
        value: elem._id
      };
    });
  }

  let massagePasses = [];
  if (props.massagePasses) {
    massagePasses = props.massagePasses.map((elem) => {
      return {
        label: `${elem.name} - cost: ${elem.cost} (x${elem.classCount})`,
        value: elem._id
      };
    });
  }

  const chooseProductType = (event) => {
    setProduct({ label: event.option.label, value: event.option.value });
  };

  const onActiveTab = (tabIndex) => {
    setProduct({});
    setSelectedTab(tabIndex);
  };

  const addNewProduct = async () => {
    try {
      const respond = await axios.post(`/products/passes/pay`, {
        method: paymentMethod,
        userId: props.selectedClient._id,
        productId: product.value
      });
      props.onSuccessfulSave(respond);
      props.fetchSingleClient();
    } catch (error) {
      props.onFailedSave(error);
    }
  };

  const renderProducts = () => {
    let allTabs = [
      {
        type: 'class',
        title: 'Class passes',
        options: classPasses
      },
      {
        type: 'personalTraining',
        title: 'PT sessions',
        options: ptSessions
      },

      {
        type: 'gymClass',
        title: 'Gym bookings',
        options: gymPasses
      },
      {
        type: 'swimmingClass',
        title: 'Swim bookings',
        options: swimPasses
      },
      {
        type: 'tennisClass',
        title: 'Tennis bookings',
        options: tennisPasses
      },
      {
        type: 'massageClass',
        title: 'Massage bookings',
        options: massagePasses
      }
    ];

    allTabs = allTabs.filter(
      (elem) =>
        props.gymSettings[elem.type] &&
        (props.selectedClient.customerType === 'class-pass-member' ||
          (props.selectedClient.customerType === 'full-member' && elem.type !== 'class'))
    );

    return (
      <>
        <HeaderWithBorder header={'Choose product'} />
        <Tabs responsive={false} justify="start" activeIndex={selectedTab} onActive={onActiveTab}>
          {allTabs.map(({ title, options }) => (
            <Tab title={title} key={title}>
              <Select options={options} value={product.label} onChange={chooseProductType} />
            </Tab>
          ))}
        </Tabs>
      </>
    );
  };

  const handleModalClose = () => {
    setModalState(false);
  };

  return (
    <>
      <Modal
        isOpen={modalState}
        onRequestClose={handleModalClose}
        className="ss-modal client-profile-add-product__modal">
        {renderProducts()}

        <HeaderWithBorder style={{ marginTop: '50px' }} header={'Choose payment method'} />

        <ClientProfilePaymentMethodSelection onMethodSelected={setPaymentMethod} />

        <div className="ss-modal__buttons-container">
          <Button box label="OK" secondary={true} onClick={addNewProduct} />

          <Button box label="Cancel" primary onClick={handleModalClose} />
        </div>
      </Modal>

      <Button
        style={{
          display: 'block',
          maxWidth: '100%',
          width: '100%',
          marginTop: '20px'
        }}
        label="Add products"
        secondary={true}
        onClick={() => setModalState(true)}
      />
    </>
  );
}
