import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  adminsList: null,
  adminsListLoading: false,
  adminsListError: null,

  newAdmin: null,
  newAdminLoading: false,
  newAdminError: null,

  updatedAdmin: null,
  updatedAdminLoading: null,
  updatedAdminError: null,

  deletedAdmin: null,
  deletedAdminLoading: false,
  deletedAdminError: null,

  globalQrReader: true,

  gymDataError: null,
  gymDataLoading: false,
  selectedGym: null,

  plansListError: null,
  plansListLoading: false,
  plansList: null,

  gymUsersListError: null,
  gymUsersListLoading: false,
  clientsList: null,
  allClientsList: null,

  selectedClientError: null,
  selectedClientLoading: false,
  selectedClient: null,

  trainersList: null,
  trainersFromAllGyms: null,
  trainersListLoading: false,
  trainersListError: null,

  classesTemplatesListError: null,
  classesTemplatesListLoading: false,
  classesTemplatesList: null,

  upcomingPtsError: null,
  upcomingPtsLoading: false,
  upcomingPts: null,

  cancelClassLoading: false,
  cancelClassError: null,

  gymsUsersFullData: null,
  gymsUsersErrorData: null,
  gymsUsersFullDataLoading: false,
  gymsUsersFullDataError: null,
  gymUsersFullDataGymId: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //manage admins
    case actionTypes.GET_ADMINS_START:
      return updateObject(state, {
        adminsListLoading: true
      });
    case actionTypes.GET_ADMINS_SUCCESS:
      return updateObject(state, {
        adminsListLoading: false,
        adminsList: action.payload.adminsList
      });
    case actionTypes.GET_ADMINS_ERROR:
      return updateObject(state, {
        adminsListLoading: false,
        adminsListError: action.payload.error
      });

    case actionTypes.CREATE_ADMIN_START:
      return updateObject(state, {
        newAdminLoading: true
      });
    case actionTypes.CREATE_ADMIN_SUCCESS:
      return updateObject(state, {
        newAdminLoading: false,
        newAdmin: action.payload.newAdmin
      });
    case actionTypes.CREATE_ADMIN_ERROR:
      return updateObject(state, {
        newAdminLoading: false,
        newAdminError: action.payload.error
      });

    case actionTypes.UPDATE_ADMIN_START:
      return updateObject(state, {
        updatedAdminLoading: true
      });
    case actionTypes.UPDATE_ADMIN_SUCCESS:
      return updateObject(state, {
        updatedAdminLoading: false,
        updatedAdmin: action.payload.updatedAdmin
      });
    case actionTypes.UPDATE_ADMIN_ERROR:
      return updateObject(state, {
        updatedAdminLoading: false,
        updatedAdminError: action.payload.error
      });

    case actionTypes.DELETE_ADMIN_START:
      return updateObject(state, {
        deletedAdminLoading: true
      });
    case actionTypes.DELETE_ADMIN_SUCCESS:
      return updateObject(state, {
        deletedAdminLoading: false,
        deletedAdmin: action.payload.deletedAdmin
      });
    case actionTypes.DELETE_ADMIN_ERROR:
      return updateObject(state, {
        deletedAdminLoading: false,
        deletedAdminError: action.payload.error
      });

    // QR reader
    case actionTypes.ADMIN_CHANGE_GLOBAL_QR_READER:
      return updateObject(state, {
        globalQrReader: action.state
      });
    // gym data
    case actionTypes.ADMIN_SET_GYM_DATA_START:
      return updateObject(state, {
        gymDataLoading: true
      });
    case actionTypes.ADMIN_SET_GYM_DATA_SUCCESS:
      return updateObject(state, {
        selectedGym: action.selectedGym,
        gymDataLoading: false,
        gymDataError: null
      });
    case actionTypes.ADMIN_SET_GYM_DATA_FAILED:
      return updateObject(state, {
        gymDataLoading: false,
        gymDataError: action.error
      });

    // plans list
    case actionTypes.ADMIN_FETCH_PLANS_START:
      return updateObject(state, {
        plansListLoading: true
      });
    case actionTypes.ADMIN_FETCH_PLANS_SUCCESS:
      return updateObject(state, {
        plansList: action.plansList,
        plansListLoading: false,
        plansListError: null
      });
    case actionTypes.ADMIN_FETCH_PLANS_FAILED:
      return updateObject(state, {
        plansListLoading: false,
        plansListError: action.error
      });

    // users in gym list
    case actionTypes.ADMIN_FETCH_GYM_USERS_START:
      return updateObject(state, {
        gymUsersListLoading: true
      });
    case actionTypes.ADMIN_FETCH_GYM_USERS_SUCCESS:
      return updateObject(state, {
        gymUsersListLoading: false,
        gymUsersListError: null,
        clientsList: action.clientsList,
        allClientsList: action.allClientsList
      });

    case actionTypes.ADMIN_FETCH_GYM_USERS_FAILED:
      return updateObject(state, {
        gymUsersListLoading: false,
        gymUsersListError: action.error
      });
    case actionTypes.ADMIN_SET_TRAINERS_START:
      return updateObject(state, {
        trainersListLoading: true
      });
    case actionTypes.ADMIN_SET_TRAINERS_FROM_ALL_GYMS_SUCCESS:
      return updateObject(state, {
        trainersListLoading: false,
        trainersFromAllGyms: action.trainersFromAllGyms
      });
    case actionTypes.ADMIN_SET_TRAINERS_SUCCESS:
      return updateObject(state, {
        trainersListLoading: false,
        trainersListError: null,
        trainersList: action.trainersList
      });
    case actionTypes.ADMIN_SET_TRAINERS_FAILED:
      return updateObject(state, {
        trainersListLoading: false,
        trainersListError: action.error
      });

    // single client
    case actionTypes.ADMIN_FETCH_SINGLE_CLIENT_START:
      return updateObject(state, {
        selectedClientLoading: true
      });
    case actionTypes.ADMIN_FETCH_SINGLE_CLIENT_SUCCESS:
      return updateObject(state, {
        selectedClientLoading: false,
        selectedClientError: null,
        selectedClient: action.client
      });
    case actionTypes.ADMIN_FETCH_SINGLE_CLIENT_FAILED:
      return updateObject(state, {
        selectedClientLoading: false,
        selectedClientError: action.error
      });
    case actionTypes.ADMIN_FETCH_SINGLE_CLIENT_RESET:
      return updateObject(state, {
        selectedClient: action.client
      });

    // classes templates list
    case actionTypes.ADMIN_FETCH_CLASSES_TEMPLATES_START:
      return updateObject(state, {
        classesTemplatesListListLoading: true
      });
    case actionTypes.ADMIN_FETCH_CLASSES_TEMPLATES_SUCCESS:
      return updateObject(state, {
        classesTemplatesListLoading: false,
        classesTemplatesListError: null,
        classesTemplatesList: action.classesTemplatesList
      });
    case actionTypes.ADMIN_FETCH_CLASSES_TEMPLATES_FAILED:
      return updateObject(state, {
        classesTemplatesListLoading: false,
        classesTemplatesListError: action.error
      });

    // upcoming PTs
    case actionTypes.ADMIN_FETCH_UPCOMING_PTS_START:
      return updateObject(state, {
        upcomingPtsLoading: true
      });
    case actionTypes.ADMIN_FETCH_UPCOMING_PTS_SUCCESS:
      return updateObject(state, {
        upcomingPtsLoading: false,
        upcomingPtsError: null,
        upcomingPts: action.upcomingPts
      });
    case actionTypes.ADMIN_FETCH_UPCOMING_PTS_FAILED:
      return updateObject(state, {
        upcomingPtsLoading: false,
        upcomingPtsError: action.error
      });

    // Inductions
    case actionTypes.ADMIN_CANCEL_CLASS_START:
      return updateObject(state, {
        cancelClassLoading: true
      });
    case actionTypes.ADMIN_CANCEL_CLASS_SUCCESS:
      return updateObject(state, {
        cancelClassLoading: false,
        cancelClassError: null
      });
    case actionTypes.ADMIN_CANCEL_CLASS_FAILED:
      return updateObject(state, {
        cancelClassLoading: false,
        cancelClassError: action.error
      });

    // Full gym's users data
    case actionTypes.FETCH_GYM_USERS_FULL_DATA_START:
      return updateObject(state, {
        gymsUsersFullDataLoading: true
      });
    case actionTypes.FETCH_GYM_USERS_FULL_DATA_SUCCESS:
      return updateObject(state, {
        gymsUsersFullDataLoading: false,
        gymsUsersFullData: action.gymsUsersFullData,
        gymsUsersErrorData: action.gymsUsersFullData.errorUsers ? action.gymsUsersFullData.errorUsers : [],
        gymUsersFullDataGymId: action.gymId
      });
    case actionTypes.FETCH_GYM_USERS_FULL_DATA_FAILED:
      return updateObject(state, {
        gymsUsersFullDataLoading: false,
        gymsUsersFullDataError: action.error
      });

    default:
      return state;
  }
};

export default reducer;
