import React, { useState } from 'react';
import Label from 'grommet/components/Label';
import FormField from 'grommet/components/FormField';
import Button from 'grommet/components/Button';
import { Table, TableHeader, TableRow } from 'grommet';
import axios from '../../../../axios-global';
import moment from 'moment';
import Loading from '../../../../components/Loading/Loading';
import RedirectToUserDetails from '../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import classes from './SearchClients.module.scss';

const SearchClients = () => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const searchFunction = () => {
    setIsLoading(true);
    const query = name.trim().toLowerCase();

    axios
      .get(`users/all?name=${query}`)
      .then((resp) => {
        setIsLoading(false);
        setSearchData(resp.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const search = () => {
    return (
      <div>
        <Label className={classes.label}>Please enter name or last name</Label>
        <FormField className={classes.input}>
          <input value={name} onChange={(e) => setName(e.target.value)}></input>
        </FormField>
        <Button primary label="Search" onClick={searchFunction} />
      </div>
    );
  };

  const searchResult = () => {
    const table = () => {
      const tableLabels = ['Name', 'Last name', 'Date of birth', 'Gym', ''];
      const renderRows = (data) =>
        data.map((elem) => (
          <TableRow direction="column" align="start" separator="horizontal" key={elem.userId}>
            <td>{elem.name}</td>
            <td>{elem.lastName}</td>
            <td>{elem.dateOfBirth ? moment(elem.dateOfBirth).format('DD/MM/YYYY') : null}</td>
            <td>{elem.gymName}</td>
            <td>
              <RedirectToUserDetails gymId={elem.gymId} clientId={elem.userId} />
            </td>
          </TableRow>
        ));

      return searchData.length > 0 ? (
        <Table responsive={true}>
          <TableHeader labels={tableLabels} />
          <tbody>{renderRows(searchData)}</tbody>
        </Table>
      ) : (
        <h3>No clients</h3>
      );
    };

    if (searchData !== null) {
      return <div className={classes.table}>{table()}</div>;
    }
    return null;
  };

  return (
    <div className={classes.root}>
      {search()}
      {searchResult()}
      {isLoading && <Loading />}
    </div>
  );
};

export default SearchClients;
